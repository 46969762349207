<template>
  <v-container fluid class="pa-0">
    <v-row class="ma-0">
      <v-col cols="12" class="pa-0">
        <v-card class="pa-5 pb-1 mobile-padding"
        :class="role === 'client' || role === 'member' ? 'notification-height': ''">
          <v-container fluid pa-0 v-if="notifications.length" class="notifications-container">
            <v-row class="ma-0">
              <v-col class="d-flex align-center pa-0 pb-1 notification-padding">
                <div class="text-uppercase font-label pl-0">NOTIFICATIONS</div>
              </v-col>
              <v-col class="d-flex justify-end pa-0 pb-1">
                <v-btn
                  text
                  height="24"
                  class="pa-0 btn-purple"
                  @click="openNotifications"
                  v-if="this.notifications.length > 6"
                >
                  View all
                  <!-- <img v-if="$vuetify.breakpoint.mdAndUp"
                  src="@/assets/svg/theme/more-details-arrow.svg" /> -->
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="d-flex pt-1 notification-wrapper"
                :class="{'client--style':client}"
              >
                <v-list class="w-100 mobile-width">
                  <div class="mb-1">
                    <NotificationComponent
                      :notification="notification"
                      v-for="notification in limitNotifications()"
                      :key="notification.id"
                      class="notification"
                    />
                  </div>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-else class="no-notification-container pa-lg-0"
          :class="{'add-padding': !client}" >
            <v-row class="ma-0 py-2">
              <v-col class="d-flex align-center pl-0 py-0">
                <div class="text-uppercase card-heading pl-0">NOTIFICATIONS</div>
              </v-col>
            </v-row>
            <div :class="{'client-':client}" class="pt-0">
              <v-row class="ma-0 mt-6 notification-text">
                <v-col class="d-flex justify-center pt-0 pl-0 sub-text" cols="12">
                  <div>When you have new notifications, you’ll see them here.</div>
                </v-col>
              </v-row>
              <v-row class="ma-0 py-4 d-flex justify-center">
                <img class="image-width" src="@/assets/svg/theme/no-notification.svg"/>
              </v-row>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { getUser } from '@/helpers';
import NotificationComponent from './NotificationComponent';

export default {
  components: {
    NotificationComponent,
  },
  props: {
    client: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('dashboard', ['notifications']),
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions('dashboard', ['getNotifications']),
    openNotifications() {
      this.$router.push('notifications');
    },
    limitNotifications() {
      return this.notifications.slice(0, 6);
    },
  },
  mounted() {
    const user = getUser();
    // FIXME: check how this whole component remounting
    // logging out
    if (user && user.token) {
      this.getNotifications();
    }
  },
};
</script>

<style lang="scss" scoped>
.notification-wrapper {
  overflow-x: auto;
}
.add-padding{
  padding-bottom:8vh !important;
}
.notification-height {
  height: 391px !important;
}
.client--style {
  height: 340px;
}
.notification {
  margin-bottom: 10px;
  padding: 0;
}
.view-text {
  font-size: 14px;
  font-family: $fontFamily1;
}
.no-notification-container{
  padding-bottom: 0px !important;
}
.notification-text{
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.63;
  letter-spacing: -0.02px;
  text-align: center;
  color: $charcoalBlack;
}
.sub-text{
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.66;
  text-align: center;
  color: #727272
}
@media  (min-height: 960px) {
  .add-padding{
    padding-bottom:20vh !important;
  }
}
@media (max-width: 960px) {
  .mobile-padding {
    padding: 12px !important;
    margin: 0px !important;
    height: 100% !important;
  }
  .mobile-width {
    width: 130% !important;
  }
  .client--style {
    min-height: 220px !important;
  }
  .image-width {
    width: 300px
  }
  .notification-padding {
    padding-left: 8px !important;
  }
}
@media (min-width: 600px) and (max-width: 960px) {
  .notification-wrapper {
    max-height: 56vh;
  }
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .notification-wrapper {
    overflow: hidden;
    padding: 0px !important;
  }
}

</style>
