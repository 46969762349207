<template>
  <v-container fluid pa-0 class="mobile-dashboard">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" md=8 class="pa-0">
       <v-row class="ma-0 pa-0 margin-bottom">
         <v-col cols="12" class="pa-0 pb-5">
          <MyContentPlan class="content-height"
            :lastContentKit="lastContentKit"
            :allContentKits="allContentKits"
            :contentKitPercentages="contentKitPercentages"
            :subscription="defaultSubscription"
           />
          </v-col>
        </v-row>
         <v-row class="ma-0 pa-0">
          <v-col cols="12" md="6" class="pa-0 pr-2 mobile-content-sweep">
           <v-card class="upcoming-event-card sweep-height" v-if="!contentSweeps">
            <v-card-text>
              <v-container fluid pa-0>
                <v-row class="ma-0">
                  <v-col cols="12" class="pa-0">
                    <div class="upcoming-event-details">
                      <div class="d-flex justify-space-between w-100">
                        <div class="main-content text-truncate pt-2 pr-4"> {{ sweep.title }} </div>
                        <div class="pt-1">
                          <v-btn text class="pa-0" small color="info"
                          :to="{path: '/sweeps', query: { tab: 'upcoming'}}">
                            View all >
                          </v-btn>
                          </div>
                        </div>
                        <div class="heading pt-2"> {{ sweepDate }}</div>
                        <div class="heading mb-1">
                          {{sweepTime(sweep.fromTime)}} - {{sweepTime(sweep.toTime)}}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="sweep-team ma-0" v-if="sweepMembers.length">
                    <v-col class="pa-0 pt-3">
                      <CreativeTeam
                        :creativeTeam="sweepMembers"
                        class="pt-2 w-100"
                        :vertical="false"
                        :avatarSize="34"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="my-1" v-else>
                    <v-col class="py-0 pt-3 black--text">
                      - No creatives matched yet
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
           </v-card>
            <v-card v-else class='pa-5 upcoming-event-card sweep-height'>
               <v-card-title class="main-content text-truncate pa-0">
                  SWEEPS
                </v-card-title>
                <v-row class="ma-0 d-flex align-center col-align">
                  <v-col cols="12" sm=4 md=12 lg=3 class="pa-0 center-align">
                    <img src="@/assets/svg/theme/no-sweeps-calendar.svg" alt="no events" />
                  </v-col>
                  <v-col cols="12" lg="9" class="pa-0 center-align">
                    <div class="heading content-heading pl-5">
                      You don't have any upcoming Sweeps just yet.
                    </div>
                  </v-col>
                </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
             <v-container fluid pa-0>
                <v-row class="ma-0">
                    <template>
                        <v-col cols="12" class="pa-0 pt-4 pt-md-0 pl-md-2">
                            <v-card class="deliverables-card pa-5">
                              <div class="deliverable-card pa-0">
                              <v-row class="ma-0">
                             <v-col cols="9" class="d-flex align-center pa-0">
                             <div class="text-uppercase card-heading pa-0">Deliverables</div>
                             </v-col>
                             </v-row>
                             <DeliverablesList :deliverableList="deliverableList"
                             :deliverableHeight="'95px'" />
                            </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
             </v-container>
          </v-col>
        </v-row>
          <v-col cols="12" class="pa-0">
            <v-container fluid pa-0>
              <!-- <v-row class="ma-0">
                <v-col cols="12" sm="6" class="pa-4 pt-0 pe-sm-2">
                  <v-card class="calendar-event-card" v-if="false">
                    <v-card-title>{{calendarEvents[0].title}}</v-card-title>
                    <v-card-text>
                      <CalendarEvent :event="calendarEvents[0]" />
                    </v-card-text>
                  </v-card>
                  <v-card v-else class="calendar-event-card">
                    <v-card-title class="card-title">UP NEXT</v-card-title>
                    <v-row>
                      <v-col cols="4" md="4" class="ml-1 pr-0">
                        <img src="@/assets/svg/theme/calendar.svg" class="pl-2" />
                      </v-col>
                      <v-col cols="8" md="7" class="mt-4 static-text">
                        You have no upcoming events</v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" class="pa-4 pt-0 pr-3 pl-sm-2">
                  <v-card class="calendar-event-card" v-if="false">
                    <v-card-title>{{calendarEvents[1].title}}</v-card-title>
                    <v-card-text>
                      <CalendarEvent :event="calendarEvents[1]" />
                    </v-card-text>
                  </v-card>
                  <v-card v-else class="calendar-event-card">
                    <v-card-title class="card-title">NEXT DELIVERY</v-card-title>
                    <v-row>
                      <v-col cols="4" md="4" class="ml-1 pr-0">
                        <img src="@/assets/svg/theme/calendar.svg" class="pl-2" />
                      </v-col>
                      <v-col cols="8" md="7" class="mt-4 static-text">
                        You have no upcoming deliveries</v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row> -->
              <v-row class="ma-0 notifications-card">
                <v-col cols="12" class="pt-5 pa-0">
                  <DashboardNotifications :clientDashboard="true" />
                </v-col>
              </v-row>
           </v-container>
         </v-col>
        <!-- <v-card class="h-100 content-tracker-card pt-5">
          <v-card-title class="pa-0 pl-5">CONTENT STAGE TRACKER</v-card-title>
          <v-card-text class="pa-0 overflow-hidden">
            <v-container fluid pa-0 class="tracker-wrapper">
              <v-row class="ma-0">
                <v-col cols="12" md="4" lg="3" class="pa-0 tracker_image">
                  <img src="@/assets/svg/theme/dashboard-client-tracker.svg" class="h-100" />
                </v-col>
                <v-col cols="12" md="8" lg="9">
                  <CustomTracker :steps="steps" :currentStep="currentStep" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card> -->
      </v-col>
       <v-col cols="12" md=4 class="pa-0 pl-3 mobile-creative-padding mobile-left-0">
         <v-row class="ma-0 pa-0 pl-3 mobile-left-0">
           <v-col cols=12 class="pa-0">
              <v-card class="creative-team-card creative-height pa-5">
                <v-container fluid pa-0>
                  <v-row class="ma-0">
                    <v-col class="d-flex align-center pa-0">
                      <v-card-title class="pa-0">CREATIVE TEAM</v-card-title>
                    </v-col>
                  </v-row>
                  <CreativeTeam :creativeTeam="projectTeam" class="pt-3"
                  :wrapperMaxHeight="'408px'"/>
                </v-container>
              </v-card>
           </v-col>
           <v-col cols=12 class="pa-0 pt-5">
             <v-card class="recent-contentkits pa-4">
                <v-card-title class="pa-0">RECENT CONTENT KITS</v-card-title>
                <div class="pa-1 pb-2 content-kit-height" v-if="contentKits.length">
                  <div v-for="(contentKit, index) in contentKits" :key="contentKit.id"
                  class="pb-1 pa-0">
                    <v-row class="ma-0 pa-0 pb-4 card-height">
                      <v-col cols=6 sm=3 md=12 lg=6 class="px-0 image-card"
                       :class="{'d-none' : $vuetify.breakpoint.mdOnly}">
                        <img
                        :src="cardImage(contentKit.smallImage || contentKit.file)"
                        class="center-cropped"
                         alt="no-image"
                         id="img"
                         :class="contentKit.smallImage === null
                         || contentKit.file === null ?  'default-image' : ''"
                        />
                      </v-col>
                      <v-col cols=6 sm=6 md=12 lg=6 class="pt-4 pr-0 content-kit-name">
                        <v-card-text class="name-text name-ellipsis pa-0">
                          <span class="name-ellipsis mobile-top notranslate">
                           {{ contentKit.name }}
                          </span>
                        </v-card-text>
                        <v-card-text class="delivered pa-0 pt-3"
                         v-if="contentKit.releasedDate !== null">
                          Delivered: {{ releasedDate(contentKit.releasedDate) }}
                        </v-card-text>
                        <v-card-text v-else class="delivered pa-0 pt-6">
                          Delivered:
                        </v-card-text>
                        <v-card-text @click="onContentKitClick(contentKit.id)"
                        class="navigation-text pa-0 pt-1 pb-2">
                          Go to Content Kit
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-divider v-if="index !== last"></v-divider>
                  </div>
                </div>
                <div v-else class="content-kit-height d-flex justify-center">
                  <div class="content-heading d-flex justify-center pt-4">
                    This is where your goodies live. Come back once you have delivery!
                  </div>
                </div>
             </v-card>
           </v-col>
         </v-row>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { lookup } from 'mime-types';
// import CustomTracker from '@/components/common/CustomTracker';
import DashboardNotifications from '@/components/common/DashboardNotifications';
// import CalendarEvent from '@/components/common/CalendarEvent';
import CreativeTeam from '@/components/dashboard/screens/common/CreativeTeam';
// import ProjectHtmlContent from '@/components/projects/ProjectHtmlContent';
import MyContentPlan from '@/components/dashboard/screens/common/MyContentPlan';
import DeliverablesList from '../common/DeliverablesList';

const namespace = 'dashboard';
export default {
  /* eslint-disable global-require */
  components: {
    // CustomTracker,
    // CalendarEvent,
    CreativeTeam,
    // ProjectHtmlContent,
    DashboardNotifications,
    MyContentPlan,
    DeliverablesList,
  },
  computed: {
    ...mapGetters(namespace, ['executiveTeam', 'creativeTeam', 'producersTeam', 'project', 'recentContentKits']),
    projectTeamDetails() {
      return (users, userRole) => _.map(users, (user) => ({
        profilePic: user.profilePic,
        smallImage: user.smallImage,
        thumbnail: user.thumbnail,
        firstName: user.firstName,
        lastName: user.lastName,
        skill: userRole === 'creative' ? this.creativeSkillsets(user) : userRole,
        number: user.number,
        email: user.email,
        bio: user.bio,
        userPronouns: user.userPronouns,
        userId: user.id,
        availability: user.availability,
        role: user.role.name,
        deletedAt: user.deletedAt,
      }));
    },
    projectTeam() {
      const producers = this.projectTeamDetails(
        _.map(_.filter(this.producersTeam, ['accepted', '1']), 'user'),
        'Content Producer',
      );
      const executives = this.projectTeamDetails(
        _.map(_.filter(this.executiveTeam, ['accepted', '1']), 'user'),
        'Production Manager',
      );

      const creativeTeam = this.projectTeamDetails(
        _.map(_.filter(this.creativeTeam, ['accepted', '1']), 'user'),
        'creative',
      );
      return _.concat(producers, executives, creativeTeam);
    },
    creativeSkillsets() {
      return (member) => {
        const skillsets = _.get(member, 'creativeProfile.skillsets', []);
        return skillsets.map((x) => (x.name === 'Other' ? x.skillSetName : x.name)).join(', ');
      };
    },
    sweep() {
      return _.get(this.project, 'sweeps[0]', []);
    },
    lastContentKit() {
      return _.get(this.project, 'lastKitValues', {});
    },
    allContentKits() {
      return _.get(this.project, 'allKitValues', {});
    },
    contentKitPercentages() {
      return _.get(this.project, 'contentKitsPercentages', []);
    },
    defaultSubscription() {
      return _.get(this.project, 'projectSnapshot', {});
    },
    deliverableList() {
      return _.get(this.project, 'deliverablesList', []);
    },
    sweepDate() {
      return moment(this.sweep.date).format('MMMM D, YYYY');
    },
    sweepTime() {
      return (time) => moment((time), ['HH:mm']).format('h:mm A');
    },
    sweepSkill() {
      return (skill) => {
        switch (skill) {
          case '1':
            return 'Photographer';
          case '2':
            return 'Videographer';
          case '3':
            return 'Talent';
          default:
            return 'Field Producer';
        }
      };
    },
    sweepMembers() {
      return _.map(_.reject(this.sweep.sweepTeam, ['creatives', null]), (team) => ({
        profilePic: team.creatives.profilePic,
        smallImage: team.creatives.smallImage,
        thumbnail: team.creatives.thumbnail,
        firstName: team.creatives.firstName,
        lastName: team.creatives.lastName,
        skill: this.sweepSkill(team.skill),
        number: team.creatives.number,
        email: team.creatives.email,
        bio: _.get(team.creatives, 'creativeProfile.bio', ''),
        userPronouns: team.creatives.userPronouns,
        availability: team.creatives.availability,
        role: 'client',
        userId: team.creatives.id,
        deletedAt: team.creatives.deletedAt,
      }));
    },
    contentSweeps() {
      return _.isEmpty(_.get(this.project, 'sweeps[0]', []));
    },
    contentKits() {
      const kits = _.slice(this.recentContentKits, 0, 2);
      const contentKit = _.map(kits, (kit) => kit);
      return contentKit;
    },
    releasedDate() {
      return (date) => moment(date).format('MM/DD/YYYY');
    },
    cardImage() {
      return (contentKitFile) => {
        const validImage = contentKitFile && this.isFileImage(lookup(contentKitFile));
        return validImage ? contentKitFile : this.defaultImage;
      };
    },
    defaultImage() {
      return require('@/assets/svg/theme/project-banner-default.svg');
    },
    last() {
      return Object.keys(this.contentKits).length - 1;
    },
  },
  data() {
    return {
      calendarEvents: [
        {
          title: 'NEXT SWEEP',
          type: 'Sweep',
          actionText: 'View treatment',
        },
        {
          title: 'NEXT DELIVERY',
          type: 'Content Kit Delivery',
          actionText: 'View content kit',
        },
      ],
    };
  },
  methods: {
    ...mapActions(namespace, ['getDashboardDetails']),
    isFileImage(format) {
      return format && ['jpg', 'png', 'jpeg', 'gif', 'svg'].includes(format.split('/')[1]);
    },
    onContentKitClick(contentKitId) {
      const projectId = this.project.id;
      this.$router.push(`library/${projectId}/content_kits/${contentKitId}`);
    },
  },
  mounted() {
    this.getDashboardDetails();
  },
};
</script>
<style lang="scss" scoped>
.static-text {
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: normal;
}
::v-deep .v-card__title {
  font-size: 14px;
  font-weight: bold;
  color: #565682;
}
.tracker-wrapper {
  min-height: 100px;
}
.deliverable-card {
  > div {
        max-width: 100%;
      }
      .name-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
}
.card-heading {
  color: #565682;
  font-size: 14px;
  font-family: $fontFamily1;
  font-weight: bold;
  text-align: left;
  letter-spacing: -0.28px;
}
.calendar-event-card {
  height: 200px;
  .v-card__title {
    padding: 0;
  }
}
.deliverables-card {
  font-family: $fontFamily1;
  height: 195px;
  padding: 10px;
  ::v-deep .v-card__title {
    font-family: $fontFamily1;
  }
}
.creative-team-card {
  min-height: 400px;
}
.tracker_image {
  height: 110px;
}

.v-card__text {
  font-size: 16px;
  line-height: 1.56;
  overflow: auto;
}
.v-chip {
    height: 20px !important;
    border-radius: 7.5px !important;
}
::v-deep .v-chip__content {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.24px;
  text-align: center;
  color: $neutral6;
}
.categories {
    margin-top: 15px;
}
.category {
    margin-right: 5px;
}
.upcoming-event-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .heading,
  .action {
    font-size: 14px;
    font-family: $fontFamily1;
    letter-spacing: -0.28px;
    color: $neutral1;
  }
  .main-content {
    font-family: $fontFamily1;
    font-size: 14px;
    font-weight: bold;
    color: $neutral1;
  }
  .action {
    color: $secondary1;
    margin-right: 4px;
  }
}
.sweep-team {
  height: 55px;
  position: relative;
}
::v-deep.upcoming-event-card > .v-card__text {
  padding: 20px;
  height: 180px;
  display: flex;
  align-self: center;
  justify-content: center;
}
.center-align {
    display: flex;
    justify-content: center;
    padding-top: 6px !important;
}
.heading {
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.18px;
  color: $charcoalBlack
}
.navigation-text {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: $secondary1;
}
.heading {
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.18px;
  color: $charcoalBlack;
}
.delivered{
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: 500;
  color: $neutral7;
}
::v-deep.name > .v-card__text {
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: 500;
  color:$neutral1;
}
.recent-contentkits {
  font-family: $fontFamily1;
  padding: 10px;
  height: 391px;
  ::v-deep .v-card__title {
    font-family: $fontFamily1;
  }
  > div {
        max-width: 100%;
      }
      .name-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
}
.content-heading {
  font-size: 14px;
  font-family: $fontFamily1;
  font-weight: normal;
  color: #727272;
  line-height: 1.66;
  letter-spacing: normal;
}
.image-card {
  display: flex;
  align-self: center;
  justify-content: center;
  height: 160px;
}
.card-height {
  height: 170px;
}
.sweep-height {
  height: 195px;
}
.creative-height {
  height: 500px;
}
.content-height {
  height: 286px;
}
.deliverables-card {
  font-family: $fontFamily1;
  height: 195px;
  padding: 10px;
  ::v-deep .v-card__title {
    font-family: $fontFamily1;
  }
}
.col-align {
  padding: 0 15px ;
  display: flex;
  justify-content: center;
}

@media (min-width: 600px) {
  .content-tracker-card {
    .v-card__title {
      padding-top: 24px;
      padding-left: 24px;
    }
  }
  .upcoming-event-card:last-child {
    margin-bottom: 0;
  }
  .mobile-dashboard {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
@media (min-width: 960px) {
  .col-align {
    overflow: auto;
    height: 120px;
  }
  .content-kit-height {
    height: 340px;
    img {
      max-height: 150px;
      width: 100%;
      object-fit:contain;
    }
    img.default-image {
      width: 80% !important;
      max-height: 150px !important;
      object-fit:contain;
    }
  }
}
@media (min-width: 1264px) {
  ::v-deep .calendar-event-card,
  .deliverables-card {
    .v-card__title,
    .v-card__text {
      padding: 0;
    }
  }
}
 .content-kit-name {
   padding: 25px 0px 0px 10px !important;
 }
@media (max-width: 960px) {
  .mobile-creative-padding {
    padding-top: 20px !important;
  }
  .mobile-content-sweep {
    padding-right: 0px !important;
  }
  .mobile-left-0 {
    padding-left: 0px !important;
  }
  .col-align {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    padding: 0 5px !important;
  }
  .center-align {
    display: flex;
    justify-content: center;
    padding-top: 6px !important;
  }
  .content-kit-name {
   padding: 30px 0px 0px 10px !important;
  }
  .content-kit-height {
    img.default-image {
      width: 30% !important;
    }
  }
  .no-content-kit-height {
    height: 80px !important;
  }
  .recent-contentkits, .card-height {
    height: 100%;
  }
  .delivered {
      padding-top: 30px !important;
    }
  .recent-contentkits {
    padding-bottom: 0px !important;
  }
  .center-cropped {
    object-position: center;
    width: 120px !important;
    height: 125px !important;
  }
  .mobile-top {
    padding-top: 5px;
  }
}
@media (max-width: 600px) {
  .mobile-padding {
    padding: 12px !important;
  }
  .content-kit-name {
   padding: 0px !important;
 }
 .padding-dashboard {
   padding: 15px !important;
 }
 .mobile-top {
   padding-top: 26px !important;
 }
 .delivered {
    padding-top: 24px !important;
  }
  .content-height {
    height: max-content;
  }
  .mobile-dashboard {
    padding: 20px !important;
  }
  .image-card {
    padding-right: 10px !important;
    padding-bottom: 0px !important;
    height: 155px !important;
  }
  .content-kit-height {
    img.default-image {
      width: 80px !important;
      height: 105px !important;
      object-fit:contain;
    }
    .content-height {
      height: max-content;
    }
  }
}
@media (max-width: 1023px) {
  .content-kit-height {
    height: 100%;
  }
}
@media (min-width: 961px) and (max-width: 1260px) {
  .delivered, .content-kit-name {
    padding-top: 16px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .card-height {
    height: 140px !important;
  }
  .content-height {
    height: 100%;
  }
}
</style>
