<template>
  <v-container fluid pa-0 class="onboarding">
      <v-tabs-items
        v-model="screen"
        class="h-100"
      >
        <v-tab-item
        v-for="(tab, index) in (totalScreens+1)"
        :key="index"
        :class="{'h-100': !$vuetify.breakpoint.xsOnly}"
        >
          <ScheduleACall v-if="index === 0"
          @schedule-call="scheduleCall"
          :onlySchedule="onlySchedule"
          />
          <SideNavbar
            v-else-if="index >= 1"
            :screen="screen"
            :totalScreens="totalScreens"
            :finalScreen="finalScreen"
            :onlyProfile="onlyProfile"
            @save-and-do-later="saveAndDoLater"
            @next-screen="nextScreen"
            @previous-screen="previousScreen"
          >
            <DemographicDetails v-if="index === 1" :screen="screen"/>
            <TagDetails
              v-if="index > 1 && screen > 1"
              :role="role"
              :screen="screen"
              :screenIndex="index"
              :scenario="scenario(index)"
            />
          </SideNavbar>
        </v-tab-item>
      </v-tabs-items>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import EventBus from '@/helpers/event-bus';
import ScheduleACall from '@/components/dashboard/screens/ScheduleACall';
import SideNavbar from '@/components/dashboard/screens/SideNavbar';
import DemographicDetails from '@/components/dashboard/screens/DemographicDetails';
import TagDetails from '@/components/dashboard/screens/TagDetails';

export default {
  name: 'Onboarding',
  components: {
    ScheduleACall, SideNavbar, DemographicDetails, TagDetails,
  },
  props: {
    onlySchedule: {
      type: Boolean,
      default: false,
    },
    onlyProfile: {
      type: Boolean,
      default: false,
    },
    initialScreen: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return ({
      screen: this.initialScreen,
      totalScreens: 2,
    });
  },
  computed: {
    ...mapState(['onboarding']),
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('profile', ['skillsets']),
    finalScreen() {
      return this.screen === this.totalScreens;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
  },
  methods: {
    scheduleCall(status) {
      if (status) {
        // TODO: Need to add schedule a call functionality
      }
      if (this.onlySchedule) {
        this.$emit('on-submit', 'schedule');
      } else {
        this.nextScreen({ screen: 0 });
      }
    },
    hasSkill(skillsets, skillName) {
      return skillsets.some((x) => (x.name === skillName));
    },
    isCopyWriter(skillsets) {
      return this.hasSkill(skillsets, 'Copywriter');
      // const set = _.find(this.skillsets, (y) => y.id === x);
    },
    isGraphicDesigner(skillsets) {
      return this.hasSkill(skillsets, 'Graphic Designer');
      // return skillsets.some((x) => (x.name === 'Graphic Designer'));
      // const set = _.find(this.skillsets, (y) => y.id === x);
    },
    checkingSkillSets(skillsets) {
      return skillsets.some((skill) => _.includes(['Photographer', 'Videographer', 'Copywriter'], skill.name));
    },
    hasOtherSkillset(skillsets) {
      return this.hasSkill(skillsets, 'Other');
    },
    checkCreativeScenario() {
      const skillsets = _.get(this.onboarding, 'creative.skillsets', []);
      if ((skillsets.length > 1) && this.isCopyWriter(skillsets)) {
        if (skillsets.length === 2
            && (this.isGraphicDesigner(skillsets) || (this.hasOtherSkillset(skillsets)))) {
          return false;
        }
        if (skillsets.length === 3
            && (this.isGraphicDesigner(skillsets) && (this.hasOtherSkillset(skillsets)))) {
          return false;
        }
        return true;
      }
      return false;
    },
    scenario(index) {
      const skillsets = _.get(this.onboarding, 'creative.skillsets', []);
      if (index === 2) {
        if (this.isCopyWriter(skillsets)) {
          if (skillsets.length === 1) {
            return 2;
          }
          if (skillsets.length === 2
            && (this.isGraphicDesigner(skillsets) || (this.hasOtherSkillset(skillsets)))) {
            return 2;
          }
          if (skillsets.length === 3
            && (this.isGraphicDesigner(skillsets) && (this.hasOtherSkillset(skillsets)))) {
            return 2;
          }
          return 1;
        }
        return 1;
      }
      return 2;
    },
    saveAndDoLater() {
      this.$emit('on-submit', 'save-and-do-later');
    },
    previousScreen() {
      if (this.screen === 2 && this.role === 'creative') {
        if (this.checkCreativeScenario()) {
          this.totalScreens = this.totalScreens - 1;
        }
      }
      this.screen = this.screen - 1;
    },
    nextScreen(options) {
      const screen = _.get(options, 'screen');
      const resolved = _.get(options, 'resolved');
      if (resolved) {
        if (this.finalScreen) {
          this.$emit('on-submit', 'profile');
        } else {
          if (this.screen === 1 && this.role === 'creative') {
            if (this.checkCreativeScenario()) {
              this.totalScreens = this.totalScreens + 1;
            }
          }
          this.screen = this.screen + 1;
        }
      } else {
        switch (screen) {
          case 1:
            EventBus.$emit('demographic-details-submit');
            break;
          case 2:
          case 3:
            EventBus.$emit('tag-details-submit');
            break;
          default:
            this.screen = this.screen + 1;
            break;
        }
      }
    },
  },
  mounted() {
    EventBus.$on('demographic-details-resolved', () => {
      this.nextScreen({ resolved: 1 });
    });
    EventBus.$on('tag-details-resolved', () => {
      this.nextScreen({ resolved: 1 });
    });
  },
  beforeMount() {
    EventBus.$on('skill-set-changed', () => {
      const skillsets = _.get(this.onboarding, 'creative.skillsets', []);
      this.totalScreens = this.checkingSkillSets(skillsets) ? 2 : 1;
    });
  },
  beforeDestroy() {
    EventBus.$off('demographic-details-resolved');
    EventBus.$off('tag-details-resolved');
    EventBus.$off('skill-set-changed');
  },
};
</script>

<style lang="scss" scoped>
  .onboarding {
    background-color: $neutral6;
    height: 100%;
  }
  @media (max-width: 600px) {
    .onboarding {
      background-color: $neutral3;
    }
  }
</style>
