<template>
    <div>
      <div class="content-wrapper" h-100>
        <v-row class="ma-0">
        <v-col class="d-flex align-center pa-0">
          <BaseInput
            solo
            hide-details
            v-model="search"
            placeholder="Search ..."
            prepend-inner-icon="search"
            class="mt-1 search-box"
            clearable
            @click:clear="search === ''"
          />
        </v-col>
        <v-col class="d-flex align-center justify-end pa-0">
          <div class="pr-0 export-header">
        <v-card flat>
          <v-card-text class="d-flex justify-end pa-1 pr-5">
            <div class="d-flex pt-0 mb-0 pb-0 export-button align-center">
             <v-btn text @click="downloadCSV()" class="export-button" color="info">Export</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
        </v-col>
      </v-row>
        <div class="listing-block">
          <Loader v-if="loading" />
          <CreditReportsList
            v-else
            :reports="reports"
            :fetching="loading"
            @onEditClick="goToChangeCredits"
            :search="search"
            @setDisplayedRows="setRowsData"
          />
        </div>
      </div>
      <v-container
      fluid
      v-if="creditsModel"
    >
      <Modal
        persistent
        content-class="new-user-form"
        :modal="creditsModel"
        width="500px"
        scrollable
      >
        <EditCreditsModel
          @reload-list="reloadList"
          @closemodal="creditsModel = false"
          :business="this.selectedBusiness"
        />
      </Modal>
    </v-container>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import BaseInput from '@/components/common/BaseInput';
import CreditReportsList from '@/components/dashboard/screens/Admin/Reports/CreditReportsList';
import Loader from '@/components/common/Loader';
import Modal from '@/components/common/Modal';
import EditCreditsModel from '../Forms/EditCreditsModel';

export default {
  components: {
    BaseInput,
    CreditReportsList,
    Loader,
    Modal,
    EditCreditsModel,
  },

  computed: {
    reports() {
      return _.get(this.creditReports, 'creditReport');
    },
  },

  methods: {
    ...mapActions('admin', ['getCreditReportList']),
    async reloadList() {
      this.loading = true;
      this.creditReports = await this.getCreditReportList();
      this.loading = false;
    },
    setRowsData(data) {
      this.exportData = data;
    },
    async downloadCSV() {
      const payload = this.exportData.map((data, i) => ({
        sno: i + 1,
        business_Name: data.data.name,
        available_credits: data.data.credits,
        lifetime_credits: data.data.availedcredits,
        last_purchased_date: data.data.date,
      }));
      const csvContent = [
        _.map(Object.keys(payload[0]), (key) => key.toUpperCase()).join(';'),
        ...payload.map((item) => Object.values(item).join(';')),
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '');
      const blob = new Blob([csvContent], {
        type: 'text/csv;charset=utf-8',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      const fileName = 'credits_report';
      link.setAttribute('href', url);
      link.setAttribute('download', `${fileName.toLowerCase()}.csv`);
      link.click();
    },
    goToChangeCredits(val) {
      this.selectedBusiness = val;
      this.creditsModel = true;
    },
  },
  async mounted() {
    this.loading = true;
    this.creditReports = await this.getCreditReportList();
    this.loading = false;
  },
  data() {
    return {
      selectedBusiness: {},
      creditsModel: false,
      creditReports: [],
      search: '',
      loading: false,
    };
  },
};
</script>

<style scoped lang="scss">
  ::v-deep .v-label {
    font-family: $fontFamily1;
    font-size: 14px;
    color: $neutral1;
  }
  .sort-text {
    font-family: $fontFamily1;
    font-size: 14px;
  }
  ::v-deep .v-text-field {
    font-family: $fontFamily1;
    font-size: 14px;
  }
  .search-box ::v-deep {
    .v-input__slot {
      border: 1px solid  #b0b0b0 !important;
      box-shadow: none !important;
      border-radius: 0 !important;
    }
    .v-input__control {
      input {
        max-height: 34px !important;
      };
    }
  }
  .search-box {
    ::v-deep .v-text-field {
      height: 40px;
      font-size: 16px;
    }
  }
  .v-data-table {
    ::v-deep th {
      font-weight: bold;
      font-size: 16px;
      color: $neutral1 !important;
    }
    ::v-deep td {
      font-size: 14px;
      color: $neutral1;
      font-family: $fontFamily1;
    }
    ::v-deep td:nth-child(1) {
      color: #0373d1;
    }
    ::v-deep tr:nth-child(even) {
      background-color: #f8f8f8;
    }
  }
  .listing-block {
    height: calc(100vh - 304px);
    overflow: auto;
  }
  .content-wrapper {
      padding: 0px;
  }
  @media (max-width: 600px) {
    .listing-block {
      height: calc(100vh - 255px);
    }
  }
</style>
