<template>
  <div class="h-100">
    <!-- {{ reports }} -->
    <base-list
      :headers="headers"
      @onEditClick="$emit('onEditClick')"
      :items="items"
      :fetching="fetching"
      @setRowsData="setRows"
      :isSetRows="true"
      :search="search"
    >
    </base-list>
  </div>

</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import BaseList from '@/components/admin/common/BaseList';
import BtnCellRenderer from '@/components/projects/ProjectTeam/BtnCellRenderer';

export default {
  components: {
    BaseList,
  },
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    timeConvert() {
      return (n) => {
        const num = n;
        const hours = num / 60;
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        const checkForMin = `${rminutes > 0 ? `${rminutes} min` : ''}`;
        const checkForHours = `${rhours > 0 ? `${rhours} hrs` : checkForMin}`;
        const text = rhours > 0 && rminutes > 0 ? `${rhours} hrs ${rminutes} min` : checkForHours;
        return text;
      };
    },
    headers() {
      const $this = this;
      const items = [
        $this.nameColumn(),
        $this.titleColumn(),
        $this.creativeNameColumn(),
        $this.creativeTypeColumn(),
        $this.dateColumn(),
        $this.timeColumn(),
        $this.sweepTimeColumn(),
        $this.locationColumn(),
        $this.payoutColumn(),
        $this.reimbursementColumn(),
        $this.editColumn(),
      ];
      return items;
    },
    reportList() {
      return this.reports.filter((x) => x !== null);
    },
    items() {
      return _.map(this.reportList, (report) => ({
        id: report,
        name: `${_.get(report.sweep.business, 'name', '')}`,
        sweep: report.sweep.title,
        date: moment(report.sweep.date).format('MMM D'),
        time: moment((report.sweep.fromTime), ['HH:mm']).format('h:mmA'),
        location: `${_.map(report.sweep.sweepLocations, 'location', '')}`,
        creativeName: `${_.get(report.creatives, 'firstName', '')}`,
        creativeType: this.getSkills(report.skill),
        sweepTime: this.duration(report.sweep),
        // eslint-disable-next-line
        reimbursement: `$${report.sweep.sweepCreativePayout.map((x) => this.reimbursementField(x.reimbursement))}`,
        payout: `$${_.map(report.sweep.sweepCreativePayout, 'payout', '')}`,
      }));
    },
  },

  methods: {
    setRows(data) {
      this.$emit('setDisplayedRows', data);
    },
    reimbursementField(val) {
      if (val === null) {
        return 0;
      }
      return val;
    },
    editClick(val) {
      this.$emit('onEditClick', val);
    },
    duration(val) {
      const startTime = moment(val.fromTime, 'hh:mm a');
      const endTime = moment(val.closedAt, 'hh:mm a');
      const diff = moment.duration(endTime.diff(startTime));
      return this.timeConvert(diff.asMinutes());
    },
    getSkills(val) {
      switch (val) {
        case '1':
          return 'Photographer';
        case '2':
          return 'Videographer';
        case '3':
          return 'Talent';
        default:
          return 'Field Producer';
      }
    },
    nameColumn() {
      return {
        headerName: 'Business Name',
        field: 'name',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        width: 100,
        cellClass: 'notranslate pt-4',
      };
    },
    titleColumn() {
      return {
        headerName: 'Sweep Name',
        field: 'sweep',
        sortable: true,
        filter: true,
        width: 100,
        cellClass: 'notranslate pt-4',
      };
    },
    dateColumn() {
      return {
        headerName: 'Date',
        field: 'date',
        sortable: true,
        filter: true,
        width: 60,
        cellClass: 'notranslate pt-4',
      };
    },
    locationColumn() {
      return {
        headerName: 'Location',
        field: 'location',
        sortable: true,
        filter: true,
        width: 100,
        cellClass: 'notranslate pt-4',
        // cellStyle: { paddingRight: '0px !important' },
      };
    },
    creativeNameColumn() {
      return {
        headerName: 'Creative Name',
        field: 'creativeName',
        sortable: true,
        filter: true,
        width: 110,
        cellClass: 'notranslate pt-4',
      };
    },
    creativeTypeColumn() {
      return {
        headerName: 'Creative Type',
        field: 'creativeType',
        sortable: true,
        filter: true,
        width: 100,
        cellClass: 'notranslate pt-4',
      };
    },
    sweepTimeColumn() {
      return {
        headerName: 'Sweep Time',
        field: 'sweepTime',
        sortable: true,
        filter: true,
        width: 80,
        cellClass: 'notranslate pt-4',
        cellStyle: { paddingRight: '0px !important' },
      };
    },
    payoutColumn() {
      return {
        headerName: 'Payout',
        field: 'payout',
        sortable: true,
        filter: true,
        width: 80,
        cellClass: 'notranslate pt-4 pr-0',
      };
    },
    reimbursementColumn() {
      return {
        headerName: 'Reimbursement',
        field: 'reimbursement',
        sortable: true,
        filter: true,
        width: 75,
        cellClass: 'notranslate pt-4 pr-0',
      };
    },
    editColumn() {
      const $this = this;
      return {
        headerName: 'Edit',
        field: 'id',
        cellClass: 'action d-flex ml-0 pr-0 cursor-pointer',
        cellRendererFramework: BtnCellRenderer,
        cellRendererParams: {
          image: 'edit',
          clicked(params) {
            $this.editClick(params.value);
            return false;
          },
        },
        width: 45,
      };
    },
    timeColumn() {
      return {
        headerName: 'Time',
        field: 'time',
        sortable: true,
        filter: true,
        width: 60,
        cellClass: 'notranslate pt-4',
      };
    },
  },
};
</script>
