<template>
<v-container fluid pa-0>
  <v-card class=" pa-4 ma-0 my-content-height">
    <v-row class="ma-0">
      <v-col cols="6" class="d-flex align-center pa-0">
        <div class="text-uppercase card-heading pl-0">{{ name }}</div>
      </v-col>
    </v-row>
    <v-row class="ma-0 d-flex justify-space-around">
      <v-col cols="12" sm="3" class="info-height pa-0 mobile-pb-3">
        <template>
        <v-row>
          <v-col v-if="role === 'client' ||  role=== 'member'"
           class="py-0 title name-ellipsis">
            <span>Your current capture is: </span>
          </v-col>
           <v-col v-else class="py-0 title name-ellipsis">
            <span>Current capture is: </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0 text">{{ getBlendAccess }}</v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-0 title name-ellipsis"> Production Credits </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0 text">
            {{subscription.totalCredits === null ? 0 : subscription.totalCredits}}/Content Kit
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pb-0 title name-ellipsis"> Suggested Platforms </v-col>
        </v-row>
        <v-row>
        <v-col class="py-0 name-ellipsis" cols=12
        v-for="(platform,index) in subscription.socialTags"
        :key="platform">
        <span v-if="index <= 2">
            {{platform.name}}
        </span>
        <span
        v-if="index === 3"
        class="more-text"
        >
      <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
      <span
         v-bind="attrs"
         v-on="on"
        >View more</span>
      </template>
      <v-col class="py-0 tool" cols=12 v-for="platform in subscription.socialTags" :key="platform">
      {{platform.name}}
      </v-col>
      </v-tooltip>
          </span>
          </v-col>
        </v-row>
        </template>
      </v-col>
      <v-divider class="horizontal-line"></v-divider>
      <v-divider vertical class="line-height"></v-divider>
      <v-col cols="12" sm="4" class="pa-0 mobile-padding mobile-pb-3">
        <div class="tab-section-header d-flex">
          <div
            class="tab-section-item pie-heading"
            :class="{active:tab==index}"
            :key="item"
            v-for="(item,index) in items"
            @click="tab=index"
          >
            {{ item }}
          </div>
        </div>
        <v-tabs-items v-model="tab">
      <v-tab-item
          v-for="item in items"
          :key="item"
        >
        <template v-if="series">
          <div id="chart">
            <apexchart type="pie" class="d-flex justify-center pa-0"
              width="225" height="218"
              :options="chartOptions"
              :series="series"
            >
            </apexchart>
          </div>
        </template>
        <template v-else>
          <v-row class="ma-0 d-flex justify-center pt-3">
              <img src="@/assets/svg/theme/empty-pie-chart.svg" class="pie-height"/>
          </v-row>
          <v-row class="ma-0 pa-1">
            <v-col class="d-flex justify-center pt-0 pl-0 sub-text" cols="12">
             <div>
               You don’t have any delivered assets just yet. Come back later.
              </div>
            </v-col>
          </v-row>
        </template>
        </v-tab-item>
      </v-tabs-items>
      </v-col>
      <v-divider class="horizontal-line"></v-divider>
      <v-divider vertical class="line-height"></v-divider>
      <v-col cols="12" sm="4" class="pa-0">
        <div class="carousel">
          <v-carousel :show-arrows="false" v-model="slider"
            v-if="contentKitPercentages.length > 0"
            hide-delimiter-background
          >
           <v-carousel-item
              v-for="(slide, i) in contentKitPercentages"
              :key="i"
            >
              <template v-if="slide">
                <v-card-text
                class="content-name pa-0 d-flex justify-center mobile-top-pt-3 notranslate">
                {{slide.contentKit.name}}
                </v-card-text>
                <div id="chart" class="px-1 d-flex justify-center" v-if="slide.percentage > 0">
                  <apexchart type="radialBar" :options="options"
                  :series="[slide.percentage]"
                  width="200" height="205">
                  </apexchart>
                </div>
                <div v-else>
                  <v-row class="ma-0 d-flex justify-center pt-3">
                    <img src="@/assets/svg/theme/progress.svg" class="bar-height"/>
                  </v-row>
                  <v-row class="ma-0 pa-1 pt-2">
                   <v-col class="d-flex justify-center pa-0 sub-text" cols="12">
                      <div>
                      This Content Plan is in progress.
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </v-carousel-item>
          </v-carousel>
           <template v-if="contentKitPercentages.length === 0">
              <v-card-text class="content-name pa-0 d-flex justify-center mobile-top-pt-3">
              My Content Plan
              </v-card-text>
              <v-row class="ma-0 d-flex justify-center pt-3">
                  <img src="@/assets/svg/theme/progress.svg"
                   :class="slider ? 'bar-height' : 'no-slide'"/>
              </v-row>
              <v-row class="ma-0 pa-1">
                <v-col class="d-flex justify-center pa-0 sub-text" cols="12">
                <div>
                  You don’t have a Content Plan yet. A status of it’s progress will show here.
                </div>
                </v-col>
              </v-row>
            </template>
        </div>
      </v-col>
    </v-row>
  </v-card>
</v-container>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'MyContentPlan',
  props: {
    allContentKits: {
      type: Object,
      required: true,
    },
    lastContentKit: {
      type: Object,
      required: true,
    },
    contentKitPercentages: {
      type: Array,
      default: () => [],
    },
    subscription: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    getBlendAccess() {
      return _.get(this.subscription, 'subscriptionBlend.access');
    },
    name() {
      return this.$route.name === 'Project' ? 'SNAPSHOT' : 'MY CONTENT';
    },
    series() {
      if (this.tab === 0 && ((this.lastContentKit.photoCount !== 0
      && this.lastContentKit.photoCount !== undefined) || (this.lastContentKit.videoCount !== 0
      && this.lastContentKit.videoCount !== undefined) || (this.lastContentKit.copyCount !== 0
      && this.lastContentKit.copyCount !== undefined))) {
        const series = [this.lastContentKit.photoCount, this.lastContentKit.videoCount,
          this.lastContentKit.copyCount];
        return series;
      }
      if (this.tab === 1 && ((this.allContentKits.photoCount !== 0
      && this.allContentKits.photoCount !== undefined) || (this.allContentKits.videoCount !== 0
      && this.allContentKits.videoCount !== undefined) || (this.allContentKits.copyCount !== 0
      && this.allContentKits.copyCount !== undefined))) {
        const series = [this.allContentKits.photoCount, this.allContentKits.videoCount,
          this.allContentKits.copyCount];
        return series;
      }
      return 0;
    },
  },
  data() {
    return {
      tab: 0,
      slider: 0,
      items: ['Last Content kit', 'All content Kits'],
      chartOptions: {
        chart: {
          type: 'pie',
        },
        colors: ['#d4f7fe', '#ffe6c5', '#a4d0df'],
        tooltip: {
          enabled: false,
        },
        stroke: {
          show: false,
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'darken',
              value: 0.90,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'darken',
              value: 0.90,
            },
          },
        },
        legend: {
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Poppins',
          fontWeight: 'normal',
          color: '#000000',
          offsetY: 2,
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: false,
          },
        },
        dataLabels: {
          enabled: true,
          formatter(val, opts) {
            return opts.w.config.series[opts.seriesIndex];
          },
          dropShadow: {
            enabled: false,
          },
          style: {
            fontSize: '14px',
            fontFamily: 'Poppins',
            fontWeight: 'normal',
            colors: ['#1b1c1e;', '#1b1c1e;', '#1b1c1e;'],
          },
        },
        labels: ['Photos', 'Video', 'Copy'],
        plotOptions: {
          customScale: 0.9,
          pie: {
            expandOnClick: false,
            offsetY: 5,
          },
          dataLabels: {
            position: 'bottom',
          },
        },
      },
      options: {
        chart: {
          type: 'radialBar',
        },
        colors: ['#65D6EB', '#cfd8dc'],
        plotOptions: {
          radialBar: {
            track: {
              background: '#cfd8dc',
            },
            hollow: {
              size: '60%',
            },
            offsetY: -2,
            dataLabels: {
              showOn: 'always',
              value: {
                offsetY: -15,
                color: '#1b1c1e',
                fontSize: '22px',
                fontFamily: 'IBMPlexSans',
                fontWeight: 'bold',
                show: true,
                formatter(val) {
                  // eslint-disable-next-line prefer-template
                  return val.toFixed(1) + '%';
                },
              },
              name: {
                offsetY: 20,
                show: true,
                color: '#1b1c1e',
                fontSize: '10px',
                fontFamily: 'Poppins',
                fontWeight: 'bold',
              },
            },
          },
        },
        stroke: {
          lineCap: 'butt',
        },
        labels: ['COMPLETE'],
      },
    };
  },
};
</script>

<style scoped lang="scss">
.tab-section-header{
  display: flex;
  justify-content: space-between;
}
.tab-section-item{
  padding: 0.5rem;
  cursor: pointer;
  &.active{
    border-bottom: 1px solid black;
  }
}
.my-content-height {
  // height: 286px;
}
.card-heading{
  color: #565682;
  font-size: 14px;
  font-family: $fontFamily1;
  font-weight: bold;
  text-align: left;
  letter-spacing: -0.28px;
}
.my-content-plan {
  color: #00b2d5;
  font-family: $fontFamily1;
  font-weight: normal;
  text-align: left;
  ::v-deep .v-btn__content {
    font-size: 14px;
    line-height: 1.38;
    letter-spacing: -0.38px
  }
}
.more-text {
    color: $primary1;
    cursor: pointer;
    font-size: 16px;
}
.info-height{
  height: 100px;
}
.title{
  color: #727272;
  font-family: $fontFamily1 !important;
  font-size: 14px !important;
  font-weight: normal;
  line-height: 1.4;
  text-align: left;
}
.text {
  color: #1b1c1e;
  font-family: $fontFamily1 !important;
  font-size: 14px !important;
  font-weight: normal;
  line-height: 1.4;
  text-align: left;
}
.pie-heading {
  color: #1b1c1e !important;
  font-family: $fontFamily1;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}
.v-tab {
  width: 4px !important;
}
.v-tab--active {
  border-bottom: 1px solid #1b1c1e;
}
.v-tabs--grow > .v-tabs-bar .v-tab{
  width: 95px !important;
}
.v-btn:not(.v-btn--round).v-size--default {
   height: 20px;
}
.sub-text {
  color: #727272;
  font-family: $fontFamily1;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  line-height: 1.2;
}
.content-name {
  font-family: $fontFamily1;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  color: $charcoalBlack;
  width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 1.3em
}
.empty-carousel {
  ::v-deep .v-carousel__controls {
    padding-top: 10px;
    align-items: center !important;
  }
}
.carousel {
  ::v-deep .v-carousel__controls {
    height: 22px;
    align-items: flex-start;
  }
  ::v-deep .v-icon {
    font-size: 9px !important;
    align-items: flex-start;
  }
  ::v-deep .v-btn--icon.v-size--small {
    height: 0px;
    width: 0px;
  }
  .v-item-group {
    height: 220px !important;
  }
  ::v-deep {
    .v-carousel__controls__item.v-btn {
      color: #d3d3d3;
    }
  }
  ::v-deep {
   .v-carousel__controls__item.v-btn.v-btn--active {
      color: #9b9b9b;
    }
  }
}
.pie-height {
  height: 130px;
}
.bar-height {
  height: 130px;
}
.no-slide {
  height: 134px;
}
::v-deep .v-item-group {
    max-width: 150%;
}
@media (min-width: 601px) {
  .line-height {
    height: 210px;
    background: #c4c4c4;
  }
  .horizontal-line {
    display: none;
  }
}
@media (max-width: 600px) {
  .my-content-height {
    height: max-content;
  }
  ::v-deep .v-carousel__controls {
    height: 20px !important;
    align-items: center;
  }
  .info-height {
    height: 100%;
  }
  .line-height {
    display: none;
  }
  .horizontal-line {
    border-width: 1px;
    background: #c4c4c4;
  }
  .v-tabs--grow > .v-tabs-bar .v-tab{
    width: 88px !important;
  }
  .mobile-top-pt-3 {
    padding-top: 12px !important;
  }
  .mobile-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .mobile-pb-3 {
    padding-bottom: 12px !important;
  }
  .pie-height {
    height: 130px !important;
  }
  .bar-height {
    height: 130px !important;
  }
}
@media (min-width: 601px) and (max-width: 1100px) {
  .v-slide-group__wrapper {
      display: inline;
  }
  .v-tabs--grow > .v-tabs-bar .v-tab{
    width: 65px !important;
  }
  .mobile-padding {
    padding-right: 0px !important;
  }
  .pie-height {
    height: 110px !important;
  }
  .bar-height {
    height: 125px !important;
  }
  .no-slide {
    height: 120px;
  }
}
@media (min-width: 960px) and (max-width: 1300px) {
  ::v-deep .v-slide-group {
     flex-flow: wrap;
  }
  .pie-heading {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  ::v-deep .v-carousel__controls {
    height: 20px !important;
    align-items: center;
  }
  .v-tabs--grow > .v-tabs-bar .v-tab{
    width: 55px !important;
  }
  ::v-deep .apexcharts-legend.position-bottom.apexcharts-align-center {
    width: 200px;
  }
  .name-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .pie-height {
    height: 100px !important;
  }
  .bar-height {
    height: 120px !important;
  }
  .empty-carousel {
    ::v-deep .v-carousel__controls {
      padding-top: 0px;
      align-items: center !important;
    }
  }
}
</style>
