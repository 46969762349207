<template>
  <div class="h-100">
    <base-list
      :headers="headers"
      :items="items"
      :fetching="fetching"
      :search="search"
    >
    </base-list>
  </div>

</template>

<script>
import _ from 'lodash';
import BaseList from '@/components/admin/common/BaseList';

export default {
  components: {
    BaseList,
  },
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers() {
      const $this = this;
      const items = [
        $this.nameColumn(),
        $this.emailColumn(),
        $this.noOfInvitationsColumn(),
        $this.expiredInvitationsColumn(),
        $this.averageRespondTimeColumn(),
      ];
      return items;
    },
    items() {
      return _.map(this.reports, (report) => ({
        id: report.id,
        name: `${_.get(report, 'firstName', '')}`,
        email: report.email,
        invitationsCount: report.noOfInvitations,
        averageRespondTime: report.averageRespondTime,
        expiredInvitations: report.expiredInvitations,
      }));
    },
  },

  methods: {
    nameColumn() {
      return {
        field: 'name',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        width: 100,
        cellClass: 'notranslate',
      };
    },
    emailColumn() {
      return {
        field: 'email',
        sortable: true,
        filter: true,
        width: 120,
      };
    },
    expiredInvitationsColumn() {
      return {
        field: 'expiredInvitations',
        sortable: true,
        filter: true,
        headerClass: 'center-align',
        cellStyle: { textAlign: 'center' },
        width: 120,
      };
    },
    averageRespondTimeColumn() {
      return {
        headerName: 'average respond time (HH:MM)',
        field: 'averageRespondTime',
        sortable: true,
        filter: true,
        headerClass: 'center-align',
        cellStyle: { textAlign: 'center' },
        width: 200,
      };
    },
    noOfInvitationsColumn() {
      return {
        field: 'invitationsCount',
        sortable: true,
        filter: true,
        headerClass: 'center-align',
        cellStyle: { textAlign: 'center' },
        width: 120,
      };
    },
  },
};
</script>
