<template>
    <div class="h-100 credits-container">
      <base-list
        :headers="headers"
        @onEditClick="$emit('onEditClick')"
        :items="items"
        :fetching="fetching"
        @setRowsData="setRows"
        :isSetRows="true"
        :search="search"
      >
      </base-list>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import BaseList from '@/components/admin/common/BaseList';
import BtnCellRenderer from '@/components/projects/ProjectTeam/BtnCellRenderer';

export default {
  components: {
    BaseList,
  },
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers() {
      const $this = this;
      const items = [
        $this.nameColumn(),
        $this.creditsColumn(),
        $this.availedColumn(),
        $this.dateColumn(),
        $this.editColumn(),
      ];
      return items;
    },
    items() {
      return _.map(this.reports, (report) => ({
        id: report,
        name: report.name,
        credits: report.credits,
        availedcredits: report.availCredits,
        date: moment(report.updatedAt).format('MMM Do YYYY'),
      }));
    },
  },

  methods: {
    setRows(data) {
      this.$emit('setDisplayedRows', data);
    },
    editClick(val) {
      this.$emit('onEditClick', val);
    },
    nameColumn() {
      return {
        field: 'name',
        headerName: 'Business Name',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        width: 100,
        cellClass: 'notranslate pt-4 pr-0',
      };
    },
    creditsColumn() {
      return {
        field: 'credits',
        headerName: 'Available Credits',
        sortable: true,
        filter: true,
        cellClass: 'pt-4',
        headerClass: 'center-align',
        cellStyle: { textAlign: 'center', paddingRight: '30px' },
        width: 120,
      };
    },
    dateColumn() {
      return {
        field: 'date',
        headerName: 'Last Purchased Date',
        sortable: true,
        filter: true,
        width: 90,
        cellClass: 'notranslate pt-4 pr-0 pl-10',
      };
    },
    availedColumn() {
      return {
        field: 'availedcredits',
        headerName: 'Lifetime Credits',
        sortable: true,
        filter: true,
        cellClass: 'pt-4',
        headerClass: 'center-align',
        cellStyle: { textAlign: 'center', paddingRight: '30px' },
        width: 120,
      };
    },
    editColumn() {
      const $this = this;
      return {
        headerName: 'Edit',
        field: 'id',
        cellClass: 'action d-flex ml-1 pr-0 cursor-pointer',
        cellRendererFramework: BtnCellRenderer,
        cellRendererParams: {
          image: 'edit',
          clicked(params) {
            $this.editClick(params.value);
            return false;
          },
        },
        width: 20,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.credits-container {
  ::v-deep .ag-header-cell-text {
    text-transform: initial !important;
  }
  ::v-deep .ag-body-horizontal-scroll-viewport {
    overflow-x: hidden !important;
  }
}
</style>
