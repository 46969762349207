<template>
    <v-form class="creative-demographic-form">
        <v-container>
            <v-row>
              <v-col cols="12" sm="8" md="6" class="pa-0">
                    <BaseInput
                        inputLabel="Phone Number *"
                        placeholder="Phone Number"
                        v-mask="'###-###-####'"
                        name="number"
                        type="tel"
                        v-model.trim="onboarding.creative.number"
                        :status="errors('number').length ? 'error': 'normal'"
                        :error-messages="errors('number')"
                        @input="$v.onboarding.creative.number.$touch()"
                        @blur="$v.onboarding.creative.number.$touch()"
                    />
                    <BaseInput
                        inputLabel="Instagram"
                        placeholder="www.instagram.com/photography"
                        name="instagramUrl"
                        v-model.trim="onboarding.creative.instagramUrl"
                        :status="errors('instagramUrl').length ? 'error': 'normal'"
                        :error-messages="errors('instagramUrl')"
                        @input="$v.onboarding.creative.instagramUrl.$touch()"
                        @blur="$v.onboarding.creative.instagramUrl.$touch()"
                    />
                    <BaseInput
                        inputLabel="Website *"
                        placeholder="www.yourwebsite.com"
                        name="portfolio"
                        v-model.trim="onboarding.creative.portfolio"
                        :status="errors('portfolio').length ? 'error': 'normal'"
                        :error-messages="errors('portfolio')"
                        @input="$v.onboarding.creative.portfolio.$touch()"
                        @blur="$v.onboarding.creative.portfolio.$touch()"
                    />
                    <BaseInput
                        inputLabel="Zip Code *"
                        placeholder="Zip Code"
                        name="zipcode"
                        v-model.trim="onboarding.creative.zipcode"
                        :status="errors('zipcode').length ? 'error': 'normal'"
                        :error-messages="errors('zipcode')"
                        @input="$v.onboarding.creative.zipcode.$touch()"
                        @blur="$v.onboarding.creative.zipcode.$touch()"
                    />
                </v-col>
                <v-col cols="12" lg="10" class="pa-0 creative-skillset onboarding-checkbox-group">
                  <SkillsetCheckboxGroup
                    label="What is your skillset? *"
                    :options="orderedSkillSets"
                    name="skillsets"
                    item-text="name"
                    item-value="id"
                    v-model="onboarding.creative.skillsets"
                    :otherSkillSelected="isOtherSkillSelected"
                    :talentSkillSelected="isTalentSkillSelected"
                    @other-skill-change="otherSkillNameChange"
                    @talent-skill-update="talentSkillUpdated"
                    :status="errors('skillsets').length ? 'error': 'normal'"
                    :error-messages="errors('skillsets')"
                    noErrorIcon
                    @change="skillsetChanged()"
                    :otherError="errors('otherSkillset')"
                    :talentError="errors('talentSkillset')"
                  />
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import {
  required, requiredIf, minLength, maxLength, numeric,
} from 'vuelidate/lib/validators';
import _ from 'lodash';
import { mask } from 'vue-the-mask';
import { url } from '@/helpers';
import EventBus from '@/helpers/event-bus';
import BaseInput from '@/components/common/BaseInput';
import SkillsetCheckboxGroup from '@/components/common/SkillsetCheckboxGroup';

export default {
  components: {
    BaseInput,
    SkillsetCheckboxGroup,
  },
  directives: {
    mask,
  },
  computed: {
    ...mapState(['onboarding']),
    ...mapGetters('profile', ['skillsets']),
    orderedSkillSets() {
      const skills = _.map(this.skillsets,
        (skill) => _.assign(skill,
          { skillSetName: null }, _.find(this.onboarding.creative.skillsets, { id: skill.id })));
      return _.orderBy(skills, 'priority', 'asc');
    },
    isOtherSkillSelected() {
      return !!(_.find(this.onboarding.creative.skillsets, { name: 'Other' }));
    },

    isTalentSkillSelected() {
      return !!(_.find(this.onboarding.creative.skillsets, { name: 'Talent' }));
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.onboarding.creative[field].$dirty) return errors;
        switch (field) {
          case 'number':
            if (!this.$v.onboarding.creative.number.required) { errors.push('Please provide your Phone Number'); }
            if (!this.$v.onboarding.creative.number.minLength) { errors.push('Please provide valid Phone Number'); }
            break;
          case 'instagramUrl':
            if (!this.$v.onboarding.creative.instagramUrl.url) { errors.push('Please provide valid url'); }
            break;
          case 'portfolio':
            if (!this.$v.onboarding.creative.portfolio.required) { errors.push('Please provide your Website Address'); }
            if (!this.$v.onboarding.creative.portfolio.url) { errors.push('Please provide valid url'); }
            break;
          case 'zipcode':
            if (!this.$v.onboarding.creative.zipcode.minLength) {
              errors.push('Zipcode is required');
            }
            if (!this.$v.onboarding.creative.zipcode.validZipcode) {
              errors.push('Invalid Zipcode');
            }
            // if (!this.$v.onboarding.creative.zipcode.required)
            //  { errors.push('Please provide your Zip Code'); }
            // // if (!this.$v.onboarding.creative.zipcode.numeric)
            //  { errors.push('Zip Code must be numeric'); }
            // if (!this.$v.onboarding.creative.zipcode.minLength
            //  || !this.$v.onboarding.creative.zipcode.maxLength)
            //   { errors.push('Zip code length should be 5 digits'); }
            break;
          case 'skillsets':
            if (!this.$v.onboarding.creative.skillsets.required) { errors.push('Please select atleast one Skill Set'); }
            break;
          case 'otherSkillset':
            if (!this.$v.onboarding.creative.otherSkillset.required) {
              errors.push('Please provide your Skill Set');
            }
            break;
          case 'talentSkillset':
            if (!this.$v.onboarding.creative.talentSkillset.required) {
              errors.push('Please provide your Skill Set');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  data() {
    return ({
    });
  },
  methods: {
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        EventBus.$emit('demographic-details-resolved');
      }
    },
    skillsetChanged() {
      EventBus.$emit('skill-set-changed');
    },
    invalidOtherSkillset() {
      const otherSkill = _.find(this.onboarding.creative.skillsets, { name: 'Other' });
      // const selectedSkills = _.map(this.onboarding.creative.skillsets, 'name');
      return otherSkill && !otherSkill.skillSetName;
    },
    otherSkillNameChange(val) {
      this.onboarding.creative.otherSkillset = val;
      this.onboarding.creative.skillsets = _.map(this.onboarding.creative.skillsets, (skillset) => {
        if (skillset.name === 'Other') {
          // eslint-disable-next-line no-param-reassign
          skillset = Object.assign({}, skillset, { skillSetName: val });
        }
        return skillset;
      });
    },
    talentSkillUpdated(val) {
      this.onboarding.creative.talentSkillset = val;
      this.onboarding.creative.skillsets = _.map(this.onboarding.creative.skillsets, (skillset) => {
        if (skillset.name === 'Talent') {
          // eslint-disable-next-line no-param-reassign
          skillset = Object.assign({}, skillset, { tier: val });
        }
        return skillset;
      });
    },
  },
  validations: {
    onboarding: {
      creative: {
        number: {
          required,
          minLength: minLength(12),
        },
        instagramUrl: {
          url,
        },
        portfolio: {
          required,
          url,
        },
        zipcode: {
          minLength: minLength(5),
          // maxLength: maxLength(10),
          // eslint-disable-next-line func-names
          required,
          validZipcode: (value) => /^[0-9A-Z]+$/.test(value),
          numeric,
          maxLength: maxLength(5),
        },
        skillsets: {
          required,
        },
        otherSkillset: {
          // eslint-disable-next-line func-names
          required: requiredIf(function () {
            return _.find(this.onboarding.creative.skillsets, { name: 'Other' });
          }),
        },
        talentSkillset: {
          // eslint-disable-next-line func-names
          required: requiredIf(function () {
            return _.find(this.onboarding.creative.skillsets, { name: 'Talent' });
          }),
        },
      },
    },
  },
  mounted() {
    EventBus.$on('demographic-details-submit', () => {
      this.submitForm();
    });
    this.skillsetChanged();
    if (this.isOtherSkillSelected) {
      this.onboarding.creative.otherSkillset = _.find(
        this.onboarding.creative.skillsets, { name: 'Other' },
      ).skillSetName;
    }
  },
  beforeDestroy() {
    EventBus.$off('demographic-details-submit');
  },
};
</script>

<style lang="scss" scoped>
  .creative-demographic-form {
    ::v-deep .input-field-label {
      font-family: $fontFamily1;
      font-weight: bold;
    }
    ::v-deep .input-field {
      margin-bottom: 10px;
      padding: 0;
      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px white inset;
      }
    }
    ::v-deep .creative-skillset.onboarding-checkbox-group {
      @media (min-width: 600px) {
        .v-input__control {
          width: 100%;
          margin-bottom: 8px;
        }
        .input-field-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        .input-field {
          min-width: 170px;
        }
        .input-field .v-input__slot {
          justify-content: flex-start;
        }
      }
    }
  }
</style>
