<template>
  <div>
    <div class="content-wrapper" h-100>
      <v-row class="ma-0">
        <v-col class="pa-0 d-flex align-center" lg=5>
        </v-col>
        <v-col class="d-flex align-center justify-end pa-0">
          <BaseInput
            solo
            hide-details
            v-model="search"
            placeholder="Search ..."
            prepend-inner-icon="search"
            class="mt-1 search-box"
            clearable
            @click:clear="search === ''"
          />
        </v-col>
      </v-row>
      <div class="listing-block">
        <Loader v-if="loading" />
        <CreativeReportsList
          :reports="reports"
          :fetching="loading"
          :search="search"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseInput from '@/components/common/BaseInput';
import CreativeReportsList from '@/components/dashboard/screens/Admin/Reports/CreativeReportsList';
import Loader from '@/components/common/Loader';

export default {
  components: {
    BaseInput,
    CreativeReportsList,
    Loader,
  },

  computed: {
    ...mapGetters('user', ['creativesReports']),
    reports() {
      return this.creativesReports;
    },
  },

  methods: {
    ...mapActions('user', ['getCreativeReports']),
  },
  async mounted() {
    this.loading = true;
    await this.getCreativeReports();
    this.loading = false;
  },
  data() {
    return {
      search: '',
      loading: false,
    };
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-label {
  font-family: $fontFamily1;
  font-size: 14px;
  color: $neutral1;
}
.sort-text {
  font-family: $fontFamily1;
  font-size: 14px;
}
::v-deep .v-text-field {
  font-family: $fontFamily1;
  font-size: 14px;
}
.search-box ::v-deep {
  .v-input__slot {
    border: 1px solid  #b0b0b0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  .v-input__control {
    input {
      max-height: 34px !important;
    };
  }
}
.search-box {
  ::v-deep .v-text-field {
    height: 40px;
    font-size: 16px;
  }
}
.v-data-table {
  ::v-deep th {
    font-weight: bold;
    font-size: 16px;
    color: $neutral1 !important;
  }
  ::v-deep td {
    font-size: 14px;
    color: $neutral1;
    font-family: $fontFamily1;
  }
  ::v-deep td:nth-child(1) {
    color: #0373d1;
  }
  ::v-deep tr:nth-child(even) {
    background-color: #f8f8f8;
  }
}
.listing-block {
  height: calc(100vh - 304px);
  overflow: auto;
}
.content-wrapper {
    padding: 0px;
}
@media (max-width: 600px) {
  .listing-block {
    height: calc(100vh - 255px);
  }
}
</style>
