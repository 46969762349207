<template>
  <div>
    <Loader v-if="showLoader" />
    <template v-else-if="!(showLoader && emptyAnalytics)">
      <div class="pr-0 export-header">
        <v-card flat>
          <v-card-text class="d-flex justify-end pa-1 pr-5">
            <div class="d-flex pt-0 mb-0 pb-0 export-button align-center">
              Time Range:
              <v-select
                :items="timeRangeItems"
                solo
                flat
                dense
                v-model="selectedRange"
                :menu-props="{
                  'content-class': 'filter-dropdown analytics',
                  bottom: true,
                  offsetY: true,
                }"
                hide-details
                class="time-range-filter"
                @change="updateTimeRange"
                auto-grow
                style="min-width: min-content"
              >
              </v-select>
              <div v-if="selectedRange === 'Range' && rangeDate !== ''">
                <span class="selection" @click="DatePickerModal = true">
                  ( {{ rangeDate }} )</span
                >
              </div>
              <v-dialog
                ref="dialog"
                v-model="DatePickerModal"
                persistent
                width="290px"
              >
                <v-date-picker
                  v-model="dates"
                  scrollable
                  range
                  no-title
                  color="info"
                >
                  <v-btn text color="primaryGray1" @click="cancelDatePicker">
                    Cancel
                  </v-btn>
                  <v-btn text color="info" @click="saveDate(dates)"> OK </v-btn>
                </v-date-picker>
              </v-dialog>
              <v-btn
                text
                @click="downloadCSV()"
                class="export-button"
                color="info"
                >Export</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div class="analytics-data card-height">
        <v-row class="d-flex align-center ma-0 border-gray">
          <v-col cols="12" class="title-text pt-0"> Users </v-col>
          <v-col cols="12">
            <v-card flat class="pa-2">
              <v-card-text>
                Total Users: {{ analytics.totalUsers }}
              </v-card-text>
              <v-card-text>
                Onboarded Users: {{ analytics.onBoardedUsers }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <template v-if="analytics.totalUsers">
          <v-row class="d-flex align-center ma-0 border-gray">
            <v-col cols="12" class="title-text"> Users by Type </v-col>
            <v-col cols="12">
              <v-card flat class="pa-2">
                <v-card-text> Admin: {{ usersByType('admin') }} </v-card-text>
                <v-card-text>
                  Content Producer: {{ usersByType('producer') }}
                </v-card-text>
                <v-card-text>
                  Customer: {{ usersByType('client') }}
                </v-card-text>
                <v-card-text>
                  Creative: {{ usersByType('creative') }}
                </v-card-text>
                <v-card-text>
                  Production Manager: {{ usersByType('executive') }}
                </v-card-text>
                <v-card-text> Member: {{ usersByType('member') }} </v-card-text>
                <v-card-text> Editor: {{ usersByType('editor') }} </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center ma-0 border-gray">
            <v-col cols="12" class="title-text"> Users by Status </v-col>
            <v-col cols="12">
              <v-card flat class="pa-2">
                <v-card-text
                  v-for="(count, name) in analytics.usersByStatus"
                  :key="name"
                >
                  {{ name }}: {{ count }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            class="d-flex align-center ma-0 border-gray"
            v-if="analytics.creativesBySkill"
          >
            <v-col cols="12" class="title-text"> Creatives by Skillset </v-col>
            <v-col cols="12">
              <v-card flat class="pa-2">
                <v-card-text>
                  Photographer: {{ creativeBySkill('Photographer') }}
                </v-card-text>
                <v-card-text>
                  Videographer: {{ creativeBySkill('Videographer') }}
                </v-card-text>
                <v-card-text>
                  Copywriter: {{ creativeBySkill('Copywriter') }}
                </v-card-text>
                <v-card-text>
                  Graphic Designer: {{ creativeBySkill('Graphic Designer') }}
                </v-card-text>
                <v-card-text>
                  Talent: {{ creativeBySkill('Talent') }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            class="d-flex align-center ma-0 border-gray"
            v-if="hasOtherSkillsetUsers"
          >
            <v-col cols="12" class="title-text">
              Creatives by Other Skillset
            </v-col>
            <v-col cols="12">
              <v-card flat class="pa-2">
                <v-card-text
                  v-for="(count, name) in analytics.creativesByOtherSkillset"
                  :key="name"
                >
                  <span class="text-capitalize">{{ name }}:</span> {{ count }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <v-row
          class="d-flex align-center ma-0 border-gray"
          v-if="analytics.projects"
        >
          <v-col cols="12" class="title-text"> Projects </v-col>
          <v-col cols="12">
            <v-card flat class="pa-2">
              <v-card-text
                v-for="(count, name) in analytics.projects"
                :key="name"
              >
                <span class="text-capitalize">{{ name }}:</span> {{ count }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          class="d-flex align-center ma-0 border-gray"
          v-if="analytics.projectsByVertical"
        >
          <v-col cols="12" class="title-text"> Projects by Vertical </v-col>
          <v-col cols="12">
            <v-card flat class="pa-2">
              <v-card-text
                v-for="(count, name) in analytics.projectsByVertical"
                :key="name"
              >
                <span class="text-capitalize">{{ name }}:</span> {{ count }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <template v-if="analytics.files">
          <v-row
            class="d-flex align-center ma-0 border-gray"
            v-if="
              analytics.files.filesByType && analytics.files.filesByType.length
            "
          >
            <v-col cols="12" class="title-text"> File Type </v-col>
            <v-col cols="12">
              <v-card flat class="pa-2">
                <div
                  v-for="(fileTypes, i) in analytics.files.filesByType"
                  :key="i"
                >
                  <v-card-text v-for="(value, key) in fileTypes" :key="key">
                    <span class="text-capitalize">{{ key }}:</span> {{ value }}
                  </v-card-text>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            class="d-flex align-center ma-0 border-gray"
            v-if="filesUploaded"
          >
            <v-col cols="12" class="title-text"> File Count </v-col>
            <v-col cols="12">
              <v-card flat class="pa-2">
                <v-card-text
                  v-for="(count, name) in analytics.files.filesByFormat"
                  :key="name"
                >
                  {{ name }}: {{ count }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <v-row class="d-flex align-center ma-0 border-gray">
          <v-col cols="12" class="title-text"> Content Kits </v-col>
          <v-col cols="12">
            <v-card flat class="pa-2">
              <v-card-text>
                Total: {{ analytics.totalContentKits }}
              </v-card-text>
              <v-card-text
                v-for="(count, name) in analytics.contentkitsByStage"
                :key="name"
              >
                <span class="text-capitalize">{{ name }}:</span> {{ count }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable no-case-declarations */
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import Loader from '@/components/common/Loader'

export default {
  components: {
    Loader,
  },
  computed: {
    ...mapGetters('admin', ['analytics']),
    usersByType() {
      return (type) => this.analytics.usersByType[type] || 0
    },
    creativeBySkill() {
      return (type) => this.analytics.creativesBySkill[type] || 0
    },
    emptyAnalytics() {
      return _.isEmpty(this.analytics)
    },
    date() {
      const date = new Date().toISOString().substr(0, 10)
      return moment(date).format('MM-DD-YYYY')
    },
    filesUploaded() {
      return (
        this.analytics.files && !_.isEmpty(this.analytics.files.filesByFormat)
      )
    },
    hasOtherSkillsetUsers() {
      return !_.isEmpty(this.analytics.creativesByOtherSkillset)
    },
    csvData() {
      console.info('Attempting to generate csvData...')
      const arr = []
      arr.push('Users')
      arr.push(`Total Users; ${this.analytics.totalUsers}`)
      arr.push(`Onboarded Users; ${this.analytics.onBoardedUsers}`)
      if (this.analytics.totalUsers) {
        arr.push('Users by Type')
        arr.push(`Admin; ${this.usersByType('admin')}`)
        arr.push(`Content Producer; ${this.usersByType('producer')}`)
        arr.push(`Customer; ${this.usersByType('client')}`)
        arr.push(`Creative; ${this.usersByType('creative')}`)
        arr.push(`Production Manager; ${this.usersByType('executive')}`)
        arr.push(`Member; ${this.usersByType('member')}`)
        arr.push(`Editor; ${this.usersByType('editor')}`)
        arr.push('Users by Status')
        _.map(this.analytics.usersByStatus, (val, key) => {
          arr.push(`${key}; ${val}`)
        })
        arr.push('Creatives by Skillset')
        arr.push(`Photographer; ${this.creativeBySkill('Photographer')}`)
        arr.push(`Videographer; ${this.creativeBySkill('Videographer')}`)
        arr.push(`Copywriter; ${this.creativeBySkill('Copywriter')}`)
        arr.push(
          `Graphic Designer; ${this.creativeBySkill('Graphic Designer')}`,
        )
        arr.push(`Talent; ${this.creativeBySkill('Talent')}`)
        arr.push('Creatives by Other Skillset')
        _.map(this.analytics.creativesByOtherSkillset, (val, key) => {
          arr.push(`${key}; ${val}`)
        })
      }
      if (this.analytics.projects) {
        arr.push('Projects')
        _.map(this.analytics.projects, (val, key) => {
          arr.push(`${_.capitalize(key)}; ${val}`)
        })
        arr.push('Projects by Vertical')
        _.map(this.analytics.projectsByVertical, (val, key) => {
          arr.push(`${key}; ${val}`)
        })
      }
      if (this.analytics.files) {
        if (
          this.analytics.files.filesByType &&
          this.analytics.files.filesByType.length
        ) {
          arr.push('File Type')
          _.map(this.analytics.files.filesByType, (fileTypes) => {
            _.map(fileTypes, (val, key) => {
              arr.push(`${key}; ${val}`)
            })
          })
        }
        if (this.filesUploaded) {
          arr.push('File Count')
          _.map(this.analytics.files.filesByFormat, (val, key) => {
            arr.push(`${key}; ${val}`)
          })
        }
      }
      arr.push('Content Kits')
      arr.push(`Total; ${this.analytics.totalContentKits}`)
      _.map(this.analytics.contentkitsByStage, (val, key) => {
        arr.push(`${key}; ${val}`)
      })
      return arr
    },
  },
  data() {
    return {
      showLoader: false,
      timeRangeItems: [
        'Today',
        'Yesterday',
        'This week',
        'This month',
        'Last month',
        'This year',
        'Range',
      ],
      selectedRange: 'This month',
      DatePickerModal: false,
      dates: ['', ''],
      rangeDate: '',
      selectedDate: ['', ''],
    }
  },
  methods: {
    ...mapActions('admin', ['getGeneralAnalytics']),
    getRange(timeRange) {
      const today = moment()
      switch (timeRange) {
        case 'Yesterday':
          const yesterday = today.subtract(1, 'days').format('YYYY-MM-DD')
          return { fromDate: yesterday, toDate: yesterday }
        case 'This week':
          return {
            fromDate: moment(today).startOf('isoWeek').format('YYYY-MM-DD'),
            toDate: today.format('YYYY-MM-DD'),
          }
        case 'This month':
          return {
            fromDate: moment(today).startOf('month').format('YYYY-MM-DD'),
            toDate: moment(today).endOf('month').format('YYYY-MM-DD'),
          }
        case 'This year':
          return {
            fromDate: moment(today).startOf('year').format('YYYY-MM-DD'),
            toDate: moment(today).endOf('year').format('YYYY-MM-DD'),
          }
        case 'Last month':
          const monthFrom = moment(today).subtract(1, 'months').startOf('month')
          return {
            fromDate: monthFrom.format('YYYY-MM-DD'),
            toDate: monthFrom.endOf('month').format('YYYY-MM-DD'),
          }
        default:
          return {
            fromDate: today.format('YYYY-MM-DD'),
            toDate: today.format('YYYY-MM-DD'),
          }
      }
    },
    async saveDate(date) {
      this.DatePickerModal = false
      this.showLoader = true
      this.dates = date
      const sortDate = this.dates.sort((a, b) => {
        const formDate = new Date(a)
        const toDate = new Date(b)
        if (formDate !== toDate) {
          if (formDate > toDate) {
            return 1
          }
          if (formDate < toDate) {
            return -1
          }
        }
        return formDate - toDate
      })
      const fromDate = moment(sortDate[0])
      const toDate = moment(sortDate[1])
      this.selectedDate = sortDate
      this.rangeDate = `${moment(fromDate).format('MMMM DD, YYYY')} - ${moment(
        toDate,
      ).format('MMMM DD, YYYY')}`
      await this.getGeneralAnalytics({
        fromDate: fromDate.format('YYYY-MM-DD'),
        toDate: toDate.format('YYYY-MM-DD'),
      })
      this.showLoader = false
    },
    async updateTimeRange(val) {
      this.showLoader = true
      if (val === 'Range') {
        this.DatePickerModal = true
        this.rangeDate = ''
        this.dates = [
          moment().format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ]
      } else {
        await this.getGeneralAnalytics(this.getRange(val))
      }
      this.showLoader = false
    },
    cancelDatePicker() {
      this.DatePickerModal = false
      this.dates = this.selectedDate
    },
    downloadCSV() {
      const csvContent = [...this.csvData]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')
      const csvFile = new Blob([csvContent], { type: 'text/csv' })
      const csvURL = window.URL.createObjectURL(csvFile)
      const link = document.createElement('a')
      link.setAttribute('href', csvURL)
      const date = this.getRange(this.selectedRange)
      const { fromDate, toDate } = date
      const rangeFromDate = this.dates[0]
      const rangeToDate = this.dates[1]
      const selectedRange = `${moment(rangeFromDate).format(
        'MM-DD-YYYY',
      )} - ${moment(rangeToDate).format('MM-DD-YYYY')}`
      const selectedDate = `${moment(fromDate).format('MM-DD-YYYY')} - ${moment(
        toDate,
      ).format('MM-DD-YYYY')}`
      const range =
        this.selectedRange === 'Range' ? selectedRange : selectedDate
      link.setAttribute(
        'download',
        `general-analytics(${range})_${this.date}.csv`,
      )
      link.click()
      this.showLoader = false
    },
  },
  async mounted() {
    this.showLoader = true
    await this.getGeneralAnalytics(this.getRange(this.selectedRange))
    this.showLoader = false
  },
}
</script>

<style lang="scss" scoped>
.border-gray {
  border: 1px solid $neutral5;
  margin-bottom: 10px !important;
  padding: 5px;
  border-radius: 5px;
}
.v-card {
  border-radius: 0 !important;
}
.analytics-data {
  margin-top: 10px;
  max-height: calc(100vh - 326px);
  overflow-y: auto;
  .col {
    padding: 0;
  }
  .title-text {
    font-weight: 600;
    padding: 6px 10px;
  }
  ::v-deep .v-card__text {
    color: black;
    font-size: 14px;
    line-height: 1.6;
    padding: 0;
  }
}
.time-range-filter {
  max-width: 160px;
  .v-text-field .v-select__slot .v-select__selection--comma {
    min-width: min-content;
  }
  ::v-deep .v-input__slot,
  ::v-deep .v-input__append-inner {
    padding-left: 0;
    .v-input__icon {
      min-width: 16px;
    }
  }
  ::v-deep .v-select__selections input {
    width: 5px;
  }
}
::v-deep .v-date-picker-table {
  height: 240px;
}
.export-button {
  ::v-deep .v-btn__content {
    font-family: $fontFamily1;
    letter-spacing: -0.32px;
  }
}
::v-deep .v-card__actions {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 960px) {
  .card-height {
    height: calc(100vh - 250px) !important;
  }
}
</style>
