<!-- eslint-disable max-len -->
<template>
    <div class="editcredits-container">
      <v-row class="ma-auto model-header">
          <v-col class="d-flex align-center py-0 pl-0" cols="10">
            <span class="text modal-header-title"> Edit Available Credits </span>
          </v-col>
          <v-col class="d-flex justify-end py-0 pr-0">
            <v-icon color="darken-1" @click="$emit('closemodal')">mdi-close</v-icon>
          </v-col>
        </v-row>
        <div class="ma-5 mt-1" style="color: black;">
        <div class="edit-data">
          <v-col class="pb-2 pl-0" style="font-weight: 600;font-size: 20px;">
            {{ business.name }}
          </v-col>
          <v-col class="pt-0 pl-0">
            Available Credits: {{ business.credits }}
          </v-col>
          <v-col class="pt-0 pl-0">
            LifeTime Credits: {{ business.availCredits }}
          </v-col>
          <v-col class="pt-0 pl-0">
            Last Purchased Date: {{ date(business.updatedAt) }}
          </v-col>
        </div>
        <div>
          <v-row class="ml-0">
            <v-col xs="3" sm="3" md="3" lg="5" xl="5" xxl="5" class="pb-0 pl-0 pt-6">
                <label class="text-left font-label">Justify Credits*</label>
                <BaseInput
                  solo
                  dense
                  flat
                  min="0"
                  oninput="validity.valid || (value = value.slice(0,value.length-2))"
                  type="number"
                  class="type-selection mb-n3 notranslate"
                  v-model.trim="credits.updateCredits"
                  :status="errors('updateCredits').length ? 'error' : 'normal'"
                  :error-messages="errors('updateCredits')"
                  @input="$v.credits.updateCredits.$touch()"
                  @blur="$v.credits.updateCredits.$touch()"
                />
              </v-col>
          <!-- <v-col class="pt-6 pl-0" cols="5">
              <span class="data">Justify Credits</span>
              <div class="payout-div pa-0"
              >
              <BaseInput
                  solo
                  dense
                  flat
                  min="0"
                  oninput="validity.valid || (value = value.slice(0,value.length-1))"
                  type="number"
                  step="0.01"
                  :menu-props="{ 'content-class': 'notranslate' }"
                  v-model.trim="credits.updateCredits"
                  :status="errors('updateCredits').length ? 'error' : 'normal'"
                  :error-messages="errors('updateCredits')"
                  @input="$v.credits.updateCredits.$touch()"
                  @blur="$v.credits.updateCredits.$touch()"
                />
              </div>
            </v-col> -->
            <v-col xs="3" sm="3" md="3" lg="5" xl="5" xxl="5" class="pb-0 pl-0 pt-6">
              <label class="text-left font-label">Operation Type*</label>
                <v-select
                  solo
                  flat
                  class="type-selection mb-n3 notranslate"
                  placeholder="Select..."
                  :items="OperateOption"
                  item-text="name"
                  item-value="value"
                  v-model="credits.operationType"
                  :menu-props="{ 'content-class': 'notranslate' }"
                  :status="errors('operationType').length ? 'error' : 'normal'"
                  :error-messages="errors('operationType')"
                  @input="$v.credits.operationType.$touch()"
                  @blur="$v.credits.operationType.$touch()"
                  append-icon="mdi-chevron-down"
                ></v-select>
            </v-col>
        </v-row>
       <v-col cols="12" class="pb-0 pl-0 pt-1">
                <label class="text-left font-label"> Reason*</label>
                <v-select
                  solo
                  flat
                  class="type-selection mb-n3 notranslate"
                  placeholder="Select..."
                  :items="reasons"
                  item-text="reason"
                  item-value="id"
                  v-model="credits.reason"
                  @change="getReason"
                  :menu-props="{ 'content-class': 'notranslate' }"
                  :status="errors('reason').length ? 'error' : 'normal'"
                  :error-messages="errors('reason')"
                  @input="$v.credits.reason.$touch()"
                  @blur="$v.credits.reason.$touch()"
                  append-icon="mdi-chevron-down"
                ></v-select>
              </v-col>
              <v-col cols="12" class="pt-0 pb-5 pl-0">
                <label class="text-left font-label" :key="notesRequired">
                  {{ notesRequired ? 'Notes*' : 'Notes' }}
                </label>
                <BaseTextAreaField
                  filled
                  rows="3"
                  class="bio-text mb-n5"
                  solo
                  dense
                  flat
                  v-model.trim="credits.notes"
                  :status="errors('notes').length ? 'error' : 'normal'"
                  :error-messages="errors('notes')"
                  @input="$v.credits.notes.$touch()"
                  @blur="$v.credits.notes.$touch()"
                />
              </v-col>
        </div>
        <v-col style="border-top: 1px solid #e0e0e0;" class="buttons">
                <div class="d-flex justify-end">
                <button
                class="cancel cursor-pointer mr-5"
                @click="$emit('closemodal')">Cancel</button>
                <v-btn
                class="closeout ml-2 btn-purple"
                type="submit"
                :loading="loading"
                :disabled="$v.$dirty && $v.$invalid"
                @click="submitForm"
                >Update</v-btn>
                </div>
            </v-col>
            </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import {
  required, requiredIf,
} from 'vuelidate/lib/validators';
import moment from 'moment';
import BaseInput from '@/components/common/BaseInput';
// import Dollar from '@/components/common/svg/Dollar';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';

export default {
  components: {
    BaseInput,
    // Dollar,
    BaseTextAreaField,
  },
  props: {
    business: {
      type: Object,
    },
  },
  data() {
    return {
      credits: {
        updateCredits: '',
        reason: '',
        notes: '',
        operationType: '',
      },
      loading: false,
      notesRequired: false,
      OperateOption: [
        { name: 'Credit', value: 1 },
        { name: 'Debit', value: 2 },
      ],
    };
  },
  computed: {
    ...mapGetters('business', ['reasons']),
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.credits[field].$dirty) return errors;
        switch (field) {
          case 'updateCredits':
            if (!this.$v.credits.updateCredits.required) {
              errors.push('Please provide credits');
            }
            break;
          case 'reason':
            if (!this.$v.credits.reason.required) {
              errors.push('Please provide reason');
            }
            break;
          case 'notes':
            if (!this.$v.credits.notes.required) {
              errors.push('Please provide notes');
            }
            break;
          case 'operationType':
            if (!this.$v.credits.operationType.required) {
              errors.push('Please provide operation');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  methods: {
    ...mapActions('business', ['getReasons']),
    ...mapActions('admin', ['updateBusinessCredits']),
    getReason(reason) {
      const reasonText = _.get(_.find(this.reasons, (item) => item.id === reason), 'reason');
      this.notesRequired = false;
      if (reasonText === 'Other') {
        this.notesRequired = true;
      }
    },
    date(val) {
      return moment(val).format('MMM Do YYYY');
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const { reason, notes, updateCredits } = this.credits;
        const payload = {
          businessId: this.business.id,
          credits: Number(updateCredits),
          operationType: this.credits.operationType,
          reasonId: reason,
          description: notes,
        };
        const result = await this.updateBusinessCredits(payload);
        if (result.success) {
          this.$emit('closemodal');
          this.$emit('reload-list');
        }
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getReasons();
  },
  validations: {
    credits: {
      updateCredits: {
        required,
      },
      reason: {
        required,
      },
      operationType: {
        required,
      },
      notes: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          return this.notesRequired;
        }),
      },
    },
  },
};
</script>

<style scoped lang="scss">
.editcredits-container {
  .edit-data {
    border-bottom: 1px solid #D9D9D9;
  }
  .main-content {
    margin-left: 5rem;
  }
  .payout-div {
    ::v-deep.theme--light.v-text-field--outlined:not(.v-input--is-focused)
    :not(.v-input--has-state) >
      .v-input__control > .v-input__slot fieldset {
        color: #E0E0E0 !important;
      }
    ::v-deep {
      .v-text-field__details {
        display: none;
      }
                .v-input__slot {
                  background-color: white;
                  padding-left: 0px !important;
                }
                .v-input__prepend-inner {
                    margin-top: 0px !important;
                }
            }
            .dollar-div {
                margin-top: 0px;
            }
            .dollar {
                background-color: #F5F5F5;
                border-right: 1px solid #E0E0E0;
                width: 35px;
                height: 40px;
                margin-top: 0px;
                padding: 8px;
                border-radius: 3px 0px 0px 3px;
            }
        }
        ::v-deep .input-field {
      font-family: $fontFamily1;
      height: inherit;
    }
    ::v-deep .v-select__slot {
      font-family: $fontFamily1;
    }
    // ::v-deep .normalState .v-input__slot {
    //   border: 1px solid #e6e8ed !important;
    // }
    // ::v-deep .nullState .v-input__slot {
    //   border: 1px solid #e6e8ed !important;
    // }
    ::v-deep .v-input {
      .v-input__control {
        .v-select__selection,
        input {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #4f4f4f !important;
        }
      }
    }
    ::v-deep .v-input__slot {
      min-height: 40px;
    }
    ::v-deep .type-selection {
      margin-top: 6px;
      .v-input__slot {
        border: solid 1px #e6e8ed;
      }
    }
    ::v-deep .type-selection.error--text .v-input__slot {
      border: solid 1px #ff0808;
    }
    .error-text {
      font-size: 12px;
      line-height: 12px;
      color: #ff0808;
    }
}
</style>
