<template>
<!-- eslint-disable -->
  <v-container fluid class="pa-0 bg-white">
    <v-row v-if="loaded" class="ma-auto">
      <v-col
        class="welcome-conatiner"
      >
        <div class="welcome-text mb-4">{{ welcomeText }}</div>
        <div class="welcome-description">
          {{ subText }}
        </div>
        <v-row class="ma-auto" style="margin-top: 69px !important;">
          <v-col
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
          xxl="6"
          class="left-col">
            <div class="tasks">
              <p class="section-title mb-0">Tasks</p>
              <div class="description">
                <v-card>
                  <v-card-text class="d-flex flex-column client-success-board">
                  
                      <div
                      class="event event-group"
                      >
                      <div class="d-flex justify-space-between pt-5"
                      :class="$vuetify.breakpoint.smAndDown && 'flex-wrap'"
                      v-for="(invite, j) in invitations"
                      :key="j"
                      >
                        <div class="text-div">
                          <div class="text">
                             {{ invite.sweep.title}}
                          </div>
                          <div class="d-flex">
                           <img :src="clockIcon" width="18" class="event-icon" />
                           <div class="sub-text  pl-3 pb-1">
                           {{ getTimeData(invite)}}
                          </div>
                          </div>
                           <div class="d-flex">
                            <MapMarker width="18" height="18"/>
                            <span class="pl-1 ml-1" style="word-break: break-all;width: fit-content;"
            v-for="(location) in invite.sweep.sweepLocations.slice(0, 1)"
            :key="location.id">
            <div class="ml-1" style="width: 80%;min-width: fit-content; ">
            <a class="mb-0" style="color: black;font-size: 14px;"
            :href="`https://www.google.com/maps/place/${location.location}`"
            target='_blank'
            >
              {{
                `${!location.location && !location.zipcode ? '-' : location.location || ''}${
                    location.zipcode ? `, ${location.zipcode}` : ''
                  }`
              }}
            </a>
            </div>
            <div class="more-location mt-0 pt-0 ml-1"
              v-if="invite.sweep.sweepLocations.length !== 1">
              +{{invite.sweep.sweepLocations.length - 1}} other location(s)
            </div>
          </span>
                          </div>
                        </div>
                        <div class="task-btn">
                          <v-btn class="actionBut" @click="$router.push({ path: `/sweeps/${invite.sweep.id}` })"
                          >
                           Accept or Decline
                          </v-btn>
                          <div class="text-center pt-3 sweepTime">
                          {{invitationExpiryTime(invite)}}
                          </div>
                        </div>
                      </div>

                      </div>
                  </v-card-text>
                </v-card>
                 <v-card>
                  <v-card-text v-if="showAvailability"  class="d-flex flex-column client-success-board">
                  
                      <div
                      class="event event-group"
                      >
                      <div class="d-flex justify-space-between "
                      :class="$vuetify.breakpoint.smAndDown && 'flex-wrap'"
                      >
                        <div class="text-div">
                          <div class="text">
                            Update your availability
                          </div>
                          <div class="d-flex">
                           <div class="sub-text pb-1">
                           Help the production team by showing them when the best time to match you for a sweep is.
                          </div>
                          </div>
                        </div>
                        <div class="task-btn">
                          <v-btn class="actionBut" @click="$router.push({ path: `/settings` })"
                          >
                           Update
                          </v-btn>
                        </div>
                      </div>

                      </div>
                  </v-card-text>
                </v-card>
                <div
                v-if="invitations.length===0 && !showAvailability"
                class="d-flex flex-column justify-center align-center">
                  <div style="width: 250px; height: 280px;">
                    <img src="@/assets/svg/noTask.svg" />
                  </div>
                  <div class="no-task">No new tasks... check back
                      <br/>later for an update!</div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
          xxl="6"
          class="right-col"
          >
          <CreativeEarnings
            :earnings="SweepEarnings"
          />
          </v-col>
        </v-row>
        <v-row class="ma-auto" style="margin-top: 86px !important;">
          <v-col
          cols="12"
          xs="12"
          sm="12"
          md="7"
          lg="7"
          xl="7"
          xxl="7"
          class="left-col">
            <div class="questions" :key="questions.length">
              <p class="section-title">Frequently Asked Questions</p>
              <v-expansion-panels
              class="description que-description"
                  v-for="(item, i) in questions"
                  :key="i">
                <v-expansion-panel class="que-div">
                  <v-expansion-panel-header class="text">
                    {{item.question}}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    class="sub-text expansion-panel-sub-text"
                  >
                    {{item.answer}}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-col>
          <v-col
          cols="12"
          xs="12"
          sm="12"
          md="5"
          lg="5"
          xl="5"
          xxl="5"
          class="right-col"
          :style="`padding-left: ${$vuetify.breakpoint.smAndDown ? '0px' : '60px'}`"
           >
            <div class="events">
              <p class="section-title">Upcoming Events</p>
              <div class="description">
                <div v-if="groupedEvents.length" >
                  <div
                    class="event event-group"
                    v-for="eventGroup in groupedEvents"
                    :key="eventGroup"
                  >
                    <div class="date">{{eventGroup.displayTitle}}</div>
                    <div
                      class="d-flex align-start event-div"
                      v-for="(event, j) in eventGroup.events"
                      :key="j"
                    >
                      <img :src="eventIcon" class="event-icon" />
                      <div>
                        <div class="mr-3">
                          <div
                          @click="goToSweepDetails(event.id)"
                          class="cursor-pointer name">{{event.title}}</div>
                          <div class="desc">
                          {{getEventTime(event)}}
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column justify-center align-center" v-else>
                  <div style="width: 48px; height: 61px; text-align: center;">
                    <img src="@/assets/svg/no-events.svg" />
                  </div>
                  <div class="no-task">No reminders yet... check back
                    <br/>later for an update!</div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="ma-auto" style="margin-top: 86px !important;">
          <v-col
          cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="7"
          xl="7"
          xxl="7"
          class="left-col">
            <div class="resources">
              <p class="section-title">Resources</p>
              <div class="description">
                <v-row class="d-flex ma-auto"
                :class="{'flex-column' : $vuetify.breakpoint.smAndDown}">
                  <v-col
                  class="d-flex align-start resource"
                  :class="`resource${i+1}`"
                  v-for="(resource, i) in resources.resource1"
                  :key="i">
                    <img :src="resource.icon"
                    style="width:28px; height: 32px; margin-right: 18px;"/>
                    <div>
                      <div class="name">{{resource.name}}</div>
                      <div class="desc">{{resource.description}}</div>
                      <a :href="resource.linkName === 'Contact Support'
                      ? `mailto:${resource.link}` : resource.link "
                      target="_blank" class="linkName">{{resource.linkName}}</a>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="d-flex ma-auto"
                :class="{'flex-column' : $vuetify.breakpoint.smAndDown}">
                  <v-col
                  class="d-flex align-start resource"
                  :class="`resource${i+1}`"
                  v-for="(resource, i) in resources.resource2"
                  :key="i">
                    <img :src="resource.icon"
                    style="width:28px; height: 32px; margin-right: 18px;"/>
                    <div>
                      <div class="name">{{resource.name}}</div>
                      <div class="desc">{{resource.description}}</div>
                      <a :href="resource.link "
                      target="_blank" class="linkName">{{resource.linkName}}</a>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else class="d-flex flex-1 align-center justify-center page-loader-div">
      <div class="page-loader-wrapper">
        <img src="@/assets/svg/theme/bs_logo.gif" class="page-loader" />
      </div>
    </v-row>
  </v-container>
</template>
<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { getOptimizedS3ImageURL } from '@/helpers/';
import questionsJson from '@/components/dashboard/ClientDashboardQue.json';
import MapMarker from '@/components/common/svg/MapMarker';
import CreativeEarnings from '@/components/dashboard/screens/common/CreativeEarnings';

export default {
  name: 'CreativeWelcomeDashboard',
  components: {
    MapMarker,
    CreativeEarnings,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    invitations: {
      default: [],
    },
    upcomingSweeps: {
      default: [],
    },
    SweepEarnings: {
      default: [],
    },
    loaded: {
      default: false,
    },
    inviteExpiry: {
      default: 12,
    },
  },
  watch: {
    loaded(newVal) {
      this.loading = newVal;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('user', ['userDetails', 'events', 'creativeEarnings']),
    showAvailability() {
      if (this.userDetails.availabilityUpdatedAt) {
        return moment(this.userDetails.availabilityUpdatedAt, 'YYYY-MM-DD').isAfter(moment(this.checkDate, 'YYYY-MM-DD'));
      }
      return true;
    },
    tasks() {
      return true;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    checkDate() {
      if (moment().format('DD') >= 25) {
        return moment(`25/${moment().format('MM')}/${moment().format('YYY')}`).format('DD/MM/YYYY');
      }
      return moment(`25/${moment().format('MM') - 1}/${moment().format('YYY')}`).format('DD/MM/YYYY');
    },
    questions() {
      return questionsJson.questions.creative;
    },
    eventIcon() {
      // eslint-disable-next-line global-require
      return require('@/assets/svg/camera.svg');
    },
    clockIcon() {
      // eslint-disable-next-line global-require
      return require('@/assets/svg/clock.svg');
    },
    locationIcon() {
      // eslint-disable-next-line global-require
      return require('@/assets/svg/location.svg');
    },
    resources() {
      const resource = {
        resource1: [
          {
            name: 'Technical Support',
            description: 'Have questions? Reach out to your producer directly.',
            // eslint-disable-next-line global-require
            icon: require('@/assets/svg/support.svg'),
            link: 'support@blendedsense.com',
            linkName: 'Contact Support',
          },
          {
            name: 'Videos & Tutorials',
            description: 'Check out our YouTube channel for tips and tricks.',
            // eslint-disable-next-line global-require
            icon: require('@/assets/svg/tutorial.svg'),
            link: 'http://youtube.com/channel/UCqrj6OYDNzdjTZ71XBrNh0Q/featured',
            linkName: 'Visit YouTube Channel',
          },
        ],
        resource2: [
          {
            name: 'Visit Resource Center',
            description: 'Check out our resources for more on all things creative.',
            // eslint-disable-next-line global-require
            icon: require('@/assets/svg/resourceCenter.svg'),
            link: 'https://www.blendedsense.com/blog',
            linkName: 'Visit Blog',
          },
          {
            name: 'Send Us Feedback',
            description: 'Let us know how we’re doing. Your feedback is important to us.',
            // eslint-disable-next-line global-require
            icon: require('@/assets/svg/feedback.svg'),
            link: 'https://www.blendedsense.com/contact',
            linkName: 'Send Feedback',
          },
        ],
      };
      return resource;
    },
    welcomeText() {
      return `Welcome, ${this.name}`;
    },
    subText() {
      return 'Check your dashboard for any sweep invitations or upcoming events.';
    },
    groupedEvents() {
      return this.formatEvents();
    },
    getDisplayTime(time) {
      const data = moment(time).format('hh:mm a');
      return data;
    },
  },
  methods: {
    ...mapActions('profile', ['getProfile']),
    ...mapActions('dashboard', ['getDashboardDetails']),
    goToSweepDetails(val) {
      const sweepId = val;
      return this.$router.push({
        name: 'Business Sweep Details2',
        params: {
          sweepId,
        },
      });
    },
    formatEvents() {
      const groupByDate = _.groupBy(this.upcomingSweeps, (event) => moment(event.date).format('YYYY-MM-DD'));
      const sortedGroupedByDateObjects = Object.entries(groupByDate)
        .map(([date, events]) => ({
          date,
          displayTitle: moment(date).format('MMM DD'),
          events,
        }))
        .sort((a, b) => moment(a.date) - moment(b.date));

      // sort the items in a group

      const sortedData = sortedGroupedByDateObjects.map((group) => ({
        ...group,
        events: group.events.sort((a, b) => moment(a.date) - moment(b.date)),
      }));
      return sortedData;
    },
    getEventTime(invite) {
      const from = `2020-06-24 ${invite.fromTime}`;
      const to = `2020-06-24 ${invite.toTime}`;
      return `${moment(from).format('hh:mm a')} - ${moment(to).format('hh:mm a')}`;
    },
    invitationExpiryTime(invitation) {
      const expiration = moment(invitation.invitationSentAt).add(this.userDetails.inviteExpiry, 'hours');
      let hours = expiration.diff(moment(), 'minutes');
      hours = hours > 0 ? hours : '';
      return `${parseInt(hours / 60, 0)} hr ${parseInt(hours % 60, 0)} m`;
    },
    getTimeData(invite) {
      const from = `2020-06-24 ${invite.sweep.fromTime}`;
      const to = `2020-06-24 ${invite.sweep.toTime}`;
      return `${moment(invite.sweep.date).format('dddd, MMM Do YYYY')} - ${moment(from).format('hh:mm a')} to ${moment(to).format('hh:mm a')}`;
    },
    getOptimizedS3ImageURL,
  },
};
</script>
<style lang="scss" scoped>
.sweepLink {
  color: #8066FF;
}
.tasks {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #555555 !important;
}
.sweepTime {
  color: #FF371B !important;
  font-size: 13px;
  line-height: 18px;
}
.sweepText {
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 5px;
}
.close-btn {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-icon {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: #9d9d9d;
}
.bg-white {
  height: 100%;
  background-color: #fff;
  .page-loader-div {
    height: 100%;
    .page-loader-wrapper {
      padding: 2rem;
      .page-loader {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}
.welcome-conatiner {
  max-width: 1280px;
  margin: auto;
  padding-top: 45px;
  ::v-deep .v-btn__content {
    font-family: $fontFamily1 !important;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
  }
  .description .no-task {
    font-family: $fontFamily1;
    font-style: italic;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #A1A1A1;
    padding-top: 42px;
  }
  .left-col, .right-col {
    padding: 0px;
    .events {
      font-family: $fontFamily1;
      font-style: normal;
      font-weight: bold;
      .event {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
        .date {
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: #AAAAAA;
          padding-bottom: 10px;
        }
        .cursor-pointer {
          cursor: pointer;
        }
        .event-div {
          width: fit-content;
          margin-bottom: 20px;
          color: #343838;
          &:last-child {
            margin-bottom: 0px;
          }
          .event-icon {
            width:25px;
            height: 25px;
            margin-right: 13px;
            margin-top: 3px;
          }
          .name {
            word-break: break-word;
            font-size: 20px;
            line-height: 24px;
          }
          .desc {
            font-weight: normal;
            font-size: 17px;
            line-height: 28px;
          }
          .event-btn {
            .v-btn {
              margin-top: 0.5rem;
              width: fit-content;
              background: #65D6EB;
              border-radius: 30px;
              color: #ffff;
              -webkit-box-shadow: none !important;
              box-shadow: none !important;
            }
          }
        }
      }
      .no-task {
        padding-top: 25px !important;
      }
    }
    .tasks {
      .task-btn {
        .v-btn {
          width: 174px;
          height: 42px;
          background: $secondary1;
          border-radius: 30px;
          color: #ffff;
          box-shadow: none !important;
          ::v-deep .v-btn__content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
          }
        }
      }
      ::v-deep .v-card {
        box-shadow: none !important;
        border-radius: unset !important;
        margin-bottom: 8px !important;
        .v-card__text {
          padding: 0px !important;
        }
      }
      ::v-deep .v-card:last-child {
        margin-bottom: 0px !important;
      }
    }
    .cpCard {
      .sub-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #AAAAAA;
        margin-bottom: 18px;
      }
      .producer-contact-img {
        padding-right: 18px;
        .avatar {
          border: 2px solid #65D6EB;
        }
        .avatar-producer {
          border: 2px solid #FD7D0D;
        }
        .avatar-editor {
          border: 2px solid #3A8290;
        }
        .avatar-talent {
          border: 2px solid #07485E;
        }
        .avatar-photographer {
          border: 2px solid #65D6EB;
        }
        .profile-icon {
          position: absolute;
          margin-top: -20px
        }
      }
      .name {
        word-break: break-word;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
      }
      .desc {
        font-style: italic;
        font-size: 16px;
        line-height: 19px;
        color: #555555;
        padding-top: 2px;
      }
      .contact-details {
        padding-top: 11px;
        .contact:nth-child(2) {
          padding-top: 6px;
        }
        a {
          font-size: 14px;
          line-height: 17px;
          border-bottom: 1px solid #65D6EB;
          color: #1CB5D1;
        }
      }
    }
    .tasks, .events, .questions, .resources, .cpCard {
      .v-expansion-panel {
        background: none;
        border-bottom: 1px solid #D8D8D8;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-bottom: 15px;
        &::before {
          box-shadow: none !important;
        }
      }
      .v-expansion-panel-header {
        padding: 0px !important;
        min-height: auto;
      }
      ::v-deep .v-expansion-panel-content .v-expansion-panel-content__wrap {
        padding: 0 0 15px 0 !important;
        font-size: 16px !important;
        line-height: 20px !important;
      }
      .section-title {
        font-family: $fontFamily1;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #262626;
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 8px;
        margin-bottom: 19px;
      }
      .creative-team {
        margin-top: 27px;
        .producer-img {
          margin-top: 18px;
        }
      }
      .que-description{
        .que-div {
          // margin-bottom: 30px;
         .text {
            margin-bottom: 12px !important;
          }
          .sub-text{
            font-size: 16px !important;
            line-height: 20px !important;
          }
        }
      }
      .text-div {
        margin-right: 30px;
      }
      .text {
        font-family: $fontFamily1;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #343838;
        margin-bottom: 10px;
      }
      .sub-text {
        font-family: $fontFamily1;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #262626;
      }
      .resource:last-child {
        padding-left: 0px;
      }
      .resource {
        font-family: $fontFamily1;
        font-style: normal;
        font-weight: normal;
        color: #555555;
        .name {
          word-break: break-word;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #000000;
        }
        .desc {
          padding-top: 4px;
          font-size: 17px;
          line-height: 24px;
          padding-bottom: 18px;
        }
        .linkName {
          padding-top: 18px;
          font-size: 16px;
          line-height: 19px;
          color: #555555;
          border-bottom: 2px solid #65D6EB;
        }
      }
    }
  }
}
.client-heading {
  margin-top: 50px;
}
.creative-heading {
  margin-top: 125px;
}
.container {
  padding: 0 50px;
}
.welcome-text {
  text-transform: capitalize;
  font-family: $fontFamily1;
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 50px;
  color: #343838;
  max-width: 600px;
}
.welcome-description {
  max-width: 600px;
  font-family: $fontFamily1;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: #555555;
}
.empty-note {
  font-family: $fontFamily1;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}
.theme-image {
  margin: auto;
  width: 100%;
}
.v-card__text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.description {
  .status-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .heading {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .description {
    .v-card__text {
      font-family: $fontFamily1;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0.18px;
      padding: 0.9375rem 2.1875rem;
      min-height: 200px;
      color: $neutral1;
    }
  }
  .client-text-next {
    font-family: $fontFamily1;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #949494;
    margin-bottom: 1rem;
  }
  .description .client-success-board {
    padding: 19px 24px;
    min-height: auto;
    .v-btn {
      margin-top: 1.25rem;
      font-weight: bold;
    }
    .sub-text {
      font-size: 16px;
      margin-top: 10px;
    }
  }
  .hover:hover {
    cursor: pointer;
  }
  // .v-card {
  //   margin-bottom: 1.25rem;
  // }
  .sub-text {
    color: $neutral1;
    font-family: $fontFamily1;
    font-size: 12px;
    margin-top: 5px;
  }
  .icon {
    margin-right: 10px;
    height: 2rem;
    width: 2rem;
    &.mdi-check {
      font-size: 0px !important;
      color: white;
      background-color: $primary2 !important;
      border-radius: 1rem;
      background-image: url('../../assets/svg/checkmark.png');
      background-position: center;
    }
    &.mdi-checkbox-blank-circle {
      color: white;
      border-radius: 1rem;
      border: solid 1px #a5affb;
      background-color: white;
    }
    &.disabled {
      border-color: #828282;
    }
  }
}
@media (min-width: 1264px) and (max-width: 1464px) {
  .welcome-conatiner {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
}
@media (min-width: 960px) and (max-width: 1263px) {
  .right-col {
    padding-left: 20px !important;
  }
}
@media (min-width: 960px) {
  .welcome-conatiner {
    padding-left: 40px;
    padding-right: 40px;
  }
  .resources {
    .row {
      margin-bottom: 45px !important;
    }
    .resource1 {
      padding: 0px;
      padding-right: 33px !important;
    }
    .resource2 {
      padding: 0px;
      padding-left: 33px !important;
    }
  }
}
@media (max-width: 959px) {
  .welcome-conatiner {
    padding-top: 82px;
    padding-left: 26px;
    padding-right: 26px;
  }
  .section-title {
    font-size: 20px !important;
    line-height: 24px !important;
  }
  ::v-deep .v-expansion-panel-header.text {
    line-height: 26px !important;
  }
  .right-col {
    margin-top: 81px;
  }
  .resources {
    .row {
      margin-bottom: 0px;
    }
    .resource {
      margin-bottom: 54px;
    }
    .resource1, .resource2 {
      padding: 0px !important;
    }
  }
  .tasks .task-btn .v-btn{
    margin-top: 19px;
  }
  .welcome-text {
    font-size: 34px;
    line-height: 41px;
  }
}
@media (max-width: 600px) {
  .container {
    padding: 0 1.875rem;
  }
  .welcome-description {
    color: $neutral1 !important;
    font-size: 1.125rem;
    letter-spacing: -0.48px;
  }
  .description {
    .text {
      font-size: 0.875rem;
    }
    .sub-text {
      font-size: 10px;
    }
  }
}
.bs_calendly_wrapper {
  position: relative;
}
.bs_calendly_loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
    height: 100px;
  }
}
.bs_calendly_fallback {
  padding: 1rem 2rem;
  font-weight: 500;
  color: rgb(45, 44, 44);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.expansion-panel-sub-text {
  padding-right: 2rem;
}
</style>
