<template>
    <v-container fluid pa-0 class="h-100">
    <v-row class="wrapper">
        <v-col cols="12" sm="3" class="pa-0">
            <v-row v-if="$vuetify.breakpoint.xsOnly" class="ma-0">
              <v-col class="text heading pa-O">
                {{ sideContent()[`${role}_main_heading`]}}
              </v-col>
            </v-row>
          <v-container class="side-nav-wrapper">
            <v-row class="side-nav">
              <v-col cols="12" class="pa-0">
                <v-progress-linear
                    active
                    height="8"
                    top
                    :value="progress"
                    background-color="#dbdbdb"
                    color="info"
                />
              </v-col>
              <v-row class="side-nav-content ma-0">
                <v-col cols="5" sm="12" class="side-image-wrapper pa-0">
                  <img
                  :src="sideContent()[`${role}_image`]"
                  class="side-image"
                  alt="onboarding-image"
                  >
                </v-col>
                <v-col cols="7" sm="12" class="px-0">
                  <div class="text side-heading">{{ sideContent()[`${role}_heading`] }}</div>
                  <div class="text side-description">
                      {{ sideContent()[`${role}_description`]}}
                  </div>
                </v-col>
              </v-row>

            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" sm="9" class="h-100 d-flex flex-column justify-space-between">
          <v-container fluid class="onboarding-content pl-10 pb-0">
            <v-row>
              <v-col v-if="!$vuetify.breakpoint.xsOnly" class="text heading pa-0">
                {{ sideContent()[`${role}_main_heading`]}}
              </v-col>
            </v-row>
            <v-row class="onboarding-form">
              <slot />
            </v-row>
          </v-container>
          <template>
            <v-row align-content="end" class="onboarding-actions"
            v-if="role === 'creative'">
              <v-container fluid pa-0 class="button-group-wrapper">
                <v-row align="center" class="ma-0">
                  <v-col cols="12" class="button-group py-0">
                    <v-btn v-show="!(onlyProfile && screen === 1)"
                      class="previous-button"
                      @click="$emit('previous-screen')"
                    >
                      Previous
                    </v-btn>
                    <v-btn
                      class="btn-purple ma-3"
                      color="info"
                      @click="$emit('next-screen', { screen })"
                    >
                      {{ finalScreen ? 'Complete' : 'Next Step'}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-row>
            <v-row align-content="end" class="onboarding-actions" v-else >
              <v-container fluid pa-0 class="button-group-wrapper">
                <v-row align="center" class="ma-0">
                  <v-col cols="12" sm="6" class="save-button-wrapper py-0">
                    <v-btn text color="info" class="save-button"
                    @click="$emit('save-and-do-later')">
                      Save and do later
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6" class="button-group py-0">
                    <v-btn v-show="!(onlyProfile && screen === 1)"
                      class="previous-button"
                      @click="$emit('previous-screen')"
                    >
                      Previous
                    </v-btn>
                    <v-btn
                      class="btn-purple"
                      color="info"
                      @click="$emit('next-screen', { screen })"
                    >
                      {{ finalScreen ? 'Complete' : 'Next Step'}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-row>
          </template>
        </v-col>
    </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
/* eslint-disable global-require */

export default {
  name: 'SideNavbar',
  props: {
    screen: {
      type: Number,
      default: 1,
    },
    totalScreens: {
      type: Number,
      default: 2,
    },
    onlyProfile: {
      type: Boolean,
      default: false,
    },
    finalScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return ({
    });
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    role() {
      return this.userDetails.role.name;
    },
    progress() {
      return (this.screen / this.totalScreens) * 100;
    },
    sideContent() {
      return () => {
        switch (this.screen) {
          case 1:
            return ({
              client_image: require('@/assets/svg/theme/onboarding-client.svg'),
              creative_image: require('@/assets/svg/theme/onboarding-creative.svg'),
              client_heading: 'Your Business Profile',
              creative_heading: 'Your Creative Profile',
              client_description: `Tell us about your business! 
                The more we know about you, the more we can tailor your content. `,
              creative_description: 'Let’s talk basics.',
              client_main_heading: `Let’s talk about you, ${this.userDetails.firstName}`,
              creative_main_heading: `Let’s talk about you, ${this.userDetails.firstName}`,
            });
          case 2:
          case 3:
            return ({
              client_image: require('@/assets/svg/theme/onboarding-client-creative.svg'),
              creative_image: require('@/assets/svg/theme/onboarding-client-creative.svg'),
              client_heading: 'How can we help?',
              creative_heading: 'Your Creative Assets',
              client_description: `We want to know more about your business needs, 
                so that we start making content work for you.`,
              creative_description: `Tell us about your experience as a creative professional! 
                The more we know about you, and what you love to create, the better we can match you.  `,
              client_main_heading: 'Now let’s talk content',
              creative_main_heading: 'Now let’s talk details',
            });
          default:
            return ({});
        }
      };
    },
  },
};
</script>
<style lang="scss" scoped>
    .wrapper {
      height: 100%;
      margin: 0;
    }
    .side-nav-wrapper {
      background-color: rgba($neutral4, 0.02);
      height: 100%;
      padding: 0;
    }
    .side-nav {
      margin: 0;
    }
    .side-image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .side-image {
      width: 92px;
    }
    .side-heading {
      font-family: $fontFamily1;
      font-size: 16px;
      font-weight: bold;
    }
    .side-description {
      font-family: $fontFamily1;
      letter-spacing: -0.12px;
    }
    .onboarding-content {
      height: calc(100% - 52px);
      overflow: auto;
      .container {
        padding-bottom: 0 !important;
      }
      ::v-deep .onboarding-radio-group .v-input__slot {
        margin-bottom: 0;
      }
    }
    .onboarding-actions {
      max-height: 52px;
    }
    ::v-deep .onboarding-content .onboarding-checkbox-group {
      @media (max-width: 600px) {
        .v-input__control {
          width: 100%;
        }
        .input-field-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
        .input-field {
          min-width: 140px;
        }
        .input-field .v-input__slot {
          justify-content: flex-start;
        }
      }
    }
    ::v-deep .onboarding-content .onboarding-radio-group {
      .v-input--radio-group--row .v-input--radio-group__input {
        width: fit-content;
        justify-content: flex-start;
      }
      .v-input--selection-controls__input {
        display: none;
      }
      .input-field {
        flex-basis: inherit;
        justify-content: center;
        width: 169px;
        height: 61px;
        border-radius: 8px !important;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25) !important;
        border: solid 1px #f4f5f6 !important;
        background-color: $neutral6 !important;
        margin: 0 14px 14px 0;
      }
      .input-field:last-child {
        margin-right: 0;
      }
      .v-radio {
        width: 100%;
        justify-content: center;
      }
      .v-label {
        font-family: $fontFamily1;
        font-size: 16px;
        text-align: center;
        color: $neutral1;
        padding: 10px 20px;
        width: 100%;
        height: 100%;
        justify-content: center;
      }
      .input-field-label {
        margin-bottom: 16px;
      }
      @media (max-width: 600px) {
        .input-field {
          width: 140px;
          margin: 10px;
        }
        .input-field:last-child {
          margin-right: 10px;
        }
        .v-label {
          font-size: 14px;
          padding: 10px;
        }
      }
    }
    .heading {
      height: 33px;
      font-size: 24px;
      margin-bottom: 10px;
      margin-top: 30px;
    }
    .button-group-wrapper {
      border-top: 1px solid $neutral5;
    }
    ::v-deep .save-button .v-btn__content {
      font-family: $fontFamily1;
      letter-spacing: -0.02px;
    }
    .button-group {
      display: flex;
    }
    .previous-button {
      height: 40px !important;
      border-radius: 6px;
      box-shadow: 0 3px 4px 0 rgba(62, 90, 102, 0.16);
      background-color: #f2f4f6 !important;
      margin: 10px 10px 0 10px;
      color: #72919e;
    }
    .next-button {
      height: 42px !important;
      border-radius: 6px;
      box-shadow: 0 3px 4px 0 rgba(62, 90, 102, 0.16);
      margin: 10px 10px 0 10px;
    }
    ::v-deep .button-group .v-btn__content{
      font-size: 18px !important;
    }
    ::v-deep .button-group .v-btn {
      width: 140px;
    }
    @media (min-width: 600px) {
      .side-image-wrapper {
        margin-top: 120px;
      }
      .side-heading {
        text-align: center;
        margin-top: 10px;
      }
      .side-description {
        margin: 20px 25px;
      }
      .button-group {
        justify-content: flex-end;
      }
    }
    @media (max-width: 600px) {
      .wrapper {
        background-color: $neutral3;
      }
      .side-nav-wrapper {
        background-color: $neutral3;
        padding: 20px;
      }
      .side-nav {
        background-color: $neutral6;
        margin: 0;
        border-radius: 14px;
      }
      .side-nav-content {
        margin: 25px 0;
      }
      .heading {
        margin: 10px 10px 25px;
      }
      .onboarding {
        background-color: rgba($neutral4, 0.02);
      }
      .onboarding-content {
        padding: 0 10px;
      }
      .save-button-wrapper {
        order: 2;
        text-align: center;
      }
      .button-group {
        order: 1;
        justify-content: center;
      }
    }
</style>
