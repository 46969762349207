<template>
    <v-form class="creative-tag-form">
        <v-container>
            <v-row class="ma-0">
              <v-col class="pa-0" cols="12" sm="8" md="6">
                <CustomComboBox
                  label="Your camera equipment*"
                  name="cameraEquipments"
                  multiple
                  :items="cameraEquipments"
                  item-text="name"
                  item-value="id"
                  v-model="onboarding.creative.cameraEquipments"
                  :status="errors('cameraEquipments').length ? 'error': 'normal'"
                  :error-messages="errors('cameraEquipments')"
                />
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="pa-0" cols="12" sm="8" md="6">
                <CustomComboBox
                  label="Your lens equipment*"
                  name="lensEquipments"
                  multiple
                  :items="lensEquipments"
                  item-text="name"
                  item-value="id"
                  v-model="onboarding.creative.lensEquipments"
                  :status="errors('lensEquipments').length ? 'error': 'normal'"
                  :error-messages="errors('lensEquipments')"
                />
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="pa-0" cols="12" sm="8" md="6">
                <CustomComboBox
                  label="Your accessories"
                  name="accessories"
                  multiple
                  :items="accessories"
                  item-text="name"
                  item-value="id"
                  v-model="onboarding.creative.accessories"
                  :status="errors('accessories').length ? 'error': 'normal'"
                  :error-messages="errors('accessories')"
                />
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="12" class="pa-0 creative-interested-topics onboarding-checkbox-group">
                <CustomCheckboxGroup
                  label="What are you most interested in? *"
                  :options="interests"
                  name="interestIds"
                  item-text="name"
                  item-value="id"
                  v-model="onboarding.creative.interestIds"
                  :status="errors('interestIds').length ? 'error': 'normal'"
                  :error-messages="errors('interestIds')"
                  noErrorIcon
                >
                </CustomCheckboxGroup>
              </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import EventBus from '@/helpers/event-bus';
import CustomComboBox from '@/components/common/CustomComboBox';
import CustomCheckboxGroup from '@/components/common/CustomCheckboxGroup';

export default {
  components: {
    CustomComboBox,
    CustomCheckboxGroup,
  },
  props: {
    screen: {
      type: Number,
      default: 2,
    },
    screenIndex: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    ...mapState(['onboarding']),
    ...mapGetters('profile', ['interests', 'cameraEquipments', 'lensEquipments', 'accessories']),
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.onboarding.creative[field].$dirty) return errors;
        switch (field) {
          case 'cameraEquipments':
            if (!this.$v.onboarding.creative.cameraEquipments.required) { errors.push('Please select atleast one camera equipment'); }
            break;
          case 'lensEquipments':
            if (!this.$v.onboarding.creative.lensEquipments.required) { errors.push('Please select atleast one lens equipment'); }
            break;
          case 'interestIds':
            if (!this.$v.onboarding.creative.interestIds.required) { errors.push('Please select atleast one interested topic'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  methods: {
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        EventBus.$emit('tag-details-resolved');
      }
    },
  },
  data() {
    return ({
    });
  },
  mounted() {
    EventBus.$on('tag-details-submit', () => {
      if (this.screen === this.screenIndex) {
        this.submitForm();
      }
    });
  },
  beforeDestroy() {
    EventBus.$off('tag-details-submit');
  },
  validations: {
    onboarding: {
      creative: {
        cameraEquipments: {
          required,
        },
        lensEquipments: {
          required,
        },
        accessories: {
        },
        interestIds: {
          required,
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .input-field {
    margin-bottom: 10px;
    padding: 0;
  }
  ::v-deep .creative-interested-topics.onboarding-checkbox-group {
    @media (min-width: 600px) {
      .v-input__control {
        width: 100%;
      }
      .input-field-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      .input-field {
        min-width: 170px;
      }
      .input-field .v-input__slot {
        justify-content: flex-start;
      }
    }
  }
</style>
