<template>
  <div>
    <label class="text-left input-field-label font-label">
      {{ label }}
      <slot name="tooltip" />
    </label>
    <v-input
      v-bind="$attrs"
      v-on="$listeners"
      class="skillset-checkbox-container"
    >
      <div :class="inputStatus" class="input-field-wrapper">
        <div
          v-for="(option, index) in options"
          :key="index"
          class="input-field d-flex justify-space-between"
          :class="{'input-field-active': (option[itemValue] === $attrs.value)}"
        >
          <v-checkbox
            :label="option[itemText]"
            :value="option"
            :input-value="values"
            multiple
            color="info"
            v-bind="$attrs"
            v-on="$listeners"
            hide-details
            class="checkbox-group"
            off-icon='mdi-checkbox'
            on-icon='mdi-check'
            :value-comparator="compareValue"
          ></v-checkbox>
          <v-icon
            v-if="!noErrorIcon"
            v-show="status === 'error'"
            color="error"
          >mdi-alert-circle-outline</v-icon>
          <v-select
            :disabled="!talentSkillSelected"
            v-if="option[itemText]==='Talent'"
            multiple
            :items="talentPaytiers"
            :value="option.tier"
            class="py-0 pl-3 mt-n1"
            :style="'max-width: 250px'"
            @change="updateTalentPaytiers"
            :status="talentError ? 'error': 'normal'"
            :error-messages="talentError"
            :menu-props="{'content-class' : 'talent-skills-dropdown notranslate'}"
          >
          </v-select>
          <v-text-field
            :disabled="!otherSkillSelected"
            v-if="option[itemText]==='Other'"
            name="test"
            :value="option.skillSetName"
            @input="updateOtherSkillName"
            class="pa-0 pl-2 mt-0"
            :status="otherError ? 'error': 'normal'"
            :error-messages="otherError"
          > </v-text-field>
        </div>
      </div>
    </v-input>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  name: 'CustomCheckboxGroup',
  model: {
    prop: 'values',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'normal',
    },
    otherError: {
      type: Array,
      default: () => [],
    },
    talentError: {
      type: Array,
      default: () => [],
    },
    values: {
      type: Array,
      default() {
        return [];
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    noErrorIcon: {
      type: Boolean,
      default: false,
    },
    otherSkillSelected: {
      type: Boolean,
      default: false,
    },
    talentSkillSelected: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    inputStatus() {
      return `${this.status}State`;
    },
    itemText() {
      if (this.$attrs['item-text']) {
        return this.$attrs['item-text'];
      }
      return 'label';
    },
    itemValue() {
      if (this.$attrs['item-value']) {
        return this.$attrs['item-value'];
      }
      return 'value';
    },
    talentPaytiers() {
      const talent = _.find(this.options, ['name', 'Talent']);
      return _.map(talent.creativePayRate, (rate) => rate.tier);
    },
  },
  methods: {
    compareValue(a, b) {
      return a.id === b.id;
    },
    updateOtherSkillName(val) {
      this.$emit('other-skill-change', val);
    },
    updateTalentPaytiers(val) {
      this.$emit('talent-skill-update', val);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-field-label {
  display: block;
  margin-bottom: 20px;
}
.skillset-checkbox-container {
  ::v-deep {
    .v-input__control, .v-input__slot {
      margin-bottom: 0 !important;
    }
    .v-select__selection {
      text-transform: capitalize;
    }
  }
}
::v-deep .input-field .v-input__control {
  margin-bottom: 0 !important;
> .v-input__slot {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 0;
  margin-bottom: 4px !important;
  input {
    padding-bottom: 2px;
    padding-top: 0;
  }
}
}
::v-deep .v-label {
  font-family: $fontFamily1;
  font-size: 16px;
  color: $neutral1;
}
.checkbox-group {
    ::v-deep {
      .v-icon{
        border-radius: 6px;
        border: 2px solid #929292 !important;
        &.mdi-check{
          color: $neutral6 !important;
          background-color: $secondary1 !important;
          border: 2px solid $primary1 !important;
          font-size: 18px;
        }
      }
    }
}
</style>
