<template>
  <v-container fluid>
    <v-row v-if="loaded">
      <v-col cols="12" class="welcome-conatiner pb-0">
        <div class="pb-12"> <PageTitle /> </div>
      </v-col>
      <v-col cols="12" class="pt-0">
        <div class="welcome-text mb-3">{{ welcomeText }}</div>
        <div class="welcome-description">
          {{ description }}
        </div>
        <v-row>
          <v-col cols="12" sm="7" lg="7">
            <div class="status">
              <div
                class="heading"
                :class="role === 'client' ? 'client-heading' : 'creative-heading'"
              >
                {{ successStatus ? null : statusHeader }}
              </div>
              <div
                class="client-text-next mt-12"
                v-if="role === 'client'"
              >
                What’s next?
              </div>
              <div v-if="role === 'client'" class="description">
                <v-card v-if="projects === 0">
                  <v-card-text class="d-flex flex-column client-success-board">
                    <div>
                      <div class="text">
                        Purchase Assets
                      </div>
                      <div class="sub-text">
                        Get started with your own matched Content Producer, Creative Team, and
                        delivered ready to post digital assets here.
                      </div>
                    </div>
                    <v-btn
                      color="info"
                      style="max-width: fit-content;"
                      :href="appendBusinessParamsToWebFlowLink(
                        getClientUpdateWebFlowLink
                      )"
                    >
                      Order Now
                    </v-btn>
                  </v-card-text>
                </v-card>
                <v-card v-if="projects && !contentProducers.length && !callScheduled">
                  <v-card-text class="d-flex flex-column client-success-board">
                    <div>
                      <div class="text">
                        We’re finding the best content producer for you
                      </div>
                      <div class="sub-text">
                        Based on the answers you provided in your signup process and
                        the subscription plan you selected,
                        we’re finding the perfect content producer for you.
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
                <v-card v-if="(projects && contentProducers.length && !callScheduled)">
                  <v-card-text class="d-flex flex-column client-success-board">
                      <div>
                        <div class="text">
                          Schedule Creative Vision Call
                        </div>
                          <div class="sub-text">
                          Meet your dedicated content producer and strategize your content plan.
                        </div>
                      </div>
                      <v-btn
                        @click="handleScheduleCall"
                        color="info"
                        style="max-width: 180px;"
                      > Schedule CV Call </v-btn>
                  </v-card-text>
                </v-card>
                <v-card v-if="(!enableQuizCard)">
                  <v-card-text class="d-flex flex-column client-success-board">
                    <div>
                      <div class="text">
                        Complete your brand profile
                      </div>
                      <div class="sub-text">
                        Take this "quiz" to fill in the answers for your Brand Profile.
                        This is where we get to know your brand and aesthetic!
                      </div>
                    </div>
                    <v-btn
                      color="info"
                      style="max-width: fit-content;"
                      @click="openModal()"
                    >
                      Start Quiz
                    </v-btn>
                  </v-card-text>
                </v-card>
                <div v-if="(projects > 0 && enableQuizCard && callScheduled)">
                  <!-- <VideoPlayer
                  styles="width: 100%; height: 100%;max-height:353px;"
                  ref="videoPlayer" :options="videoOptions" class="video-player" /> -->
                  <iframe
                    width="100%"
                    height="367"
                    id="youtubeVideoPlayer "
                    src="https://www.youtube.com/embed/xjOdkr8F98Q?autoplay=&showinfo=0&loop=1&playlist=xjOdkr8F98Q&rel=0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer;
                    autoplay;
                    clipboard-write;
                    encrypted-media;
                    gyroscope;
                    picture-in-picture"
                    allowfullscreen></iframe>
                  <div class="pt-2 empty-note">
                    We are looking forward to meeting you. While you wait check
                    <br/>out this welcome video from Co-Founder, Albert Baez.
                  </div>
                </div>
              </div>
              <div v-if="role !== 'client' && successStatus" class="description">
                <v-card>
                  <v-card-text class="d-flex flex-column client-success-board">
                    Sit tight….Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                    dolore eu fugiat nulla pariatur.
                  </v-card-text>
                </v-card>
              </div>
              <div v-else-if="!rejected && role !== 'client'">
                <v-card
                  v-for="(item, index) in actionItems"
                  :key="index"
                  @click.native="onClickHandler(item.custom_event_type, item.success)"
                  :class="{ hover: item.custom_event_type && !item.success }"
                >
                  <v-card-text>
                    <div class="status-item">
                      <v-icon size="32" class="icon">
                        {{ item.success ? 'mdi-check' : 'mdi-checkbox-blank-circle' }}
                      </v-icon>
                      <div>
                        <div class="text">
                          {{ item[`${role}_text`] }}
                        </div>
                        <div class="sub-text">
                          {{ item[`${role}_subText`] }}
                        </div>
                      </div>
                    </div>
                    <v-icon v-if="!item.success">
                      mdi-chevron-right
                    </v-icon>
                  </v-card-text>
                </v-card>
              </div>
              <div v-if="role === 'creative'">
                <template v-if="rejected">
                  <v-card>
                    <v-card-text>
                      <div class="status-item">
                        <v-icon size="32" class="icon disabled">
                          mdi-checkbox-blank-circle
                        </v-icon>
                        <div>
                          <div class="text disabled">
                            Reapply
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </template>
                <template v-else-if="checkOnboardingStatus('profile')">
                  <v-card>
                    <v-card-text>
                      <div class="status-item">
                        <v-icon size="32" class="icon">
                          mdi-check
                        </v-icon>
                        <div>
                          <div class="text">
                            Start Your Application!
                          </div>
                          <div class="sub-text">
                            Follow the instructions in your email to get started
                          </div>
                        </div>
                      </div>
                      <v-btn text color="info" @click="resendVideoEmail()"> Resend </v-btn>
                    </v-card-text>
                  </v-card>
                </template>
              </div>
            </div>
          </v-col>
          <v-col
          cols="12"
          sm="5"
          lg="5"
          class="flex-column d-flex"
          :style="producerCallInfo && producerCallInfo.email && role === 'client'
          ? 'margin-top:4.1rem' : 'margin-top: 0;padding-top: 0'"
          :class="$vuetify.breakpoint.mdAndDown ? 'align-start' : 'align-center'">
            <div class="pt-15"
              v-if="producerCallInfo && producerCallInfo.email && role === 'client'"
              >
                <ProducerContact
                    :displayName="producerCallInfo.displayName"
                    :avatar="producerCallInfo.avatar"
                    :email="producerCallInfo.email"
                    :callLink="producerCallInfo.callLink"
                    :callScheduled="callScheduled"
                    @handleScheduleCall="
                    () => handleScheduleCall({ scheduleCallSource: 'producer' })"
                />
            </div>
            <div class="dashboard-theme-image"
            v-else-if="(!producerCallInfo && !userCompletedActions) || role==='creative'">
              <img class="theme-image" src="@/assets/svg/theme/welcome_new.svg" />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Loader v-else />
    <!-- <template v-if="showScheduleCallModal"> -->
      <v-dialog
        v-model="showScheduleCallModal"
        max-width="1280px"
        persistent
      >
      <v-card
        style="border-radius: unset !important;"
        class="d-flex flex-column pa-4" height="90vh" max-height="1080px">
          <div class="d-flex mb-2">
            <v-card-title  class='pt-2 pb-0 pl-2 pr-0 text-capitalize modal-header-title'>
              SCHEDULE A CALL
            </v-card-title>
            <v-spacer></v-spacer>
            <v-icon
              class="pr-1 cursor-pointer"
              @click="()=>toggleScheduleCallModal(false)">
                mdi-close
            </v-icon>
          </div>
          <div class="bs_calendly_wrapper d-flex flex-1" style="height:100%">
            <vue-calendly
              :url="producerCallInfo && appendInviteeParams(producerCallInfo.callLink)"
              ref="scheduleCallModal"
              style="flex:1;min-width:320px; height:100%;" data-processed="true">
            </vue-calendly>
            <div
              v-if="producerCallInfo && !producerCallInfo.isValidLink && !calendlyLoading"
              class="bs_calendly_fallback
              flex-1 d-flex align-center justify-center"
            >
              Calendly link is invalid. Please contact support.
            </div>
            <div
              class="bs_calendly_loader"
              v-if="(producerCallInfo && producerCallInfo.isValidLink) && calendlyLoading"
            >
              <Loader />
            </div>
          </div>
          <!-- <div class="controls align-center d-flex justify-space-between pa-4">
            <v-card-title></v-card-title>
            <div class="d-flex align-center">
              <v-btn color="info"
                v-if="!calendlyLoading"
                depressed @click="()=>toggleScheduleCallModal(false)">
                  {{this.calendlyButtonText}}
                </v-btn>
            </div>
          </div> -->
        </v-card>
      </v-dialog>
    <!-- </template> -->
    <BrandModal
      :modalInitialStep="modalCurrentState"
      :isModal="showBrandQuizModal"
      @closeModal="closeModal"
    />
  </v-container>
</template>
<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import BrandModal from '@/components/profile/client/brandQuiz/Layout';
import ProducerContact from '@/components/dashboard/screens/common/ProducerContact';
// import VideoPlayer from '@/components/common/VideoPlayer';
import Loader from '@/components/common/Loader';
import PageTitle from '@/components/common/PageTitle';

export default {
  name: 'WelcomeDashboard',
  components: {
    BrandModal,
    ProducerContact,
    Loader,
    // VideoPlayer,
    PageTitle,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('business', ['business', 'brandKits', 'brandColors', 'brandFonts', 'brandKitLinks', 'businessDescription', 'businessName', 'brandStyleSelected', 'brandMusicSelected', 'brandKitInspirations']),
    ...mapGetters('profile', { profile: 'clientProfile' }),
    ...mapGetters('brandProfile', ['brandProfile']),
    enableQuizCard() {
      const {
        brandColors,
        brandKits,
        brandKitLinks,
        purpose,
        website,
        facebook,
        instagram,
        linkedin,
        pinterest,
        youtube,
        brandMusic,
        brandStyle,
        brandFonts = {},
        brandKitInspirations,
      } = this.brandProfile;
      const hasBrandFonts = !!(brandFonts.primary && brandFonts.primary.name)
        || !!(brandFonts.secondary && brandFonts.secondary.name);
      const brandMusicData = Object.keys(brandMusic).length > 0;
      const brandStyleData = Object.keys(brandStyle).length > 0;
      if (
        (website || facebook || instagram || linkedin || pinterest || youtube)
        && brandKits.length > 0
        && brandColors.length > 0
        && hasBrandFonts
        && brandStyleData
        && brandMusicData
        && purpose
        && (brandKitInspirations.length > 0 || brandKitLinks.length > 0)
      ) {
        return true;
      }
      return false;
    },
    projects() {
      return this.business.projects && _.get(this.business, 'projects').length;
    },
    contentProducers() {
      return (this.projectTeam.producersTeam || []).map((item) => ({
        ...item.user,
      })).map((user) => ({
        ...user,
        ...user.producerProfile,
      }));
    },
    userCompletedActions() {
      return (
        this.projects && (this.callScheduled || !this.contentProducers.length)
        && this.enableQuizCard
      );
    },
    producerCallInfo() {
      const info = {};
      const { defaultContact } = this.profile;

      const contactWithCalendly = [...this.contentProducers].find(({ calendly }) => calendly);
      if (contactWithCalendly) {
        info.id = contactWithCalendly.id;
        info.displayName = `${contactWithCalendly.firstName} ${contactWithCalendly.lastName}`;
        info.email = contactWithCalendly.email;
        info.avatar = contactWithCalendly.profilePic;
        info.callLink = contactWithCalendly.calendly;
      }

      // if there is no cp with calendly link, then use the first cp
      if (!info.callLink && this.contentProducers.length) {
        info.id = this.contentProducers[0].id;
        info.displayName = `${this.contentProducers[0].firstName} ${this.contentProducers[0].lastName}`;
        info.email = this.contentProducers[0].email;
        info.avatar = this.contentProducers[0].profilePic;
        info.callLink = this.contentProducers[0].calendly;
      }

      // if there is no cp's at all use default contact
      if (this.contentProducers.length === 0 && defaultContact) {
        info.id = defaultContact.id;
        info.displayName = `${defaultContact.firstName} ${defaultContact.lastName}`;
        info.email = defaultContact.email;
        info.avatar = defaultContact.profilePic;
        info.callLink = defaultContact.calendly;
      }
      if (info.callLink) {
        let link = info.callLink;
        if (link.indexOf('http') === -1) {
          link = `http://${link}`;
        }
        const url = new URL(link);
        info.isValidLink = url.host === 'calendly.com';
      }
      if (Object.keys(info).length === 0) {
        return null;
      }
      return info;
    },

    getClientUpdateWebFlowLink() {
      const businessType = this.business.businessType
        && this.business.businessType.name;

      if (businessType === 'E-Commerce') {
        return process.env.VUE_APP_WEB_FLOW_ECOMMERCE_LINK;
      }
      if (businessType === 'Professional Services') {
        return process.env.VUE_APP_WEB_FLOW_PROFESSIONAL_SERVICES_LINK;
      }
      if (businessType === 'Technology') {
        return process.env.VUE_APP_WEB_FLOW_TECHNOLOGY_LINK;
      }
      return null;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    successStatus() {
      return _.get(this.userDetails, 'onboardingStatus.success');
    },
    callScheduled() {
      return _.get(this.userDetails, 'onboardingStatus.schedule');
    },
    rejected() {
      return _.get(this.userDetails, 'status') === '4';
    },
    welcomeText() {
      if (this.role === 'client' || !this.checkOnboardingStatus('profile')) {
        return `Welcome ${this.name}!`;
      }
      return 'Application Status';
    },
    statusHeader() {
      let status = '';
      if (this.role !== 'client') {
        status = this.checkOnboardingStatus('profile') ? 'Sit tight...' : 'You’re Almost Done!';
      }
      if (this.rejected) return '';
      return status;
    },
    description() {
      if (this.role === 'client') {
        return 'Your business profile is set up! To get the most out of your experience, finish the steps below.';
      }
      if (this.rejected) {
        return (
          'Thanks so much for applying to the Creative Professional Network.'
          + ' At this time we are unable to offer you a spot in the network.'
          + ' Keep working on those skills and reapply again after 3 months.'
        );
      }
      if (this.checkOnboardingStatus('profile')) {
        return 'Our production team is reviewing your application and will reach out with your acceptance decision soon. 🎉';
      }
      return (
        'Thanks for creating an account with Blended Sense,'
        + ' we’re excited about having you in the Creative Professional Network.'
        + ' You’re one step closer to activating your dashboard and'
        + ' getting matched to local gigs.'
      );
    },
    actionItems() {
      const items = [
        {
          client_text: 'Create an account',
          creative_text: 'Create an account',
          success: true,
        },
        {
          client_text: 'Complete your business profile',
          creative_text: 'Complete your creative profile',
          client_subText:
            'This profile will help us to match you with a creative team and content kit',
          creative_subText: 'This profile will help us match you to the right local projects',
          custom_event_type: 'profile',
          success: this.checkOnboardingStatus('profile'),
        },
      ];

      if (this.role === 'client') {
        items.push({
          client_text: 'Schedule a call',
          creative_text: 'Talk to us',
          client_subText: 'Set up a call to discuss your content kit needs',
          creative_subText: 'Schedule a time to learn more about joining our creative network',
          custom_event_type: 'schedule',
          success: this.checkOnboardingStatus('schedule'),
        });
      }
      return items;
    },
  },
  data() {
    return {
      modalCurrentState: { step: 1 },
      showBrandQuizModal: false,
      showScheduleCallModal: false,
      projectTeam: {},
      calendlyLoading: true,
      calendlyButtonText: 'Skip',
      calendlyLoadTimer: null,
      loaded: false,
      scheduleCallSource: null,
      videoOptions: {
        autoplay: false,
        controls: true,
        aspectRatio: '16:9',
        sources: [
          {
            src: 'https://bldsns-prod.s3.amazonaws.com/brand_sources/Welcome+to+Blended+Sense.mp4',
            type: 'video/mp4',
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions('user', ['resendCreativeVideoEmail', 'updateScheduleACallStatus']),
    ...mapActions('profile', ['getProfile']),
    ...mapActions('business', ['getBusiness', 'getBrandSources', 'updateBrandQuizVisit']),
    ...mapActions('project', ['getProjectTeam']),
    async openModal() {
      this.showBrandQuizModal = true;
      await this.updateBrandQuizVisit();
      // this.userDetails.brandQuizVisited = true;
    },
    appendInviteeParams(link) {
      const {
        firstName,
        lastName,
        email,
      } = (this.profile || {});
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append('name', `${firstName} ${lastName}`);
      urlSearchParams.append('email', email);
      return `${link}?${urlSearchParams.toString()}`;
    },
    toggleScheduleCallModal(flag) {
      this.showScheduleCallModal = flag;
    },
    closeModal() {
      this.showBrandQuizModal = false;
    },
    onClickHandler(event, enabled) {
      if (event && !enabled) {
        this.$emit('onboarding-state-change', event);
      }
    },
    checkOnboardingStatus(type) {
      if (!this.successStatus) {
        return this.userDetails.onboardingStatus[type];
      }
      return true;
    },
    resendVideoEmail() {
      this.resendCreativeVideoEmail();
    },
    appendBusinessParamsToWebFlowLink(link) {
      const {
        name: businessName,
      } = this.business;
      const {
        firstName,
        lastName,
        email,
        number,
      } = (this.profile || {});
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append('business_name', businessName);
      urlSearchParams.append('email', email);
      urlSearchParams.append('phone', number);
      urlSearchParams.append('first_name', firstName);
      urlSearchParams.append('last_name', lastName);
      urlSearchParams.append('customer_id', this.userDetails.id);
      urlSearchParams.append('redirect_url', encodeURIComponent(window.location.href));
      return `${link}?${urlSearchParams.toString()}`;
    },
    handleScheduleCall(options = {}) {
      if (options && options.scheduleCallSource) {
        this.scheduleCallSource = options.scheduleCallSource;
      } else {
        this.scheduleCallSource = null;
      }
      if (this.producerCallInfo.callLink) {
        if (this.producerCallInfo.isValidLink) {
          // this.calendlyLoading = true;
          if (!this.calendlyLoadTimer) {
            this.calendlyLoadTimer = setTimeout(() => {
              this.calendlyLoading = false;
            }, 10000);
          }
        } else {
          this.calendlyLoading = false;
          this.calendlyButtonText = 'Close';
        }
        this.toggleScheduleCallModal(true);
      } else {
        const element = document.createElement('a');
        element.setAttribute('target', '_blank');
        element.setAttribute('href', `mailto:${this.producerCallInfo.email}`);
        element.click();
      }
    },
  },
  mounted() {
    function isCalendlyEvent(e) {
      return e.data.event && e.data.event.indexOf('calendly') === 0;
    }
    window.addEventListener('message', async (e) => {
      if (isCalendlyEvent(e)) {
        const { data } = e;
        if (data.event === 'calendly.event_type_viewed') {
          this.calendlyLoading = false;
        }
        if (data.event === 'calendly.event_scheduled') {
          if (this.scheduleCallSource !== 'producer') {
            const requestPayload = {
              eventURI: data.payload.event.uri.split('/').pop(),
              contactType: 'creative_vision_call',
              inviteeId: this.producerCallInfo.userId,
            };
            await this.updateScheduleACallStatus(requestPayload);
          }
          this.calendlyButtonText = 'Done';
        }
      }
    });
  },
  async beforeMount() {
    await this.getProfile();
    if (this.role === 'client') {
      if (this.userDetails.currentBusinessId) {
        await this.getBusiness(this.userDetails.currentBusinessId);
        if (this.business && this.business.projects && this.business.projects.length) {
          this.projectTeam = await this.getProjectTeam(this.business.projects[0].id);
        }
        await this.getBrandSources({
          businessId: this.userDetails.currentBusinessId,
        });
      }
    }
    this.loaded = true;
  },
  beforeDestroy() {
    window.removeEventListener('message', () => {});
    if (this.calendlyLoadTimer) {
      clearTimeout(this.calendlyLoadTimer);
    }
  },
};
</script>
<style lang="scss" scoped>
.welcome-conatiner {
  padding-top: 30px;
}
.client-heading {
  margin-top: 50px;
}
.creative-heading {
  margin-top: 125px;
}
.container {
  padding: 0 50px;
}
.welcome-text {
  font-size: 32px;
  font-family: $fontFamily1;
  letter-spacing: -0.6px;
  font-weight: 500;
  max-width: 600px;
}
.welcome-description {
  color: $neutral1 !important;
  font-size: 22px;
  font-family: $fontFamily1;
  line-height: 26px;
  color: $charcoalBlack;
  max-width: 600px;
}
.dashboard-theme-image {
  width: 350px;
}
.empty-note {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: $charcoalBlack;
  }
.theme-image {
  margin: auto;
  width: 100%;
}
.v-card__text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.status {
  .status-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .heading {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .description {
    .v-card__text {
      font-family: $fontFamily1;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0.18px;
      padding: 0.9375rem 2.1875rem;
      min-height: 200px;
      color: $neutral1;
    }
  }
  .client-text-next {
    font-family: $fontFamily1;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #949494;
    margin-bottom: 1rem;
  }
  .description .client-success-board {
    padding: 19px 24px;
    min-height: auto;
    .v-btn {
      margin-top: 1.25rem;
      font-weight: bold;
    }
    .sub-text {
      font-size: 16px;
      margin-top: 10px;
    }
  }
  .hover:hover {
    cursor: pointer;
  }
  .v-card {
    margin-bottom: 1.25rem;
  }
  .text {
    font-size: 16px;
    color: $neutral1;
    font-weight: 700;
    font-family: $fontFamily1;
    letter-spacing: -0.12px;
    &.disabled {
      color: #828282;
    }
  }
  .sub-text {
    color: $neutral1;
    font-family: $fontFamily1;
    font-size: 12px;
    margin-top: 5px;
  }
  .icon {
    margin-right: 10px;
    height: 2rem;
    width: 2rem;
    &.mdi-check {
      font-size: 0px !important;
      color: white;
      background-color: $primary2 !important;
      border-radius: 1rem;
      background-image: url('../../assets/svg/checkmark.png');
      background-position: center;
    }
    &.mdi-checkbox-blank-circle {
      color: white;
      border-radius: 1rem;
      border: solid 1px #a5affb;
      background-color: white;
    }
    &.disabled {
      border-color: #828282;
    }
  }
  ::v-deep .v-btn__content {
    font-family: $fontFamily1 !important;
  }
}
@media (max-width: 1200px) {
  .dashboard-theme-image {
    width: 250px;
  }
}
@media (max-width: 600px) {
  .container {
    padding: 0 1.875rem;
  }
  .welcome-text {
    font-size: 1.2rem;
  }
  .welcome-description {
    color: $neutral1 !important;
    font-size: 1.125rem;
    letter-spacing: -0.48px;
  }
  .theme-image {
    display: flex;
    width: 175px;
  }
  .status {
    .text {
      font-size: 0.875rem;
    }
    .sub-text {
      font-size: 10px;
    }
  }
}
  .bs_calendly_wrapper{
    position: relative;
  }
  .bs_calendly_loader {
    position: absolute;
    width: 100%;
    height: 100%;
}
  .bs_calendly_fallback{
    padding: 1rem 2rem;
    font-weight: 500;
    color: rgb(45, 44, 44);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
