<template>
    <v-container fluid pa-0 >
      <template  v-if="showInvitations">
        <v-row class="d-flex align-center ma-0 pr-3 invite-padding">
          <v-col class="font-label pa-0">
            NEW INVITES
          </v-col>
          <v-col class="d-flex justify-end pa-0">
            <v-btn
              text color="info" small class="px-0"
              :to="{path: '/sweeps', query: { tab: 'invitations'}}"
            >
            View all
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-0 pr-3 py-3 invite-padding">
          <v-col cols="12" class="pa-0" v-if="invitations.length">
            <UpcomingEvent
              v-for="(invitation, index) in invitations"
              :key="`delivery${index}`"
              :sweep="invitation.sweep"
              isInvitation
              :invitationId="invitation.id"
              @reload-dashboarddata="reloadDashboardData"
              @open-invitation-dialog="openInvitationPopup"
            />
          </v-col>
          <v-col v-else class='pa-0'>
          <v-card>
            <v-card-text>
              <v-row class="ma-0 pr-3 py-3 d-flex align-center col-align">
                <v-col v-if="$vuetify.breakpoint.mdOnly" class="pa-0"></v-col>
                <v-col v-else cols="4" class="pa-0 center-align">
                  <img src="@/assets/svg/theme/calendar.svg" alt="no events"
                  class="pl-3" />
                </v-col>
                <v-col v-if="$vuetify.breakpoint.mdOnly" cols="12" class="pa-0 center-align">
                  <div class="heading pr-0">You have no new invites.</div>
                </v-col>
                <v-col v-else cols="8" lg="7" class="pa-0 center-align">
                  <div class="heading pr-0">You have no new invites.</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        </v-row>
      </template>
      <v-row class="d-flex align-center ma-0 pr-3 pt-2 invite-padding">
          <v-col class="font-label pa-0">
            UPCOMING SWEEPS
          </v-col>
      </v-row>
      <v-row class="ma-0 pr-3 py-4 invite-padding">
        <v-col cols="12" class="pa-0" v-if="upcomingSweeps.length">
          <UpcomingEvent
            v-for="(sweep, index) in sweeps"
            :key="`sweep${index}`"
            :sweep="sweep"
          />
        </v-col>
        <v-col v-else class='pa-0'>
          <v-card>
            <v-card-text>
              <v-row class="ma-0 pr-3 py-4 d-flex align-center col-align">
                <v-col v-if="$vuetify.breakpoint.mdOnly" class="pa-0"></v-col>
                <v-col v-else cols="4" class="pa-0 center-align">
                  <img src="@/assets/svg/theme/calendar.svg" alt="no events"
                  class="pl-3" />
                </v-col>
                <v-col v-if="$vuetify.breakpoint.mdOnly" cols="12" class="pa-0 center-align">
                  <div class="heading pr-0">You have no upcoming sweeps.</div>
                </v-col>
                <v-col v-else cols="8" lg="7" class="pa-0 center-align">
                  <div class="heading pr-0">You have no upcoming sweeps.</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <template
        v-if="showInvitationPopup"
        class="pa-0"
      >
        <Modal
          :modal="showInvitationPopup"
          width="600"
          persistent
        >
          <InvitationDialog
            :invitationStatus="inviteStatus"
            @submit-invitation="updateInvitation"
            @close-invitation-dialog="showInvitationPopup=false"
            :askForReason="askReason"
          >
          </InvitationDialog>
        </Modal>
      </template>
    </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import UpcomingEvent from '@/components/dashboard/screens/common/UpcomingEvent';
import InvitationDialog from '@/components/projects/sweep/InvitationDialog';
import Modal from '@/components/common/Modal';
import EventBus from '@/helpers/event-bus';

export default {
  name: 'UpcomingEvents',
  props: {
    invitations: {
      type: Array,
      default: () => [],
    },
    upcomingSweeps: {
      type: Array,
      default: () => [],
    },
    showInvitations: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InvitationDialog,
    Modal,
    UpcomingEvent,
  },
  computed: {
    sweeps() {
      return this.showInvitations ? _.slice(this.upcomingSweeps, 0, 2) : this.upcomingSweeps;
    },
  },
  data() {
    return {
      inviteStatus: '',
      showInvitationPopup: false,
      selectedInvitationId: '',
      selectedReasonId: '',
      askReason: true,
    };
  },

  methods: {
    ...mapActions('sweep', ['updateInvitationById']),
    openInvitationPopup(invitationId, status, needReason) {
      this.selectedInvitationId = invitationId;
      this.inviteStatus = status;
      this.askReason = needReason;
      this.showInvitationPopup = true;
    },
    reloadDashboardData() {
      this.$emit('update-dashboard-data');
    },
    async updateInvitation(reasonId) {
      const result = await this.updateInvitationById({
        reasonId,
        status: this.inviteStatus,
        sweepId: this.selectedInvitationId,
      });
      if (result.success) {
        this.showInvitationPopup = false;
        this.$emit('update-dashboard-data');
        EventBus.$emit('sweep-invite-counts', this.inviteStatus);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upcoming-event-card {
  overflow-y: hidden;
  margin-bottom: 16px;
  min-width: 22vw;
}
::v-deep.upcoming-event-card > .v-card__text {
  // overflow: auto;
  padding: 20px;
}
.heading{
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.18px;
  color:$neutral1;
}
@media (min-width: 600px) {
  .upcoming-event-card:last-child {
    margin-bottom: 0;
  }
}
 @media (max-width: 1445px) {
  .heading {
    margin-left:24px;
  }
}
@media (max-width: 700px) {
  .invite-padding {
    padding-right: 2px !important;
  }
  .heading {
    margin-left:10px;
  }
}
@media (min-width: 601px) and (max-width: 900px) {
  .col-align {
    display: flex;
    flex-direction: column;
    padding: 0 5px !important;
  }
  .center-align {
    display: flex;
    justify-content: center;
  }
  .invite-padding {
    padding-top: 20px !important;
    padding-left: 2px !important;
    padding-right: 10px !important;
  }
  .heading {
    margin-top: 10px !important;
  }
 }
</style>
