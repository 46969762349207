<template>
  <v-list-item class="notification pa-2" @click="onNotificationClick(notification)">
    <div
      v-if="
        notification.notificationType.name === 'creative_invitation_response' ||
          (notification.notificationType.name === 'creative_sweep_invitation' &&
            $vuetify.breakpoint.mdAndUp)
      "
      class="d-flex ma-auto profile-image-container mr-3"
    >
      <v-img class="profile-image" :src="getIcon(notification)" alt="Profile" />
      <v-btn
        v-if="
          notification.message !== 'You have no creatives left in the queue' &&
            notification.message !== 'Add a new creative to the team'
        "
        icon
        x-small
        class="btn-icon"
        color="white"
      >
        <v-icon size="13" color="#02e648" class="icon">mdi-check-circle</v-icon>
      </v-btn>
      <v-btn v-else icon x-small class="btn-icon" color="white">
        <v-icon size="13" color="#e33a4c" class="icon">mdi-close-circle</v-icon>
      </v-btn>
    </div>
    <div v-else>
      <v-list-item-avatar
        v-if="$vuetify.breakpoint.mdAndUp"
        tile
        :size="this.$vuetify.breakpoint.smAndUp ? 35 : 30"
        class="ma-0 mr-2 pic-container ml-2 ml-sm-4 ml-sm-3"
      >
        <v-img :src="getIcon(notification)"></v-img>
      </v-list-item-avatar>
    </div>
    <v-row>
      <v-col cols="9" class="pl-3 pa-0 title-padding">
        <v-list-item-content class="content">
          <v-list-item-title class="notification-title" v-html="notification.title">
          </v-list-item-title>
          <v-list-item-subtitle
            class="notification-subtitle"
            v-if="$vuetify.breakpoint.smAndUp"
            v-html="notification.message"
          ></v-list-item-subtitle>
          <span
            v-if="
              notification.notificationType.name === 'creative_invitation' &&
                checkInfo(notification) &&
                $vuetify.breakpoint.smAndUp
            "
          >
            <v-btn
              small
              class="ma-1 btn-purple btn-rounded-pill btn-primary-small"
              @click="creativeInvitationHandler(notification, '1')"
              >Accept</v-btn
            >
            <v-btn
              small
              outlined
              color="info"
              class="ma-1 btn-rounded-pill btn-primary-small"
              @click="creativeInvitationHandler(notification, '2')"
              >Decline</v-btn
            >
          </span>
        </v-list-item-content>
      </v-col>
      <v-col cols="3" class="pa-0 pr-3 d-flex justify-end">
        <v-list-item-action
          v-if="displayTime"
          class="time-ago justify-end me-2 pt-0 mt-0 time-padding"
          >{{ notification.createdAt | getDayHour }}
        </v-list-item-action>
        <v-list-item-action v-else class="time-ago justify-end me-2 pt-0 mt-0 time-padding">{{
          notification.createdAt | getDate($vuetify.breakpoint.xsOnly)
        }}</v-list-item-action>
      </v-col>
      <v-col
        class="message-col"
        style="line-height: 1.2; display: flex; flex-direction: column;"
        v-if="$vuetify.breakpoint.xsOnly"
      >
        <span class="message-content" v-html="notification.message"></span>
        <span
          v-if="
            notification.notificationType.name === 'creative_invitation' && checkInfo(notification)
          "
        >
          <v-btn
            small
            class="ma-1 btn-purple btn-rounded-pill btn-primary-small"
            @click="creativeInvitationHandler(notification, '1')"
            >Accept</v-btn
          >
          <v-btn
              small
              outlined
              color="info"
              class="ma-1 btn-rounded-pill btn-primary-small"
              @click="creativeInvitationHandler(notification, '2')"
              >Decline</v-btn
            >
        </span>
      </v-col>
    </v-row>
  </v-list-item>
</template>

<script>
/* eslint-disable global-require */

import moment from 'moment-shortformat';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

const namespace = 'dashboard';

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
    displayTime: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    getDate(datetime, mobile = false) {
      if (mobile) return moment(datetime).short();
      const yesterday = moment().subtract(1, 'days');
      if (moment(datetime).isSame(yesterday, 'd')) {
        moment.locale('yourlang', {
          calendar: {
            lastDay() {
              return '[Yesterday]';
            },
          },
        });
        return moment(datetime).calendar();
      }
      return moment(datetime).short();
    },
    getDayHour(dateTime) {
      moment.locale('yourlang', {
        calendar: {
          lastDay() {
            return '[Yesterday], h:mm A';
          },
        },
      });
      return moment(dateTime).calendar();
    },
  },
  computed: {
    ...mapGetters(namespace, ['notifications']),
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    creative() {
      return this.role === 'creative';
    },
    client() {
      return ['client', 'member', 'agency_owner', 'agency_member'].includes(this.role);
    },
    checkInfo() {
      return (notification) => (notification.info ? _.get(JSON.parse(notification.info), 'accepted') === 0 : false);
    },
  },
  methods: {
    ...mapActions('project', ['updateCreativeInvitationStatus']),

    getIcon(notification) {
      const type = _.get(notification, 'notificationType.name');
      switch (type) {
        case 'content_sweep':
          return require('@/assets/svg/theme/calendar-client.svg');
        case 'new_project':
        case 'content_delivery':
          return require('@/assets/svg/theme/content-delivery.svg');
        case 'project_deliverables':
          return require('@/assets/svg/theme/deliverables-update.svg');
        case 'file_upload':
          return require('@/assets/svg/theme/file-upload.svg');
        case 'creatives_matched':
          return require('@/assets/svg/theme/matched-creative.svg');
        case 'brandkit_upload':
          return require('@/assets/svg/theme/brand-profile.svg');
        case 'creative_invitation':
          return require('@/assets/svg/theme/start-up.svg');
        case 'photo_upload':
          return require('@/assets/svg/theme/attach.svg');
        case 'video_upload':
          return require('@/assets/svg/theme/play-button.svg');
        case 'copy_upload':
        case 'caption_update':
          return require('@/assets/svg/theme/contract.svg');
        case 'deadline':
        case 'reminder':
          return require('@/assets/svg/theme/clock.svg');
        case 'content_stage':
          return require('@/assets/svg/theme/content-delivery.svg');
        case 'creative_invitation_response':
        case 'creative_sweep_invitation':
        case 'message':
          if (notification.sender.profilePic) {
            return notification.sender.profilePic;
          }
          return require('@/assets/svg/users.svg');
        default:
          return require('@/assets/svg/users.svg');
      }
    },
    onNotificationClick(notification) {
      const sweepBusinessId = _.get(notification, 'sweep.businessId');
      const businessId = _.get(notification, 'project.businessId');
      const type = _.get(notification, 'notificationType.name');
      const projectId = _.get(notification, 'projectId');
      const sweepId = _.get(notification, 'sweepId');
      if (this.creative) {
        switch (type) {
          case 'creative_sweep_invitation':
            return this.$router.push(`sweep_details/${sweepId}`);
          default:
            return false;
        }
      }
      if (this.client) {
        switch (type) {
          case 'content_sweep':
            return this.$router.push(`sweep_details/${sweepBusinessId}/${sweepId}`);
          case 'brandkit_upload':
            return this.$router.push('/brand_profile');
          default:
            return false;
        }
      }
      switch (type) {
        case 'creative_sweep_invitation':
          return this.$router.push(`sweep_details/${sweepBusinessId}/${sweepId}`);
        case 'creative_invitation_response':
          return this.$router.push(`project_overview/${projectId}/project_team`);
        case 'new_project':
        case 'content_stage':
          if (!['client', 'member'].includes(this.role)) {
            return this.$router.push(`project_overview/${projectId}`);
          }
          return false;
        // return this.$router.push(`library/${projectId}/content_kits/${contentKit}`);
        case 'photo_upload':
        case 'video_upload':
        case 'copy_upload':
        case 'caption_update':
          return this.$router.push(`project_overview/${projectId}?businessId=${businessId}`);
        case 'content_delivery':
          return this.$router.push(
            `library/${projectId}/content_kits?businessId=${this.userDetails.currentBusinessId}`,
          );
        case 'brandkit_upload':
          return this.$router.push('/brand_profile');
        default:
          return false;
      }
    },
    creativeInvitationHandler(notification, accepted) {
      const info = JSON.parse(notification.info);
      const projectId = _.get(info, 'projectId');
      const creativeId = this.userDetails.id;
      this.updateCreativeInvitationStatus({
        notificationId: notification.id,
        projectId,
        creativeId,
        accepted,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-icon {
  position: absolute;
  height: 10px;
  width: 10px;
  top: 28px;
  left: 28px;
  background-color: white;
}
// .icon {
//   top: -4px !important;
// }
.view-text {
  font-size: 14px;
  font-family: $fontFamily1;
  color: $secondary1;
}
.notification {
  border-radius: 20px;
  background-color: rgba(216, 216, 216, 0.38);
  margin-bottom: 10px !important;
  min-height: 74px;
  &::before {
    border-radius: inherit;
  }
}
.notification:last-child {
  margin-bottom: 0 !important;
}
.v-list-item__title {
  font-family: $fontFamily1;
  font-weight: bold;
  color: $neutral1;
  white-space: normal !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.v-list-item__subtitle {
  font-family: $fontFamily1;
  color: $neutral1 !important;
  font-size: 14px;
}
.time-ago {
  font-family: $fontFamily1;
  font-weight: bold;
  color: $neutral1;
}
.content {
  min-width: 70%;
}
.profile-image-container {
  width: 36px;
  height: 36px;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  margin-left: 14px !important;
}
// .pic-container {
//   margin-left: 18px !important;
// }
.profile-image {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}
@media (max-width: 600px) {
  .content {
    padding-bottom: 0px !important;
    display: flex;
    align-self: flex-start;
    padding-top: 10px !important;
    padding-left: 5px;
  }
  .time-padding {
    margin: 0px !important;
    display: flex;
    align-self: flex-start;
    padding-top: 6px !important;
  }
  .notification {
    .v-list-item {
      padding: 0 12px !important;
    }
  }
  .message-content {
    font-family: $fontFamily1;
    font-size: 13px;
    font-weight: normal;
    text-align: left;
    color: $charcoalBlack;
  }
  .message-col {
    padding: 5px 16px;
  }
}
@media (min-width: 600px) and (max-width: 960px) {
  .title-padding {
    padding: 0 10px !important;
  }
  .content {
    padding-left: 8px !important;
  }
}
</style>
