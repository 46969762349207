<template>
  <v-container pa-0 class="h-100">
    <v-row class="ma-0">
      <v-col cols="12" class="pa-0">
        <v-container pa-0>
          <v-row class="pt-4 pl-3 recent-padding mobile-left" v-if="!recentProjects.length">
            <v-col class="pb-0 recent-name-padding" cols="7">
              <div class="text-uppercase font-label">RECENT PROJECTS</div>
              <div class="static-text mt-4">
                Uh-oh! No projects just yet, come back again.
              </div>
              <v-col class="ml-12 pl-4 pt-0 recent-image-padding" cols="12">
                <img
                  src="@/assets/svg/theme/empty-plane.svg"
                  alt="more details"
                  class="pl-10 empty-plane-img"
                />
              </v-col>
            </v-col>
            <v-col cols="5" class="pt-0 d-flex justify-end">
              <img src="@/assets/svg/theme/empty-girl.svg" alt="more details"
              class=" empty-girl" height="200" />
            </v-col>
          </v-row>

          <v-row class="ma-0 mt-5" v-else>
            <v-col cols="12" class="pa-0 pb-2">
              <v-container pa-0 class="h-100 pl-3 mobile-left-0">
                <v-row>
                  <v-col class="d-flex align-center pb-0 recent-name-padding">
                    <div class="text-uppercase font-label">RECENT PROJECTS</div>
                  </v-col>
                </v-row>
                <v-row class="card-content">
                  <v-col cols="12" md="12" lg="10" class="pb-0 recent-projects-padding">
                    <v-row class="ma-0 pt-1 d-flex align-stretch">
                      <v-col
                        sm="4"
                        md="4"
                        class="pt-0 pb-1 pl-0"
                        v-for="item in recentProjects"
                        :key="item.id"
                      >
                        <base-card
                          @click="projectOverview(item.id, item.businessId)"
                          class="card-container"
                          cardType="recentProjects"
                          :item="item"
                        ></base-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseCard from '../../../common/BaseCard';

export default {
  name: 'RecentProjects',
  components: {
    BaseCard,
  },
  props: {
    recentProjects: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  data() {
    return {};
  },
  methods: {
    projectOverview(projectId, businessId) {
      this.$router.push(`/project_overview/${projectId}?businessId=${businessId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-plane-img, .empty-girl{
  width:100%;
}
.empty-girl{
  padding-left:60px;
}

.card-container {
  background: white;
  height: 100%;
}
.static-text {
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.18px;
}
@media screen and (max-width: 960px) {
  .empty-plane-img {
    width: 320px;
  }
  .mobile-left {
    padding-left: 8px !important;
  }
  .recent-name-padding {
    padding: 0px !important;
  }
}
@media screen and (min-width: 961px)  and (max-width: 1300px){
  .empty-plane-img {
    width: 240px;
  }
}
@media (max-width: 600px) {
  .recent-projects-padding {
    padding-left: 2px !important;
    padding-right: 14px !important
  }
  .mobile-padding {
    padding-left: 2px !important;
  }
  .recent-image-padding {
    padding: 2px !important;
  }
  .recent-padding {
    padding-top: 20px !important;
  }
  .empty-plane-img {
    width: 190px;
  }
  .empty-girl {
    padding-left: 35px;
    padding-right: 0px;
    height: 100px;
  }
  .mobile-left {
    padding-left: 2px !important;
  }
  .mobile-left-0 {
    padding-left: 0px !important;
  }
}
</style>
