<template>
  <v-form class="client-tag-form">
    <v-container>
      <v-row class="ma-0">
        <v-col cols="12" lg="10" class="pa-0 onboarding-checkbox-group">
          <CustomCheckboxGroup
            label="You are looking to… *"
            :options="contentTypes"
            name="contentTypeIds"
            item-text="name"
            item-value="id"
            v-model="onboarding.client.contentTypeIds"
            :status="errors('contentTypeIds').length ? 'error': 'normal'"
            :error-messages="errors('contentTypeIds')"
            noErrorIcon
          >
            <!-- <template v-slot:tooltip>
              <v-tooltip right max-width="360" content-class="custom-tooltip" nudge-bottom="50">
                <template v-slot:activator="{ on }">
                  <img v-on="on" src="@/assets/svg/theme/info.svg" width="12" alt="note" />
                </template>
                <span>
                  <p>Photo: Photography for event recap, products,
                  lifestyle, headshots, promos and more.</p>
                  <p>Video: Videography for promo videos,
                  product stories, teasers, testimonials,
                  event recaps, editorial, live recordings and more.</p>
                  <p>Copy: Copywriting for blog posts, social posts, captions and more.</p>
                  <p>Audio: Custom audio for logo stings, podcasts, branding and more.</p>
                </span>
              </v-tooltip>
            </template> -->
          </CustomCheckboxGroup>
        </v-col>
        <v-col cols="12" lg="12" class="pa-0 onboarding-radio-group">
          <CustomRadioGroup
            label="What do you need content for? *"
            :options="sortedTimeRages"
            name="timeRangeId"
            item-text="range"
            item-value="id"
            v-model="onboarding.client.timeRangeId"
            :status="errors('timeRangeId').length ? 'error': 'normal'"
            :error-messages="errors('timeRangeId')"
            noErrorIcon
          />
        </v-col>
        <v-col cols="12" lg="12" class="pa-0 onboarding-radio-group">
          <CustomRadioGroup
            label="Who handles your content marketing right now? *"
            :options="sortedPriceRanges"
            name="priceRangeId"
            item-text="range"
            item-value="id"
            v-model="onboarding.client.priceRangeId"
            :status="errors('priceRangeId').length ? 'error': 'normal'"
            :error-messages="errors('priceRangeId')"
            noErrorIcon
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import _ from 'lodash';
import EventBus from '@/helpers/event-bus';
import CustomCheckboxGroup from '@/components/common/CustomCheckboxGroup';
import CustomRadioGroup from '@/components/common/CustomRadioGroup';

export default {
  components: {
    CustomCheckboxGroup,
    CustomRadioGroup,
  },
  mounted() {
    EventBus.$on('tag-details-submit', () => {
      this.submitForm();
    });
  },
  beforeDestroy() {
    EventBus.$off('tag-details-submit');
  },
  computed: {
    ...mapState(['onboarding']),
    ...mapGetters('profile', ['contentTypes', 'timeRanges', 'priceRanges']),
    sortedTimeRages() {
      return _.orderBy(this.timeRanges, 'id', 'asc');
    },
    sortedPriceRanges() {
      return _.orderBy(this.priceRanges, 'id', 'asc');
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.onboarding.client[field].$dirty) return errors;
        switch (field) {
          case 'contentTypeIds':
            if (!this.$v.onboarding.client.contentTypeIds.required) {
              errors.push('Please select atleast one what you are looking to...');
            }
            break;
          case 'timeRangeId':
            if (!this.$v.onboarding.client.timeRangeId.required) { errors.push('Please select your Content Type'); }
            break;
          case 'priceRangeId':
            if (!this.$v.onboarding.client.priceRangeId.required) { errors.push('Please select your Content Marketing'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  methods: {
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        EventBus.$emit('tag-details-resolved');
      }
    },
  },
  data() {
    return {

    };
  },
  validations: {
    onboarding: {
      client: {
        contentTypeIds: {
          required,
        },
        timeRangeId: {
          required,
        },
        priceRangeId: {
          required,
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.client-tag-form {
  ::v-deep .input-field-label {
    font-family: $fontFamily1;
    font-weight: bold;
  }
  ::v-deep .input-field {
    margin: 0;
    padding: 0;
    margin-bottom: 10px !important;
  }
  ::v-deep .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 0
  }
}
</style>
