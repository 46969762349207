<template>
  <div class="h-100">
    <div class="h-100">
      <template v-if="customer || agency">
        <!-- <MainDashboard :role="role" v-if="contentKits && userDetails.status == 1"/> -->
        <ClientWelcomeDashboard
          :name="getFirstName()"
          @onboarding-state-change="onboardingStateChange"
        />
      </template>
      <template v-else-if="creative">
        <!-- <MainDashboard :role="role" v-if="contentKits && userDetails.status == 1"/> -->
        <CreativeWelcomeDashboard
          :name="getFirstName()"
          :invitations="invitations"
          :upcomingSweeps="upcomingSweeps"
          :SweepEarnings="earnings"
          :loaded="loaded"
          :key="loaded"
          @onboarding-state-change="onboardingStateChange"
          :inviteExpiry="inviteExpiry"
        />
      </template>
      <template v-else>
        <WelcomeDashboard
          v-if="(welcome || firstTimeUser) && !onboardingCompleted"
          :name="getFirstName()"
          @onboarding-state-change="onboardingStateChange"
        />
        <MainDashboard :role="role" v-else/>
      </template>
    </div>
    <v-container
      fluid
      v-if="modal"
    >
      <Modal
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
      content-class="onboarding-modal"
      :modal="modal"
      >
        <Onboarding
          :initialScreen="initialScreen"
          :onlyProfile="onlyProfile"
          :onlySchedule="onlySchedule"
          @on-submit="onSubmit"
        />
      </Modal>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import WelcomeDashboard from '@/components/dashboard/Welcome';
import CreativeWelcomeDashboard from '@/components/dashboard/CreativeWelcomeDashboard';
import ClientWelcomeDashboard from '@/components/dashboard/ClientWelcomeBoard';
import MainDashboard from '@/components/dashboard/Main';
import Onboarding from '@/components/dashboard/Onboarding';
import Modal from '@/components/common/Modal';

const namespace = 'user';
export default {
  name: 'Dashboard',
  components: {
    ClientWelcomeDashboard,
    CreativeWelcomeDashboard,
    WelcomeDashboard,
    MainDashboard,
    Onboarding,
    Modal,
  },
  computed: {
    ...mapGetters(['modal']),
    ...mapGetters(namespace, ['userDetails']),
    ...mapGetters('onboarding', ['clientOnboardingDetails', 'creativeOnboardingDetails']),
    ...mapGetters('dashboard', ['project']),
    customer() {
      return _.includes(['client', 'member'], this.role);
    },
    creative() {
      return _.includes(['creative'], this.role);
    },
    agency() {
      return _.includes(['agency_owner', 'agency_member'], this.role);
    },
    contentKits() {
      return _.get(this.project.contentKits, '[0].id');
    },
    firstTimeUser() {
      if (this.role !== 'executive') {
        return _.get(this.userDetails, 'onboardingStatus.firstLogin');
      }
      return false;
    },
    onboardingCompleted() {
      if (this.role === 'client' || this.role === 'creative') {
        return _.get(this.userDetails, 'onboardingStatus.success');
      }
      return true;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
  },
  data() {
    return {
      welcome: false,
      initialScreen: 0,
      onlyProfile: false,
      onlySchedule: false,
      invitations: [],
      earnings: [],
      upcomingSweeps: [],
      loaded: false,
    };
  },
  methods: {
    ...mapActions(['setModal']),
    ...mapActions('user', ['creativeEarnings']),
    ...mapActions(namespace, ['updateUser']),
    ...mapActions('onboarding', ['clientOnboarding', 'creativeOnboarding']),
    ...mapActions('profile', ['getSavedProfile']),
    ...mapActions('dashboard', ['getDashboardDetails']),
    onboardingStateChange(state) {
      switch (state) {
        case 'profile':
          this.initialScreen = 1;
          this.onlyProfile = true;
          break;
        case 'schedule':
          this.initialScreen = 0;
          this.onlySchedule = true;
          break;
        default:
          break;
      }
      this.showModal();
    },
    screen() {
      if (this.role === 'creative') {
        this.initialScreen = 1;
        this.onlyProfile = true;
      } else {
        this.initialScreen = 0;
        this.onlyProfile = false;
      }
    },
    showModal() {
      this.setModal(true);
    },
    hideModal() {
      this.setModal(false);
    },
    getFirstName() {
      return `${this.userDetails.firstName}`;
    },
    async onSubmitHandler(validate) {
      let response = '';
      switch (this.role) {
        case 'client':
          response = await this.clientOnboarding(
            Object.assign({ validate }, this.clientOnboardingDetails),
          );
          break;

        case 'creative':
          response = await this.creativeOnboarding(
            Object.assign({ validate }, this.creativeOnboardingDetails),
          );
          break;

        default:
          response = {
            success: true,
          };
          break;
      }
      return response;
    },
    async onSubmit(type) {
      let response = '';
      switch (type) {
        case 'profile':
          response = await this.onSubmitHandler(true);
          break;

        case 'save-and-do-later':
          response = await this.onSubmitHandler(false);
          this.welcome = true;
          break;

        default:
          this.welcome = true;
          this.hideModal();
          break;
      }
      if (response && response.success) {
        this.hideModal();
      }
    },
  },
  async mounted() {
    this.screen();
    if (this.firstTimeUser) {
      this.welcome = true;
      if (!['client', 'member', 'agency_owner', 'agency_member'].includes(this.role)) {
        this.showModal();
      }
    } else if (!this.onboardingCompleted) {
      this.getSavedProfile();
      this.welcome = true;
    } else {
      this.welcome = false;
    }
    if (this.role === 'creative') {
      const data = await this.getDashboardDetails();
      this.invitations = data.sweepInvitations;
      this.upcomingSweeps = data.sweepList;
      this.inviteExpiry = data.inviteExpiry;
      const creativeEarnings = await this.creativeEarnings();
      this.earnings = creativeEarnings.sweepEarnings;
      this.loaded = true;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
