import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{staticClass:"notification pa-2",on:{"click":function($event){return _vm.onNotificationClick(_vm.notification)}}},[(
      _vm.notification.notificationType.name === 'creative_invitation_response' ||
        (_vm.notification.notificationType.name === 'creative_sweep_invitation' &&
          _vm.$vuetify.breakpoint.mdAndUp)
    )?_c('div',{staticClass:"d-flex ma-auto profile-image-container mr-3"},[_c(VImg,{staticClass:"profile-image",attrs:{"src":_vm.getIcon(_vm.notification),"alt":"Profile"}}),(
        _vm.notification.message !== 'You have no creatives left in the queue' &&
          _vm.notification.message !== 'Add a new creative to the team'
      )?_c(VBtn,{staticClass:"btn-icon",attrs:{"icon":"","x-small":"","color":"white"}},[_c(VIcon,{staticClass:"icon",attrs:{"size":"13","color":"#02e648"}},[_vm._v("mdi-check-circle")])],1):_c(VBtn,{staticClass:"btn-icon",attrs:{"icon":"","x-small":"","color":"white"}},[_c(VIcon,{staticClass:"icon",attrs:{"size":"13","color":"#e33a4c"}},[_vm._v("mdi-close-circle")])],1)],1):_c('div',[(_vm.$vuetify.breakpoint.mdAndUp)?_c(VListItemAvatar,{staticClass:"ma-0 mr-2 pic-container ml-2 ml-sm-4 ml-sm-3",attrs:{"tile":"","size":this.$vuetify.breakpoint.smAndUp ? 35 : 30}},[_c(VImg,{attrs:{"src":_vm.getIcon(_vm.notification)}})],1):_vm._e()],1),_c(VRow,[_c(VCol,{staticClass:"pl-3 pa-0 title-padding",attrs:{"cols":"9"}},[_c(VListItemContent,{staticClass:"content"},[_c(VListItemTitle,{staticClass:"notification-title",domProps:{"innerHTML":_vm._s(_vm.notification.title)}}),(_vm.$vuetify.breakpoint.smAndUp)?_c(VListItemSubtitle,{staticClass:"notification-subtitle",domProps:{"innerHTML":_vm._s(_vm.notification.message)}}):_vm._e(),(
            _vm.notification.notificationType.name === 'creative_invitation' &&
              _vm.checkInfo(_vm.notification) &&
              _vm.$vuetify.breakpoint.smAndUp
          )?_c('span',[_c(VBtn,{staticClass:"ma-1 btn-purple btn-rounded-pill btn-primary-small",attrs:{"small":""},on:{"click":function($event){return _vm.creativeInvitationHandler(_vm.notification, '1')}}},[_vm._v("Accept")]),_c(VBtn,{staticClass:"ma-1 btn-rounded-pill btn-primary-small",attrs:{"small":"","outlined":"","color":"info"},on:{"click":function($event){return _vm.creativeInvitationHandler(_vm.notification, '2')}}},[_vm._v("Decline")])],1):_vm._e()],1)],1),_c(VCol,{staticClass:"pa-0 pr-3 d-flex justify-end",attrs:{"cols":"3"}},[(_vm.displayTime)?_c(VListItemAction,{staticClass:"time-ago justify-end me-2 pt-0 mt-0 time-padding"},[_vm._v(_vm._s(_vm._f("getDayHour")(_vm.notification.createdAt))+" ")]):_c(VListItemAction,{staticClass:"time-ago justify-end me-2 pt-0 mt-0 time-padding"},[_vm._v(_vm._s(_vm._f("getDate")(_vm.notification.createdAt,_vm.$vuetify.breakpoint.xsOnly)))])],1),(_vm.$vuetify.breakpoint.xsOnly)?_c(VCol,{staticClass:"message-col",staticStyle:{"line-height":"1.2","display":"flex","flex-direction":"column"}},[_c('span',{staticClass:"message-content",domProps:{"innerHTML":_vm._s(_vm.notification.message)}}),(
          _vm.notification.notificationType.name === 'creative_invitation' && _vm.checkInfo(_vm.notification)
        )?_c('span',[_c(VBtn,{staticClass:"ma-1 btn-purple btn-rounded-pill btn-primary-small",attrs:{"small":""},on:{"click":function($event){return _vm.creativeInvitationHandler(_vm.notification, '1')}}},[_vm._v("Accept")]),_c(VBtn,{staticClass:"ma-1 btn-rounded-pill btn-primary-small",attrs:{"small":"","outlined":"","color":"info"},on:{"click":function($event){return _vm.creativeInvitationHandler(_vm.notification, '2')}}},[_vm._v("Decline")])],1):_vm._e()]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }