<template>
    <v-form class="creative-tag-form">
        <v-container>
            <v-row class="ma-0">
              <v-col cols="12" class="pa-0 creative-copywriter onboarding-checkbox-group">
                <CustomCheckboxGroup
                  label="How would you describe your style? *"
                  :options="orderedStyles"
                  name="styleIds"
                  item-text="name"
                  item-value="id"
                  v-model="onboarding.creative.styleIds"
                  :status="errors('styleIds').length ? 'error': 'normal'"
                  :error-messages="errors('styleIds')"
                  noErrorIcon
                >
                </CustomCheckboxGroup>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="12" class="pa-0 creative-copywriter onboarding-checkbox-group">
                <CustomCheckboxGroup
                  label="What type of content are you interested in? *"
                  :options="orderedWriterInterests"
                  name="writerInterestIds"
                  item-text="name"
                  item-value="id"
                  v-model="onboarding.creative.writerInterestIds"
                  :status="errors('writerInterestIds').length ? 'error': 'normal'"
                  :error-messages="errors('writerInterestIds')"
                  noErrorIcon
                >
                </CustomCheckboxGroup>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="12" class="pa-0 creative-copywriter onboarding-checkbox-group">
                <CustomCheckboxGroup
                  label="What subjects do you love to write about? *"
                  :options="orderedSubjects"
                  name="subjectIds"
                  item-text="name"
                  item-value="id"
                  v-model="onboarding.creative.subjectIds"
                  :status="errors('subjectIds').length ? 'error': 'normal'"
                  :error-messages="errors('subjectIds')"
                  noErrorIcon
                >
                </CustomCheckboxGroup>
              </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import _ from 'lodash';
import EventBus from '@/helpers/event-bus';
import CustomCheckboxGroup from '@/components/common/CustomCheckboxGroup';

export default {
  components: {
    CustomCheckboxGroup,
  },
  props: {
    screen: {
      type: Number,
      default: 2,
    },
    screenIndex: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    ...mapState(['onboarding']),
    ...mapGetters('profile', ['styles', 'writerInterests', 'subjects']),
    orderedStyles() {
      return _.orderBy(this.styles, 'id', 'asc');
    },
    orderedWriterInterests() {
      return _.orderBy(this.writerInterests, 'id', 'asc');
    },
    orderedSubjects() {
      return _.orderBy(this.subjects, 'id', 'asc');
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.onboarding.creative[field].$dirty) return errors;
        switch (field) {
          case 'styleIds':
            if (!this.$v.onboarding.creative.styleIds.required) {
              errors.push('Please select atleast one that describes your style');
            }
            break;
          case 'writerInterestIds':
            if (!this.$v.onboarding.creative.writerInterestIds.required) {
              errors.push('Please select atleast one interested topic');
            }
            break;
          case 'subjectIds':
            if (!this.$v.onboarding.creative.subjectIds.required) {
              errors.push('Please select atleast one subject you love');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  methods: {
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        EventBus.$emit('tag-details-resolved');
      }
    },
  },
  data() {
    return ({
    });
  },
  mounted() {
    EventBus.$on('tag-details-submit', () => {
      if (this.screen === this.screenIndex) {
        this.submitForm();
      }
    });
  },
  beforeDestroy() {
    EventBus.$off('tag-details-submit');
  },
  validations: {
    onboarding: {
      creative: {
        styleIds: {
          required,
        },
        writerInterestIds: {
          required,
        },
        subjectIds: {
          required,
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .input-field {
    margin-bottom: 10px;
    padding: 0;
  }
  ::v-deep .creative-copywriter.onboarding-checkbox-group {
    @media (min-width: 600px) {
      .v-input__control {
        width: 100%;
        margin-bottom: 8px;
      }
      .input-field-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      .input-field {
        min-width: 170px;
      }
      .input-field .v-input__slot {
        justify-content: flex-start;
      }
    }
  }
</style>
