<template>
  <v-container pa-0 id="screenContainer" class="h-100">
    <v-row align="center" v-if="!onlySchedule && hideCalendly"
    justify="center" class="ma-0 wrapper">
      <v-col cols="12" sm="10" md="8" lg="7">
        <VideoPlayer ref="videoPlayer" :options="videoOptions" class="video-player" />
        <div class="text text-center heading">Welcome to Blended Sense!</div>
        <div class="description">
          <p v-if="role=='client'">
            We’re so excited to introduce you to the Blended Sense Business Portal,
            a first of its kind. As you read this, our team is working behind the scenes to match
            you with a dedicated Producer. In the meantime, you can activate your dashboard by
            filling out your profile and scheduling a call with us. You’ll then be able to start
            tracking your content from production to distribution within the portal. That’s right.
            You can now manage &amp; store all of your creative assets in one place.
            Ready to get started?
           </p>
           <p v-else >
            We’re so excited to introduce you to the Blended Sense Creative Portal,
            a first of its kind. You can activate your dashboard by filling out your profile and
            scheduling a call with us. Our team can then get to work matching you with local
            creative projects you will love. Ready to get started?
            </p>
        </div>
        <v-btn class="schedule-a-call-button d-flex" text color="white"
          @click="scheduleACall(true)"  v-if="role === 'client'">
            Schedule a Call
        </v-btn>
        <v-btn class="skip-for-now-button d-flex" text color="info"
          @click="scheduleACall(false)">
            <span>Skip for now</span>
        </v-btn>
      </v-col>
    </v-row>
    <div class="calendly-container h-100 overflow-hidden" v-else>
      <div class="skip-btn-content pa-3">
        <v-btn text color="info" @click="scheduleACall(false)">Skip for now</v-btn>
      </div>
      <div class="calendar-content">
        <vue-calendly
          :url="calendlyUrl"
          style="min-width:320px;" data-processed="true">
        </vue-calendly>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import VideoPlayer from '@/components/common/VideoPlayer';
import { clientCalendlyUrl, creativeCalendlyUrl } from '@/helpers';

export default {
  components: {
    VideoPlayer,
  },
  props: {
    onlySchedule: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    calendlyUrl() {
      if (this.role === 'client') {
        return clientCalendlyUrl;
      }
      return creativeCalendlyUrl;
    },
  },
  data() {
    return {
      videoOptions: {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: 'https://app.blendedsense.com/api/intro.mp4',
            type: 'video/mp4',
          },
        ],
      },
      hideCalendly: true,
    };
  },
  methods: {
    ...mapActions('user', ['updateScheduleACallStatus']),
    async scheduleACall(status) {
      this.videoOptions.autoplay = false;
      if (status) {
        this.hideCalendly = false;
      } else {
        this.$emit('schedule-call', status);
      }
    },
  },
  mounted() {
    function isCalendlyEvent(e) {
      return e.data.event && e.data.event.indexOf('calendly') === 0;
    }

    window.addEventListener(
      'message',
      async (e) => {
        if (isCalendlyEvent(e)) {
          if (e.data.event === 'calendly.event_scheduled') {
            this.hideCalendly = true;
            this.$emit('schedule-call', true);
            await this.updateScheduleACallStatus();
          }
        }
      },
    );
  },
  beforeDestroy() {
    window.removeEventListener('message', () => {});
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 25px;
}

.Wrapper__cls1___2LpwQ.Wrapper__media-tablet-up___1cMla { margin-top: 0px; }

.video-player {
  margin: auto;
  width: 100%;
  height: 280px;
  margin-top: 60px;
}
.heading {
  height: 33px;
  font-size: 24px;
  letter-spacing: -0.48px;
  margin-top: 16px;
}
.description {
  font-family: $fontFamily1;
  font-size: 16px;
  line-height: 1.31;
  letter-spacing: -0.12px;
  margin-top: 16px;
}
.schedule-a-call-button {
    width: 300px;
    height: 48px !important;
    border-radius: 8px;
    box-shadow: 2px 2px 4px 0 rgba(129, 129, 129, 0.5);
    background-color:$primary5 !important;
    margin: auto;
    margin-top: 32px;
}
.skip-for-now-button {
  height: 17px !important;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 30px;
}
.skip-btn-content {
  text-align: end !important;
}
.calendar-content {
  height: calc(100% - 60px);
  overflow-y: auto;
}
::v-deep {
  .skip-for-now-button, .skip-btn-content {
    .v-btn__content{
      font-size: 14px;
      font-family: $fontFamily1;
      letter-spacing: -0.12px;
    }
  }
}
@media (max-width: 600px) {
  .wrapper {
    background-color: $neutral3;
  }
}
</style>
