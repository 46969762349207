<template>
    <div
      class="producer-contact" v-if="email">
        <div class="text font-label">Contact Your Producer</div>
        <v-card>
        <v-card-text class="d-flex flex-column producer-content">
            <div class="producer-contact-img">
            <AvatarRound
              :size="50"
              :avatar="avatar"
              :name="memberInitials(displayName)"
              textStyle="font-size: 16px;"
            />
            <div class="name text-wrap notranslate font-h5">{{displayName}}</div>
            </div>
            <div class="contact-details">
            <div class="d-flex align-center">
                <img class="email-icon" src="@/assets/svg/theme/email-outline.svg" />
                <a
                  class="text-wrap-all notranslate a-link"
                  :href="`mailto:${email}`"
                >{{email}}</a>
            </div>
            <div
              class="d-flex align-center pt-3"
            >
                <img class="calender-icon" src="@/assets/svg/calendly.svg" />
                <a
                  class="text-wrap a-link"
                  @click="$emit('handleScheduleCall')"
                >
                  Schedule a meeting
                </a>
            </div>
            </div>
        </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { getOptimizedS3ImageURL } from '@/helpers/';
import AvatarRound from '@/components/common/Avatar';

export default {
  name: 'ProducerContact',
  components: {
    AvatarRound,
  },
  methods: {
    getOptimizedS3ImageURL,
  },
  props: {
    displayName: {
      type: String,
    },
    avatar: {
      type: String,
    },
    email: {
      type: String,
    },
    callLink: {
      type: String,
    },
    callScheduled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    memberInitials() {
      return (displayName) => {
        let name = displayName.trim();
        if (name) {
          if (name.indexOf(' ') > 0) {
            const first = name.split(' ');
            name = first[0].charAt(0) + first[1].charAt(0);
          } else {
            name = name.charAt(0) + name.charAt(1);
          }
        }
        return name;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.producer-contact {
  max-width: 321px;
  .text-wrap {
    word-break: break-word;
  }
  .text-wrap-all {
    word-break: break-word;
  }
  .text {
    padding-bottom: 20px;
  }
  .producer-content {
    padding: 25px 30px;
  }
  .v-card {
    background: #FFFFFF;
    font-family: $fontFamily1;
    .v-card__text {
      box-shadow: 0px 2px 4px rgba(195, 195, 195, 0.25);
      border-radius: 10px !important;
    }
    .producer-contact-img {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .v-avatar {
        border-radius: 18px;
      }
      .name {
        padding-top: 10px;
      }
    }
    .contact-details {
      margin-top: 20px;
      a {
        padding-left: 1.5rem;
      }
      .email-icon {
        width: 20px;
        height: 20px;
      }
      .calender-icon {
        width: 23px;
        height: 23px;
      }
    }
  }
}
@media  (max-width: 598px) {
  .producer-contact {
    max-width: -webkit-fill-available;
  }
}
</style>
