<template>
    <v-container pa-0>
      <ClientTagForm v-if="role === 'client'"/>
      <CreativePhotoVideoForm
        v-else-if="role === 'creative' && scenario === 1"
        :screen="screen"
        :screenIndex="screenIndex"
      />
      <CreativeCopywriterForm
        v-else-if="role === 'creative' && scenario === 2"
        :screen="screen"
        :screenIndex="screenIndex"
      />
      <div v-else></div>
    </v-container>
</template>

<script>
import ClientTagForm from '@/components/dashboard/screens/forms/client/TagForm';
import CreativePhotoVideoForm from '@/components/dashboard/screens/forms/creative/PhotoVideoForm';
import CreativeCopywriterForm from '@/components/dashboard/screens/forms/creative/CopywriterForm';

export default {
  components: {
    ClientTagForm, CreativePhotoVideoForm, CreativeCopywriterForm,
  },
  props: {
    role: {
      type: String,
      default: 'client',
    },
    screen: {
      type: Number,
      default: 2,
    },
    screenIndex: {
      type: Number,
      default: 2,
    },
    scenario: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot{
  padding-right:0;
}
</style>
