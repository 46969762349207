<template>
    <v-form class="client-demographic-form">
        <v-container>
            <v-row>
                <v-col cols="12" sm="8" md="6" class="pa-0">
                    <BaseInput
                        inputLabel="Business Name *"
                        placeholder="Business Name"
                        name="businessName"
                        v-model.trim="onboarding.client.businessName"
                        :status="errors('businessName').length ? 'error': 'normal'"
                        :error-messages="errors('businessName')"
                        @input="$v.onboarding.client.businessName.$touch()"
                        @blur="$v.onboarding.client.businessName.$touch()"
                    />
                    <BaseInput
                        inputLabel="Business Number *"
                        placeholder="Business Phone Number"
                        v-mask="'###-###-####'"
                        name="number"
                        type="tel"
                        v-model.trim="onboarding.client.number"
                        :status="errors('number').length ? 'error': 'normal'"
                        :error-messages="errors('number')"
                        @input="$v.onboarding.client.number.$touch()"
                        @blur="$v.onboarding.client.number.$touch()"
                    />
                    <BaseInput
                        inputLabel="Website *"
                        placeholder="Business Website"
                        name="website"
                        v-model.trim="onboarding.client.website"
                        :status="errors('website').length ? 'error': 'normal'"
                        :error-messages="errors('website')"
                        @input="$v.onboarding.client.website.$touch()"
                        @blur="$v.onboarding.client.website.$touch()"
                    />
                </v-col>
                <v-col cols="12" class="pa-0 onboarding-radio-group">
                    <CustomRadioGroup
                      label="What type of business are you? *"
                      :options="businessTypes"
                      item-text="name"
                      item-value="id"
                      name="businessTypeId"
                      v-model="onboarding.client.businessTypeId"
                      :status="errors('businessTypeId').length ? 'error': 'normal'"
                      :error-messages="errors('businessTypeId')"
                      noErrorIcon
                    />
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import { url } from '@/helpers';
import EventBus from '@/helpers/event-bus';
import BaseInput from '@/components/common/BaseInput';
import CustomRadioGroup from '@/components/common/CustomRadioGroup';

export default {
  components: {
    BaseInput,
    CustomRadioGroup,
  },
  directives: {
    mask,
  },
  mounted() {
    EventBus.$on('demographic-details-submit', () => {
      this.submitForm();
    });
  },
  beforeDestroy() {
    EventBus.$off('demographic-details-submit');
  },
  computed: {
    ...mapState(['onboarding']),
    ...mapGetters('profile', ['businessTypes']),
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.onboarding.client[field].$dirty) return errors;
        switch (field) {
          case 'businessName':
            if (!this.$v.onboarding.client.businessName.required) { errors.push('Please provide your Business Name'); }
            if (!this.$v.onboarding.client.businessName.maxLength) { errors.push('Business Name must be less than 50 characters length'); }
            break;
          case 'number':
            if (!this.$v.onboarding.client.number.required) { errors.push('Please provide your Business Phone Number'); }
            if (!this.$v.onboarding.client.number.minLength) { errors.push('Please provide valid Business Phone Number'); }
            break;
          case 'website':
            if (!this.$v.onboarding.client.website.required) { errors.push('Please provide your Business Website'); }
            if (!this.$v.onboarding.client.website.url) { errors.push('Please provide valid url'); }
            break;
          case 'businessTypeId':
            if (!this.$v.onboarding.client.businessTypeId.required) { errors.push('Please select your Business Vertical'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  methods: {
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        EventBus.$emit('demographic-details-resolved');
      }
    },
  },
  data() {
    return ({

    });
  },
  validations: {
    onboarding: {
      client: {
        businessName: {
          required,
          maxLength: maxLength(50),
        },
        number: {
          required,
          minLength: minLength(12),
        },
        website: {
          required,
          url,
        },
        businessTypeId: {
          required,
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
    .client-demographic-form {
        ::v-deep .input-field-label {
            font-family: $fontFamily1;
            font-weight: bold;
        }
        ::v-deep .input-field {
            margin-bottom: 20px;
            padding: 0;
            input:-webkit-autofill {
             -webkit-box-shadow:0 0 0 50px white inset;
            }
        }
    }
    ::v-deep .v-input input::placeholder {
      font-family: $fontFamily1;
      font-size: 16px !important;
      font-weight: 300;
    }
    ::v-deep .v-input--radio-group.v-input--radio-group--row .v-radio {
      margin-right: 0
    }
</style>
