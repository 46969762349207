<template>
  <v-container>
    <ClientDemographicForm v-if="userDetails.role.name === 'client'"/>
    <CreativeDemographicForm v-else-if="userDetails.role.name === 'creative'"/>
    <div v-else></div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ClientDemographicForm from '@/components/dashboard/screens/forms/client/DemographicForm';
import CreativeDemographicForm from '@/components/dashboard/screens/forms/creative/DemographicForm';

export default {
  components: {
    ClientDemographicForm,
    CreativeDemographicForm,
  },
  props: {
    screen: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
  },
  data() {
    return ({
    });
  },
  methods: {

  },

};
</script>

<style lang="scss" scoped>


</style>
