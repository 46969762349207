<template>
<div class="h-100">
  <v-container fluid v-if="customer" class="content-wrapper h-100">
    <v-row class="ma-0">
      <v-col cols="12" class="pa-0">
        <ClientDashboard :currentStep="currentStep" :deliverables="deliverables"/>
      </v-col>
    </v-row>
  </v-container>
    <v-container fluid v-else-if="role === 'admin' || role === 'sales'"
      class="content-wrapper admin-wrapper h-100">
    <v-row class="ma-0 h-100">
      <v-col cols="12" class="pa-0">
        <AdminDashborad/>
      </v-col>
    </v-row>
  </v-container>
  <v-container fluid pa-0 v-else>
    <v-row
    class="ma-0 segment-content-wrapper segment-content-padding pt-8">
    <div class="pb-10"> <PageTitle /> </div> </v-row>
    <Loader v-if="loading" />
    <v-row v-else class="ma-0 segment-content-wrapper segment-content-padding">
      <v-col cols="12" md="8" class="left pa-0 pt-0 pr-4 mobile-padding">
        <div class="notifications-container">
          <DashboardNotifications/>
        </div>
        <v-row class="mt-2 ml-2 project-padding">
         <RecentProjects :recentProjects="recentProjects"/>
        </v-row>
      </v-col>
      <!-- It will be used once the calendar integration has been done -->
      <v-col cols="12" md="4"
        v-if="role !== 'editor' && role !== 'community_manager'"
        class="segment-content-wrapper upcoming-events-wrapper pa-0 pt-8 pl-4 border-left invites"
      >
        <UpcomingEvents
          :showInvitations="role === 'creative'"
          :invitations="invitations"
          :upcomingSweeps="upcomingSweeps"
          @update-dashboard-data="updateDashboardData"
        />
      </v-col>
    </v-row>
  </v-container>
  <template v-if="showConfirm">
      <v-dialog
        v-model="showConfirm"
        persistent
        max-width="680"
      ><v-card class='overflow-hidden py-2 px-1' flat>
        <div class="d-flex justify-end">
          <v-col class="d-flex justify-end pb-0">
          <v-icon color="darken-1" @click="showConfirm = false">mdi-close</v-icon>
        </v-col>
        </div>
        <v-card-text class="pa-5 pt-0 black--text font-family-2 card-text">
        You're currently set to away, but it looks like you're back.
        Want to update your availability?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn text
            @click="showConfirm = false"
            class="py-0"
            color="primaryGray1"
          >
            No Thanks
          </v-btn>
            <v-btn text
            @click="updateUserStateApi()"
            class="py-0 pr-4"
            color="info"
          >
            Set to Active
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import ClientDashboard from '@/components/dashboard/screens/client/Dashboard';
import UpcomingEvents from '@/components/dashboard/screens/common/UpcomingEvents';
import Loader from '@/components/common/Loader';
import { getUser } from '@/helpers';
import PageTitle from '@/components/common/PageTitle';
import AdminDashborad from './AdminDashboard';
import RecentProjects from './screens/common/RecentProjects';
import DashboardNotifications from '../common/DashboardNotifications';

export default {
  components: {
    ClientDashboard,
    UpcomingEvents,
    RecentProjects,
    DashboardNotifications,
    AdminDashborad,
    Loader,
    PageTitle,
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    customer() {
      return ['client', 'member'].includes(this.role);
    },
    inactiveDate() {
      const date1 = moment(new Date());
      const date2 = moment(this.userDetails.availabilityUpdatedAt);
      const inactiveDays = date1.diff(date2, 'days');
      return inactiveDays;
    },
  },
  methods: {
    ...mapActions('dashboard', ['getDashboardDetails']),
    ...mapActions('user', ['creativeStatusUpdate']),
    getDetails() {
      this.getDashboardDetails().then((res) => {
        if (this.customer && res.project) {
          if (res.project.contentKits.length) {
            this.currentStep = Number(res.project.contentKits[0].state);
          }
          this.deliverables = res.project.deliverables;
        }
        this.recentProjects = res.projectDetails;
        this.invitations = res.sweepInvitations;
        this.upcomingSweeps = res.sweepList;
      });
    },
    async closePopup(isPopup) {
      const localStoreData = JSON.parse(localStorage.getItem('__bs_valid'));
      const userDetailsObj = Object.assign({},
        { ...localStoreData },
        { isPopup });
      const userDetailsObjs = JSON.stringify(userDetailsObj);
      localStorage.setItem('__bs_valid', userDetailsObjs);
    },
    isCreative() {
      const localStoreData = JSON.parse(localStorage.getItem('__bs_valid'));
      if (this.role === 'creative' && this.userDetails.availability === '2' && localStoreData.isPopup === false && this.inactiveDate >= process.env.VUE_APP_INACTIVE_DAYS) {
        this.showConfirm = true;
        this.isPopup = true;
        this.closePopup(this.isPopup);
      } else {
        this.showConfirm = false;
      }
    },
    async updateUserStateApi() {
      const response = await this.creativeStatusUpdate({
        availability: this.userDetails.availability === '1' ? '2' : '1',
      });
      if (response.success) {
        this.showConfirm = false;
      }
    },
    updateDashboardData() {
      this.getDetails();
    },
  },
  props: ['role'],
  data() {
    return ({
      currentStep: 0,
      recentProjects: [],
      deliverables: '',
      upcomingSweeps: [],
      invitations: [],
      showConfirm: false,
      loading: false,
    });
  },
  async mounted() {
    this.loading = true;
    const user = await getUser();
    // FIXME: check why this component re-mounting
    // on logout
    if (user && user.token) {
      await this.getDetails();
    }
    this.isCreative();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
  .segment-content-wrapper {
    padding: 0 20px 0 40px;
  }
  .font-family-2 {
    font-family: $fontFamily1;
    }
  .upcoming-events-wrapper{
    overflow-x: auto;
    padding-left: 30px;
  }
  .border-right {
    border-right: 1px solid $neutral5;
  }
  .border-left {
    border-left: 1px solid $neutral5;
  }
  @media (max-width: 600px) {
    .segment-content-wrapper {
      padding: 0;
      padding-top: 30px;
    }
    .mobile-padding {
      padding-left: 16px !important;
      margin-left: 0px !important;
    }
    .project-padding {
      margin-left: 10px !important;
    }
    .theme--light.v-icon:focus {
    opacity: 0;
    }
    .upcoming-events-wrapper {
      padding-right: 16px !important;
      padding-top: 16px !important;
      padding-left: 16px !important;
    }
    .border-left {
     border-left: 0px solid $neutral5;
    }
  }
  @media (min-width: 601px) and (max-width: 900px) {
    .upcoming-events-wrapper {
      padding: 0px !important;
    }
    .project-padding {
      margin-left: 4px !important;
    }
    .border-left {
     border-left: 0px solid $neutral5;
    }
    .segment-content-padding {
      padding-left: 20px !important;
    }
    .invites {
      padding-left: 8px !important;
    }
  }
</style>
