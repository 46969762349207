<template>
    <div>
        <label class="text-left input-field-label font-label">
          {{ label }}
          <slot name="tooltip" />
        </label>
        <div>
            <v-radio-group
                row
                :class="inputStatus"
                v-bind="$attrs"
                v-on="$listeners"
            >
                <div
                  v-for="(option, index) in options"
                  :key="index"
                  class="input-field"
                  :class="{'input-field-active': (option[itemValue] === $attrs.value)}"
                >
                    <v-radio
                        :label="option[itemText]"
                        :value="option[itemValue]"
                        color="info"
                        >
                    </v-radio>
                    <v-icon v-if="!noErrorIcon" v-show="status === 'error'" color="error">
                        mdi-alert-circle-outline
                    </v-icon>
                </div>
            </v-radio-group>
        </div>
    </div>
</template>
<script>
export default {
  name: 'CustomRadioGroup',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    originParent: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'normal',
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    noErrorIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputStatus() {
      return `${this.status}State`;
    },
    itemText() {
      if (this.$attrs['item-text']) {
        return this.$attrs['item-text'];
      }
      return 'label';
    },
    itemValue() {
      if (this.$attrs['item-value']) {
        return this.$attrs['item-value'];
      }
      return 'value';
    },
  },
};
</script>

<style lang="scss" scoped>
  .input-field {
    display: flex;
    justify-content: space-between;
    padding: 0 7px;
    margin: 0;
    flex-basis: 48%;
  }
  .input-field-label {
    display: block;
    margin-bottom: 10px;
  }
  ::v-deep .v-input__control {
    width: 100% !important;
  }
  ::v-deep .v-input--radio-group--row .v-input--radio-group__input {
    justify-content: space-between;
  }
  ::v-deep .v-radio {
    margin-right: 0;
  }
  ::v-deep .errorState .v-input__control > .v-input__slot .input-field {
    border: solid 1px #ff0808 !important;
  }
  ::v-deep .successState .v-input__control > .v-input__slot .input-field {
    border: solid 1px #00c48c !important;
  }
  ::v-deep .disabledState .v-input__control > .v-input__slot .input-field {
    border: solid 1px #e6e8ed !important;
    background-color: #ecebed !important;
  }
  ::v-deep .normalState .v-input__control > .v-input__slot .input-field {
    border: solid 1px #e6e8ed !important;
  }
  ::v-deep .normalState .v-input__control > .v-input__slot .input-field-active {
    border: solid 3px #65d6eb !important;
  }
</style>
