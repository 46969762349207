<template>
  <div class="earning-div ml-12 mt-9">
    <v-col style="border-bottom: 1px solid #dedede;">
      <div class="heading px-4 py-2">Your Earnings</div>
    </v-col>
          <v-row class="pl-6 pr-2">
            <v-col class="pl-0 pr-0" style="flex-grow: 1">
              <div class="earning-headings pa-6 pl-0 pr-0">MTD EARNINGS</div>
              <div class="earning-headings pa-6 pl-0 pr-0">LAST MONTH EARNINGS</div>
              <div class="earning-headings pa-6 pl-0 pr-0">YTD EARNINGS</div>
            </v-col>
            <v-col style="max-width: fit-content;min-width: 100px;">
              <div
               class="earning-rate pt-2 pb-2 mt-4">
               $ {{earnings.mtdEarnings}}</div>
              <div class="earning-rate pt-2 pb-2 mt-8">
                $ {{parseFloat(earnings.ltdEarnings.toFixed(2))}}
              </div>
              <div class="earning-rate pt-2 pb-2 mt-8">
                $ {{parseFloat(earnings.ytdEarnings.toFixed(2))}}
              </div>
            </v-col>
            <v-col class="pl-3 pr-0">
              <div class="earning-headings pa-6 pl-0 pr-0 pt-6">MTD SWEEPS</div>
              <div class="earning-headings pa-6 pl-0 pr-0">LAST MONTH SWEEPS</div>
              <div class="earning-headings pa-6 pl-0 pr-0">YTD SWEEPS</div>
            </v-col>
            <v-col cols="2">
              <div class="earning-rate pt-2 pb-2 mt-4">{{earnings.mtdSweeps}}</div>
              <div class="earning-rate pt-2 pb-2 mt-8">{{earnings.ltdSweeps}}</div>
              <div class="earning-rate pt-2 pb-2 mt-8">{{earnings.ytdSweeps}}</div>
            </v-col>
          </v-row>
    </div>
</template>

<script>
export default {
  props: {
    earnings: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.earning-div {
  border: 1px solid #DEDEDE;
  .heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
    .earning-headings {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.5px;
    }
    .earning-rate {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      border-radius: 30px;
      background-color: #E5E7FA;
      color: #8066ff;
    }
  }
</style>
