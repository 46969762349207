<template>
  <div>
    <Loader v-if="showLoader" />
    <template v-else-if="!(showLoader && emptyAnalytics)">
    <div class="pr-0 export-header">
      <v-card flat>
        <v-card-text class="d-flex justify-end pa-1 pr-3">
          <div class="d-flex pt-0 mb-0 pb-0 export-button align-center">
            Time Range:
              <v-select
                :items="timeRangeItems"
                solo
                flat
                dense
                v-model="selectedRange"
                :menu-props="{
                  'content-class' : 'filter-dropdown analytics', bottom: true, offsetY: true
                }"
                hide-details
                class="time-range-filter"
                @change="updateTimeRange"
                auto-grow
                style="min-width: min-content;"
              >
              </v-select>
                <div v-if="selectedRange === 'Range' && rangeDate !== ''">
                  <span class="selection" @click="DatePickerModal = true">
                    ( {{ rangeDate }} )</span>
                </div>
              <v-dialog
                ref="dialog"
                v-model="DatePickerModal"
                persistent
                width="290px"
              >
              <v-date-picker
                v-model="dates"
                scrollable
                range
                no-title
                color="info"
              >
                <v-spacer></v-spacer>
                <v-btn
                text
                color="primaryGray1"
                @click="cancelDatePicker"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="info"
                @click="saveDate(dates)"
              >
                OK
              </v-btn>
            </v-date-picker>
            </v-dialog>
            <v-btn text @click="downloadCSV(projectDetails)" class="text-purple export-button"
            :disabled="!projectDetails.length">Export</v-btn>
         </div>
       </v-card-text>
     </v-card>
      <div class="analytics-card card-height" v-if="projectDetails.length">
          <div class="border-gray" v-for="project in projectDetails" :key="project.id">
            <v-row class="pa-1 d-flex align-center">
              <v-col class="pl-4 pa-0">
                <span class="pa-1 title-text">
                Business Name: <span class="notranslate">{{ project.name }}</span>
              </span>
              <span class="save-btn" @click="viewSweepDetails(project.sweeps, project.name)">
                  <v-icon class="pb-1 eye-color">mdi-eye</v-icon>
                </span>
              </v-col>
            </v-row>
           <v-card flat class="pa-2">
              <div class="pa-0">
                <v-card-text class="pa-1 text-size">
                  Total Sweeps: {{ project.sweepsCount }}
                </v-card-text>
                <v-card-text class="pa-1 text-size">
                  Accepted: {{ project.totalAccepted }}
                </v-card-text>
                <v-card-text class="pa-1 text-size">
                  Declined: {{ project.totalDeclined }}
                </v-card-text>
                <v-card-text class="pa-1 text-size">
                  Expired: {{ project.toatalExpiredInvitationCount }}
                </v-card-text>
              </div>
           </v-card>
          </div>
      </div>
    <div v-else>
      <v-col class="file-padding">
      Sorry, no results found!
      Please try applying different time range.
    </v-col>
    </div>
    <template v-if="showSweepDetails">
      <v-dialog
        v-model="showSweepDetails"
        max-width="600"
      >
        <v-card class="modal-card overflow-hidden" flat>
            <v-row class="my-0 px-5">
                <v-col class="d-flex align-center pb-1" cols=7>
                <span class="text-capitalize title notranslate">{{ projectName }} </span>
                </v-col>
                <v-col class="d-flex justify-end align-center pb-1" cols="4">
                 <v-btn text @click="downloadCSV(sweepDetails, projectName)"
                 color="info" class="export-button">
                   Export
                 </v-btn>
                </v-col>
                <v-col class="d-flex justify-end pb-1" cols="1">
                <v-icon color="darken-1" @click="showSweepDetails = false">mdi-close</v-icon>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <div v-if="sweepDetails.length > 0" class="sweep-list">
            <v-card flat
            v-for="sweep in sweepDetails" :key="sweep.id"
            class="mt-0 pa-0"
            >
             <v-divider></v-divider>
             <div class="pa-1 pl-5">
              <v-card-title class="pa-1 pt-2 title-text">
                Sweep Name: <span class="notranslate">{{ sweep.title }}</span>
              </v-card-title>
                <v-card-text class="pa-1 text-size">
                    Created Date: {{ sweepDate(sweep.date) }}
                </v-card-text>
                <v-card-text class="pa-1 text-size">
                    Accepted: {{ sweep.accepted }}
                </v-card-text>
                <v-card-text class="pa-1 text-size">
                    Declined: {{ sweep.declined }}
                </v-card-text>
                <v-card-text class="pa-1 pb-3 text-size">
                    Expired: {{ sweep.expiredInvitationCount }}
                </v-card-text>
             </div>
            </v-card>
            </div>
            <div v-else class="pa-5 text-size"> No sweeps.</div>
        </v-card>
      </v-dialog>
     </template>
  </div>
  </template>
  </div>
</template>

<script>
/* eslint-disable no-case-declarations */
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import Loader from '@/components/common/Loader';

export default {
  components: {
    Loader,
  },
  computed: {
    ...mapGetters('admin', ['contentSweeps']),
    sweepDate() {
      return (date) => moment(date).format('MMMM D, YYYY');
    },
    date() {
      const date = new Date().toISOString().substr(0, 10);
      return moment(date).format('MM-DD-YYYY');
    },
    projectDetails() {
      return this.contentSweeps.projects;
    },
    emptyAnalytics() {
      return _.isEmpty(this.contentSweeps);
    },
  },
  methods: {
    ...mapActions('admin', ['getContentSweepAnalytics']),
    getRange(timeRange) {
      const today = moment();
      switch (timeRange) {
        case 'Yesterday':
          const yesterday = today.subtract(1, 'days').format('YYYY-MM-DD');
          return { fromDate: yesterday, toDate: yesterday };
        case 'This week':
          return {
            fromDate: moment(today).startOf('isoWeek').format('YYYY-MM-DD'),
            toDate: today.format('YYYY-MM-DD'),
          };
        case 'This month':
          return {
            fromDate: moment(today).startOf('month').format('YYYY-MM-DD'),
            toDate: moment(today).endOf('month').format('YYYY-MM-DD'),
          };
        case 'This year':
          return {
            fromDate: moment(today).startOf('year').format('YYYY-MM-DD'),
            toDate: moment(today).endOf('year').format('YYYY-MM-DD'),
          };
        case 'Last month':
          const monthFrom = moment(today).subtract(1, 'months').startOf('month');
          return {
            fromDate: monthFrom.format('YYYY-MM-DD'),
            toDate: monthFrom.endOf('month').format('YYYY-MM-DD'),
          };
        default:
          return { fromDate: today.format('YYYY-MM-DD'), toDate: today.format('YYYY-MM-DD') };
      }
    },
    async saveDate(date) {
      this.DatePickerModal = false;
      this.showLoader = true;
      this.dates = date;
      const sortDate = this.dates.sort((a, b) => {
        const formDate = new Date(a);
        const toDate = new Date(b);
        if (formDate !== toDate) {
          if (formDate > toDate) { return 1; }
          if (formDate < toDate) { return -1; }
        }
        return formDate - toDate;
      });
      const fromDate = moment(sortDate[0]);
      const toDate = moment(sortDate[1]);
      this.selectedDate = sortDate;
      this.rangeDate = `${moment(fromDate).format('MMMM DD, YYYY')} - ${moment(toDate).format('MMMM DD, YYYY')}`;
      this.DatePickerModal = false;
      await this.getContentSweepAnalytics({ fromDate: fromDate.format('YYYY-MM-DD'), toDate: toDate.format('YYYY-MM-DD') });
      this.showLoader = false;
    },
    async updateTimeRange(val) {
      this.showLoader = true;
      if (val === 'Range') {
        this.DatePickerModal = true;
        this.rangeDate = '';
        this.dates = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
      } else {
        await this.getContentSweepAnalytics(this.getRange(val));
      }
      this.showLoader = false;
    },
    cancelDatePicker() {
      this.DatePickerModal = false;
      this.dates = this.selectedDate;
    },
    viewSweepDetails(sweepDetails, projectName) {
      this.projectName = projectName;
      this.sweepDetails = sweepDetails;
      this.showSweepDetails = true;
    },
    csvData(projectDetails) {
      return _.map(projectDetails, (project, i) => ({
        sno: i + 1,
        'project name': `${project.name}`,
        'total sweeps': `${project.sweepsCount}`,
        accepted: `${project.totalAccepted}`,
        declined: `${project.totalDeclined}`,
        expired: `${project.toatalExpiredInvitationCount}`,
      }));
    },
    csv(sweepDetails) {
      return _.map(sweepDetails, (sweep, i) => ({
        sno: i + 1,
        'sweep name': `${sweep.title}`,
        'created date': `${this.sweepDate(sweep.date)}`,
        accepted: `${sweep.accepted}`,
        declined: `${sweep.declined}`,
        expired: `${sweep.expiredInvitationCount}`,
      }));
    },
    async downloadCSV(details, projectName) {
      this.showLoader = true;
      const arrData = projectName ? this.csv(details) : this.csvData(details);
      const csvContent = [
        _.map(Object.keys(arrData[0]), (key) => key.toUpperCase()).join(';'),
        ...arrData.map((item) => Object.values(item).join(';')),
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      const date = this.getRange(this.selectedRange);
      const { fromDate, toDate } = date;
      const rangeFromDate = this.dates[0];
      const rangeToDate = this.dates[1];
      const selectedRange = `${moment(rangeFromDate).format('MM-DD-YYYY')} - ${moment(rangeToDate).format('MM-DD-YYYY')}`;
      const selectedDate = `${moment(fromDate).format('MM-DD-YYYY')} - ${moment(toDate).format('MM-DD-YYYY')}`;
      const range = this.selectedRange === 'Range' ? selectedRange : selectedDate;
      const fileName = projectName ? `${projectName}` : 'content-sweep-analytics';
      link.setAttribute('download', `${fileName}(${range})_${this.date}.csv`);
      link.click();
      this.showLoader = false;
    },
  },
  data() {
    return {
      showSweepDetails: false,
      sweepDetails: [],
      showLoader: false,
      timeRangeItems: ['Today', 'Yesterday', 'This week', 'This month', 'Last month', 'This year', 'Range'],
      selectedRange: 'This month',
      projectName: '',
      DatePickerModal: false,
      dates: ['', ''],
      rangeDate: '',
      selectedDate: ['', ''],
    };
  },
  async mounted() {
    this.showLoader = true;
    await this.getContentSweepAnalytics(this.getRange(this.selectedRange));
    this.showLoader = false;
  },
};
</script>

<style lang="scss" scoped>
.border-gray {
  border: 1px solid $neutral5;
  margin-bottom: 10px !important;
  padding: 5px;
  border-radius: 5px;
}
.title-text {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $neutral1;
}
.text-size {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $neutral1;
}
.v-card {
  border-radius: 0 !important;
}
.title {
font-family: $fontFamily1 !important;
font-size: 18px !important;
font-weight: bold;
}
.save-btn {
  color: $secondary1;
}
.analytics-card {
  padding-left: 2px;
  padding-right: 5px;
  overflow: hidden;
}
.sweep-list {
  margin-top: 0px;
  overflow: auto;
  max-height: calc(100vh - 200px) !important;
}
.eye-color {
    color: $secondary1;
    cursor: pointer;
}
.export-button {
  ::v-deep .v-btn__content {
    font-family: $fontFamily1 !important;
    letter-spacing: -0.32px;
  }
}
::v-deep .v-date-picker-table {
    height: 230px;
}
.export-header {
  z-index: 2;
}
::v-deep .v-card__actions{
    display: flex;
    justify-content: flex-end;
}
.card-height {
  height: calc(100vh - 326px) !important;
  margin-top: 10px;
  overflow-y: auto;
}
.time-range-filter {
    max-width: 160px;
    .v-text-field .v-select__slot .v-select__selection--comma {
      min-width: min-content;
    }
    ::v-deep .v-input__slot, ::v-deep .v-input__append-inner{
      padding-left: 0;
      .v-input__icon {
        min-width: 16px;
      }
    }
    ::v-deep .v-select__selections input { width: 5px }
  }
.file-padding {
    padding: 12px;
  }
.selection{
  cursor: pointer;
}
@media (max-width: 1200px) {
 .analytics-card {
    padding: 8px 10px !important;
 }
}
@media (max-width: 960px) {
  .card-height {
    height: calc(100vh - 210px) !important;
  }
}
@media (max-width: 600px) {
  .card-height {
    height: calc(100vh - 250px) !important;
  }
}
</style>
