<template>
      <div v-if="deliverableList.length">
        <v-row class="ma-0 pa-0">
            <v-col cols="6" class="delivered-title pa-2 pb-1 pl-0">Deliverable</v-col>
            <v-col cols="6" class="delivered-title pa-2 pb-1 pl-3">Deliverable Type</v-col>
        </v-row>
        <v-card-text class="ma-0 pa-0 scroll" :style="{height: deliverableHeight}" >
        <v-row class="ma-0" v-for="deliverable in deliverableList" :key="deliverable.id">
            <v-col cols="6" class="delivered-text name-ellipsis pa-0">
            {{ deliverable.name }}</v-col>
            <v-col cols="6" class="delivered-text name-ellipsis pa-0 pl-3">
            {{ categoryName(deliverable) }}</v-col>
        </v-row>
        </v-card-text>
    </div>
    <div v-else class="py-4 content-text">
      A list of your tailored just-for-you digital assets will show here.
    </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'DeliverablesList',
  props: {
    deliverableList: {
      type: Array,
      default: () => [],
    },
    deliverableHeight: {
      type: String,
      default: 'auto',
    },
  },
  computed: {
    categoryName() {
      return (item) => _.get(item, 'category.name');
    },
  },
};

</script>

<style scoped lang="scss">

.deliverable-card {
  > div {
        max-width: 100%;
      }
      .name-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
}
.scroll{
  overflow: auto;
}
.delivered-title {
  color: #727272;
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.66;
  text-align: left;
}
.delivered-text {
  color: $neutral1;
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.66;
  text-align: left;
}
.card-heading {
  color: #565682;
  font-size: 14px;
  font-family: $fontFamily1;
  font-weight: bold;
  text-align: left;
  letter-spacing: -0.28px;
}
.content-text {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.66;
  letter-spacing: normal;
  text-align: left;
  color: #727272;
}
</style>
