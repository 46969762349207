<template>
  <div>
    <div class="content-wrapper">
      <v-row class="ma-0">
        <v-col class="d-flex align-center pa-0">
          <BaseInput
            solo
            hide-details
            v-model="search"
            placeholder="Search ..."
            prepend-inner-icon="search"
            class="mt-1 search-box"
            clearable
            @click:clear="search === ''"
          />
        </v-col>
        <v-col class="d-flex align-center justify-end pa-0">
          <div class="pr-0 export-header">
        <v-card flat>
          <v-card-text class="d-flex justify-end pa-1 pr-5">
            <div class="d-flex pt-0 mb-0 pb-0 export-button align-center">
                Time Range:
                <v-select
                  :items="monthFilters"
                  solo
                  flat
                  dense
                  v-model="months"
                  :menu-props="{
                    'content-class' : 'filter-dropdown analytics', bottom: true, offsetY: true
                  }"
                  hide-details
                  class="time-range-filter"
                  @change="updateTimeRange"
                  auto-grow
                  style="min-width: min-content;"
                >
                </v-select>
                <div v-if="months === 'Range' && rangeDate !== ''">
                  <span class="selection" @click="DatePickerModal = true">
                    ( {{ rangeDate }} )</span>
                </div>
                <v-dialog
                  ref="dialog"
                  v-model="DatePickerModal"
                  persistent
                  width="290px"
                >
                <v-date-picker
                  v-model="dates"
                  scrollable
                  range
                  no-title
                  color="info"
                >
                  <v-btn
                  text
                  color="primaryGray1"
                  @click="cancelDatePicker"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="info"
                  @click="saveDate(dates)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
             <v-btn text @click="downloadCSV()" class="export-button" color="info">Export</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
        </v-col>
      </v-row>
      <div class="listing-block">
        <Loader v-if="showLoader" />
        <SweepReportList
          v-else
          :reports="reportdata.creatives"
          :fetching="showLoader"
          @onEditClick="goToChangePayout"
          @setDisplayedRows="setRowsData"
          :search="search"
        />
      </div>
    </div>
    <v-container
      fluid
      v-if="payoutModal"
    >
      <Modal
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
        content-class="new-user-form"
        :modal="payoutModal"
        width="500px"
        scrollable
      >
        <PayoutModal
          @reload-list="reloadList"
          @closemodal="closePayoutModal"
          :sweep="this.selectedSweep"
        />
      </Modal>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable no-case-declarations */
import { mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import Modal from '@/components/common/Modal';
import BaseInput from '@/components/common/BaseInput';
import SweepReportList from '@/components/dashboard/screens/Admin/Reports/SweepReportList';
import Loader from '@/components/common/Loader';
import PayoutModal from '../Forms/PayoutModal';

export default {
  components: {
    BaseInput,
    SweepReportList,
    Loader,
    Modal,
    PayoutModal,
  },

  computed: {
    timeConvert() {
      return (n) => {
        const num = n;
        const hours = num / 60;
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        const checkForMin = `${rminutes > 0 ? `${rminutes} min` : ''}`;
        const checkForHours = `${rhours > 0 ? `${rhours} hrs` : checkForMin}`;
        const text = rhours > 0 && rminutes > 0 ? `${rhours} hrs ${rminutes} min` : checkForHours;
        return text;
      };
    },
  },
  methods: {
    ...mapActions('admin', ['getSweepReportList', 'exportSweepReports']),
    setRowsData(data) {
      this.exportData = data;
    },
    skillset(val) {
      switch (val) {
        case '1':
          return 'Photographer';
        case '2':
          return 'Videographer';
        case '3':
          return 'Talent';
        default:
          return 'Field Producer';
      }
    },
    duration(val) {
      const startTime = moment(val.fromTime, 'hh:mm a');
      const endTime = moment(val.closedAt, 'hh:mm a');
      const diff = moment.duration(endTime.diff(startTime));
      return this.timeConvert(diff.asMinutes());
    },
    csvData(sweep) {
      return _.map(sweep, (sweeps, i) => ({
        sno: i + 1,
        sweepName: sweeps.sweep.title,
        businessName: sweeps.sweep.business.name,
        creativeName: `${sweeps.creatives.firstName} ${sweeps.creatives.lastName}`,
        creativeType: this.skillset(sweeps.skill),
        date: moment(sweeps.sweep.date).format('M/DD/YYYY'),
        time: moment((sweeps.sweep.fromTime), ['HH:mm']).format('h:mm A'),
        sweepTime: this.duration(sweeps.sweep),
        location: `${_.map(sweeps.sweep.sweepLocations, 'location', '')}`,
        // eslint-disable-next-line
        payout: `$${_.map(sweeps.sweep.sweepCreativePayout, 'payout', '')}`,
        reimbursement: `$${sweeps.sweep.sweepCreativePayout.map((x) => this.reimbursementField(x.reimbursement))}`,
      }));
    },
    reimbursementField(val) {
      if (val === null) {
        return 0;
      }
      return val;
    },
    async downloadCSV() {
      const payload = {
        creativeSweepReports: this.exportData.map((data) => ({
          sweepId: data.data.id.sweepId,
          userId: data.data.id.creatives.id,
        })),
      };
      const result = await this.exportSweepReports(payload);
      if (result.success) {
        const arrayData = this.csvData(result.creatives);
        const csvContent = [
          _.map(Object.keys(arrayData[0]), (key) => key.toUpperCase()).join(';'),
          ...arrayData.map((item) => Object.values(item).join(';')),
        ]
          .join('\n')
          .replace(/(^\[)|(\]$)/gm, '');
        const blob = new Blob([csvContent], {
          type: 'text/csv;charset=utf-8',
        });
        const url = window.URL.createObjectURL(blob);
        // const data = encodeURI(csvContent);
        const link = document.createElement('a');
        const fileName = 'sweepReports';
        link.setAttribute('href', url);
        link.setAttribute('download', `${fileName.toLowerCase()}.csv`);
        link.click();
      }
    },
    async reloadList() {
      this.showLoader = true;
      this.reportdata = await this.getSweepReportList(this.getRange(this.months));
      this.showLoader = false;
    },
    cancelDatePicker() {
      this.DatePickerModal = false;
      this.dates = this.selectedDate;
    },
    getRange(timeRange) {
      const today = moment();
      switch (timeRange) {
        case 'Yesterday':
          const yesterday = today.subtract(1, 'days').format('YYYY-MM-DD');
          return { fromDate: yesterday, toDate: yesterday };
        case 'This week':
          return {
            fromDate: moment(today).startOf('isoWeek').format('YYYY-MM-DD'),
            toDate: today.format('YYYY-MM-DD'),
          };
        case 'This month':
          return {
            fromDate: moment(today).startOf('month').format('YYYY-MM-DD'),
            toDate: moment(today).endOf('month').format('YYYY-MM-DD'),
          };
        case 'This year':
          return {
            fromDate: moment(today).startOf('year').format('YYYY-MM-DD'),
            toDate: moment(today).endOf('year').format('YYYY-MM-DD'),
          };
        case 'Last month':
          const monthFrom = moment(today).subtract(1, 'months').startOf('month');
          return {
            fromDate: monthFrom.format('YYYY-MM-DD'),
            toDate: monthFrom.endOf('month').format('YYYY-MM-DD'),
          };
        default:
          return { fromDate: today.format('YYYY-MM-DD'), toDate: today.format('YYYY-MM-DD') };
      }
    },
    async saveDate(date) {
      this.DatePickerModal = false;
      this.showLoader = true;
      this.dates = date;
      const sortDate = this.dates.sort((a, b) => {
        const formDate = new Date(a);
        const toDate = new Date(b);
        if (formDate !== toDate) {
          if (formDate > toDate) { return 1; }
          if (formDate < toDate) { return -1; }
        }
        return formDate - toDate;
      });
      const fromDate = moment(sortDate[0]);
      const toDate = moment(sortDate[1]);
      this.selectedDate = sortDate;
      this.rangeDate = `${moment(fromDate).format('MMMM DD, YYYY')} - ${moment(toDate).format('MMMM DD, YYYY')}`;
      this.reportdata = await this.getSweepReportList({ fromDate: fromDate.format('YYYY-MM-DD'), toDate: toDate.format('YYYY-MM-DD') });
      this.showLoader = false;
    },
    async updateTimeRange(val) {
      this.showLoader = true;
      if (val === 'Range') {
        this.DatePickerModal = true;
        this.rangeDate = '';
        this.dates = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
      } else {
        this.reportdata = await this.getSweepReportList(this.getRange(val));
      }
      this.showLoader = false;
    },
    closePayoutModal() {
      this.payoutModal = false;
    },
    goToChangePayout(val) {
      this.selectedSweep = _.cloneDeep(_.find(this.reportdata.creatives, ['id', val.id]));
      this.payoutModal = true;
    },
  },
  async mounted() {
    this.showLoader = true;
    this.reportdata = await this.getSweepReportList(this.getRange(this.months));
    this.showLoader = false;
  },
  data() {
    return {
      selectedSweep: {},
      reportdata: [],
      exportData: [],
      payoutModal: false,
      months: 'Today',
      monthFilters: [
        'Today', 'Yesterday', 'This week', 'This month', 'Last month', 'This year', 'Range'],
      search: '',
      DatePickerModal: false,
      dates: ['', ''],
      rangeDate: '',
      selectedDate: ['', ''],
      showLoader: false,
    };
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-label {
  font-family: $fontFamily1;
  font-size: 14px;
  color: $neutral1;
}
.sort-text {
  font-family: $fontFamily1;
  font-size: 14px;
}
::v-deep .v-text-field {
  font-family: $fontFamily1;
  font-size: 14px;
}
.search-box ::v-deep {
  .v-input__slot {
    border: 1px solid  #b0b0b0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  .v-input__control {
    input {
      max-height: 34px !important;
    };
  }
}
.search-box {
  ::v-deep .v-text-field {
    height: 40px;
    font-size: 16px;
  }
}
.v-data-table {
  ::v-deep th {
    font-weight: bold;
    font-size: 16px;
    color: $neutral1 !important;
  }
  ::v-deep td {
    font-size: 14px;
    color: $neutral1;
    font-family: $fontFamily1;
  }
  ::v-deep td:nth-child(1) {
    color: #0373d1;
  }
  ::v-deep tr:nth-child(even) {
    background-color: #f8f8f8;
  }
}
.listing-block {
  height: calc(100vh - 310px);
  overflow: auto;
}
.admin-menu {
    .v-list-item {
      background: red;
      padding: 4px 20px;
      padding-right: 30px;
      min-height: auto;
    }
  }
.content-wrapper {
  padding: 0px;
  .export-button {
    ::v-deep .v-btn__content {
      font-family: $fontFamily1;
      letter-spacing: -0.32px;
    }
  }
  .time-range-filter {
    width: 140px;
    .v-text-field .v-select__slot .v-select__selection--comma {
      min-width: min-content;
    }
    ::v-deep .v-input__slot, ::v-deep .v-input__append-inner{
      padding-left: 0;
      .v-input__icon {
        min-width: 16px;
      }
    }
    ::v-deep .v-select__selections input { width: 5px }
  }
}
@media (max-width: 600px) {
  .listing-block {
    height: calc(100vh - 255px);
  }
}
</style>
