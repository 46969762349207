<template>
  <div>
    <v-row class="analytics-page h-100 flex-column">
      <div class="pb-10"><PageTitle /></div>
      <v-select
        v-if="$vuetify.breakpoint.xsOnly"
        solo
        flat
        class="type-selection notranslate tabs-dropdown"
        placeholder="Select..."
        :items="tabsList"
        item-text="name"
        item-value="value"
        v-model="tab"
        hide-details
        :menu-props="{ 'content-class': 'notranslate' }"
        append-icon="mdi-chevron-down"
      ></v-select>
      <v-tabs v-model="tab" class="tabs-padding pa-0" v-else>
        <v-tab href="#general_analytics">General Analytics</v-tab>
        <v-tab href="#sweep_analytics" active> Sweep Analytics </v-tab>
        <v-tab href="#reporting"> Reports </v-tab>
        <v-tab href="#sweep_reports"> Sweep Reports </v-tab>
        <v-tab href="#credit_reports"> Credits Report </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="font-family-2 analytics-height">
        <v-tab-item
          value="general_analytics"
          :key="'general_analytics'"
          :transition="false"
          :reverse-transition="false"
        >
          <GeneralAnalytics />
        </v-tab-item>
        <v-tab-item
          :transition="false"
          :reverse-transition="false"
          value="sweep_analytics"
          :key="'sweep_analytics'"
          class="content-sweeps-tab"
        >
          <ContentSweepAnalytics />
        </v-tab-item>
        <v-tab-item
          :transition="false"
          :reverse-transition="false"
          value="reporting"
          :key="'admin_reporting'"
          class="content-sweeps-tab"
        >
          <Reports />
        </v-tab-item>
        <v-tab-item
          :transition="false"
          :reverse-transition="false"
          value="sweep_reports"
          :key="'admin_reporting'"
          class="content-sweeps-tab"
        >
          <SweepReports />
        </v-tab-item>
        <v-tab-item
          :transition="false"
          :reverse-transition="false"
          value="credit_reports"
          :key="'admin_reporting'"
          class="content-sweeps-tab"
        >
          <CreditReports />
        </v-tab-item>
      </v-tabs-items>
    </v-row>
  </div>
</template>

<script>
import SweepReports from '@/components/dashboard/screens/Admin/Reports/SweepReports'
import PageTitle from '@/components/common/PageTitle'
import ContentSweepAnalytics from './screens/Admin/Analytics/ContentSweepAnalytics'
import GeneralAnalytics from './screens/Admin/Analytics/GeneralAnalytics'
import Reports from './screens/Admin/Reports/Reporting'
import CreditReports from './screens/Admin/Reports/CreditReports'

export default {
  components: {
    ContentSweepAnalytics,
    GeneralAnalytics,
    Reports,
    PageTitle,
    SweepReports,
    CreditReports,
  },
  computed: {
    tabsList() {
      return [
        {
          name: 'General Analytics',
          value: 'general_analytics',
        },
        {
          name: 'Sweep Analytics',
          value: 'sweep_analytics',
        },
        {
          name: 'Reports',
          value: 'reporting',
        },
        {
          name: 'Sweep Reports',
          value: 'sweep_reports',
        },
        {
          name: 'Credit Reports',
          value: 'credit_reports',
        },
      ]
    },
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get() {
        return this.$route.query.tab || 'general_analytics'
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.analytics-page {
  .v-tabs-items {
    width: 100%;
  }
  .tabs-padding {
    margin-top: 100px;
    padding: 14px;
    position: fixed;
    z-index: 2;
  }
  .analytics-height {
    overflow: auto;
    margin-top: 60px;
  }
}
@media (max-width: 960px) {
  .mobile-padding {
    margin: 0 8px;
  }
  .v-slide-group__wrapper {
    display: inline;
  }
  .content-sweeps-tab {
    padding: 8px 0;
  }
  .analytics-page {
    padding-right: 4px !important;
  }
  .tabs-padding {
    padding: 0 !important;
  }
}
@media (max-width: 600px) {
  .analytics-height {
    margin-top: 90px;
  }
  .analytics-page {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
  .tabs-padding {
    padding: 8px 20px 0 12px !important;
  }
}
</style>
