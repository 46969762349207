<template>
  <v-card class="upcoming-event-card">
    <v-card-text>
      <v-container fluid pa-0>
        <v-row class="ma-0">
          <v-col cols="12" class="pa-0">
            <div class="upcoming-event-details">
              <div class="d-flex justify-space-between w-100">
                <div class="main-content text-truncate pr-4"> {{ sweep.title }} </div>
                <div>
                  <v-btn text class="pa-0" small color="info"
                  @click="gotoDetails(sweep.id)"
                  >
                    View details >
                  </v-btn>
                </div>
              </div>
              <div class="heading"> {{ sweepDate }}</div>
              <div class="heading mb-1">
                {{sweepTime(sweep.fromTime)}} - {{sweepTime(sweep.toTime)}}
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="isInvitation" class="d-flex align-start">
          <v-col class="pb-0">
            <v-btn class='mr-3 btn-rounded-pill btn-purple btn-primary-small' small
              v-on:click ="availabilityCheck === '2'? acceptPopup(sweep.id, '1') :
              $emit('open-invitation-dialog', sweep.id, '1', false)"
            >
              Accept
            </v-btn>
            <v-btn small outlined color="info"
            class="btn-rounded-pill btn-primary-small"
              @click="$emit('open-invitation-dialog', sweep.id, '0', true)"
            >
              Decline
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="sweep-team ma-0" v-else-if="sweepMembers.length">
          <v-col class="pa-0">
            <CreativeTeam
              :creativeTeam="sweepMembers"
              class="pt-2 w-100"
              :vertical="false"
              :avatarSize="34"
            />
          </v-col>
        </v-row>
        <v-row class="my-1" v-else>
          <v-col class="py-0 black--text">
            - No creatives matched yet
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <template v-if="showConfirm">
      <v-dialog
        v-model="showConfirm"
        max-width="680"
      ><v-card class='overflow-hidden' flat>
        <div class="d-flex justify-end">
            <v-icon class="pa-4 pb-0 icon-height" @click="showConfirm = false">
              mdi-close
            </v-icon>
        </div>
        <v-card-text class="pb-1 black--text font-family-2 card-text">
        Looks like your status is set to Inactive.
        Tap on Confirm to set your status to Active and accept this invite.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn text
            @click="showConfirm = false"
            class="py-0"
            color="primaryGray1"
          >
            Cancel
          </v-btn>
            <v-btn text color="info"
            @click="updateUserStatusApi()"
            class="py-0"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </template>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import store from '@/store';
import CreativeTeam from '@/components/dashboard/screens/common/CreativeTeam';

export default {
  name: 'UpcomingEvent',
  props: {
    sweep: {
      type: Object,
    },
    invitationId: {
      type: [String, Number],
      default: '',
    },
    isInvitation: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CreativeTeam,
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    availabilityCheck() {
      return this.userDetails.availability;
    },
    sweepDate() {
      return moment(this.sweep.date).format('MMMM D, YYYY');
    },
    sweepSkill() {
      return (skill) => {
        switch (skill) {
          case '1':
            return 'Photographer';
          case '2':
            return 'Videographer';
          case '3':
            return 'Talent';
          default:
            return 'Field Producer';
        }
      };
    },
    sweepMembers() {
      return _.map(_.reject(this.sweep.sweepTeam, ['creatives', null]), (team) => ({
        profilePic: team.creatives.profilePic,
        smallImage: team.creatives.smallImage,
        thumbnail: team.creatives.thumbnail,
        firstName: team.creatives.firstName,
        lastName: team.creatives.lastName,
        skill: this.sweepSkill(team.skill),
        number: team.creatives.number,
        email: team.creatives.email,
        bio: _.get(team.creatives, 'creativeProfile.bio', ''),
        userPronouns: team.creatives.userPronouns,
        availability: team.creatives.availability,
        role: 'creative',
        userId: team.creatives.id,
        deletedAt: team.creatives.deletedAt,
      }));
    },
    sweepTime() {
      return (time) => moment((time), ['HH:mm']).format('h:mm A');
    },
  },
  data() {
    return {
      showConfirm: false,
      selectedInvitationId: '',
      inviteStatus: '',
    };
  },
  methods: {
    ...mapActions('sweep', ['updateInvitationById']),
    gotoDetails(val) {
      const sweepId = val;
      const { businessId } = this.sweep;
      return this.$router.push({
        name: 'Business Sweep Details',
        params: {
          businessId,
          sweepId,
        },
      });
    },
    acceptPopup(sweepId, status) {
      this.selectedInvitationId = sweepId;
      this.inviteStatus = status;
      this.showConfirm = true;
    },
    async updateUserStatusApi() {
      const response = await this.updateInvitationById({
        status: this.inviteStatus,
        sweepId: this.selectedInvitationId,
      });
      if (response.success) {
        const user = { ...this.userDetails, availability: '1' };
        store.commit('user/SET_USER', user);
        this.showConfirm = false;
        this.$emit('reload-dashboarddata');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-chip {
    height: 20px !important;
    border-radius: 7.5px !important;
}
::v-deep .v-chip__content {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.24px;
  text-align: center;
  color: $neutral6;
}
.font-family-2 {
    font-family: $fontFamily1;
}
.categories {
    margin-top: 15px;
}
.category {
    margin-right: 5px;
}
.upcoming-event-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .heading,
  .action {
    font-size: 14px;
    font-family: $fontFamily1;
    letter-spacing: -0.28px;
    color: $neutral1;
  }
  .main-content {
    font-family: $fontFamily1;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.22px;
    color: $neutral1;
  }
  .action {
    color: $secondary1;
    margin-right: 4px;
  }
}
.sweep-team {
  height: 55px;
  position: relative;
}
</style>
