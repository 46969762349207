<template>
  <div class="card" v-on="$listeners">
    <div class="card-body">
      <v-hover v-slot:default="{ hover }">
        <div class="card-image d-flex justify-center align-center"
          :class="{ 'on-hover': hover, 'card-project': cardType === 'recentProjects'}"
          :id="'card-image-' + item.id"
          >
          <div class="menu" v-show="canShowMenu">
            <v-menu auto :attach="'#card-image-' + item.id">
              <template v-slot:activator="{ on }">
                <v-btn
                  dark
                  icon
                  v-on="on"
                  height="auto"
                  width="auto"
                  v-show="hover"
                  class="menu-btn"
                >
                  <v-icon dense>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="cardType === 'project'">
                  <v-list-item-title>
                    <v-btn text class="w-100" @click="showInfo = true">
                      <span class="pr-2 d-flex"> <Info /> </span> Info
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <template v-if="canAssign">
                <v-list-item v-if="cardType === 'project'">
                  <v-list-item-title>
                    <v-btn text @click="assignToProject(item)">
                      <v-icon small class="pr-2">
                        mdi-plus-circle-outline</v-icon> Add to my projects
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                </template>
                <template v-if="canEdit">
                  <template v-if="cardType === 'project'">
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn text class="w-100"
                        @click="$emit('edit-project')"
                        >
                          <v-icon small class="pr-2">
                            mdi-square-edit-outline</v-icon> Edit
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-else>
                    <!-- <v-list-item>
                      <v-list-item-title>
                        <v-btn text class="w-100"
                        @click= renaming()
                        >
                          <v-icon small class="pr-2">
                            mdi-square-edit-outline</v-icon> Rename
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item> -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn text class="w-100"
                          @click="$emit('edit-kit')"
                        >
                          <v-icon small class="pr-2">
                            mdi-square-edit-outline</v-icon>
                            Edit
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </template>

                  <v-list-item v-if="cardType === 'project'">
                    <v-list-item-title v-if="item.status!=='2'">
                      <v-btn text class="w-100" @click="projectUpdate(item,'2')">
                        <img
                          src="@/assets/svg/theme/archive.svg"
                          class="archive-icon"
                          style="width: 24px; height: 14px"
                        /> Archive
                      </v-btn>
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      <v-btn text class="w-100" @click="projectUpdate(item,'1')">
                        <v-icon small class="pr-2">mdi-delete-restore</v-icon>Active
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item v-else-if="item.status === '1'">
                    <v-btn text class="w-100" @click="updateKitStatus(item, item.status)">
                      <template>
                        <v-icon small class="pr-2"> mdi-check-bold </v-icon>
                        Mark as completed
                      </template> -->
                      <!-- <template v-else>
                        <v-icon small class="pr-2"> mdi-progress-check </v-icon>
                        Mark as active
                      </template> -->
                    <!-- </v-btn>
                  </v-list-item> -->
                </template>
              </v-list>
            </v-menu>
          </div>
          <img
            :src="cardImage"
            alt="no-image"
            id="img"
            :class="{'default-image': !validImage}"
          />
        </div>
      </v-hover>
      <div
        class='card-info'
        v-if="cardType === 'project' && showInfo"
        v-click-outside="onClickOutsideInfo"
      >
        <v-card tile flat outlined>
          <v-card-title  class='pt-2 pb-0 pl-2 pr-0 text-capitalize notranslate'>
            {{item.name}} <v-spacer></v-spacer>
            <v-icon small class="pr-1" @click="showInfo = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider class="ml-2 mr-6"> </v-divider>
          <v-card-text class="pa-2 font-weight-medium black--text">
            <v-row class="ma-0">
              <v-col cols=5> Date: </v-col>
              <v-col cols=7>{{item.date}}</v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols=5> Customer: </v-col>
              <v-col cols=7 class="name notranslate">{{clientName}}</v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols=5> Blend: </v-col>
              <v-col cols=7 class="notranslate">{{projectBlend}}</v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols=5> CP: </v-col>
              <v-col cols=7 class="name notranslate">{{producers}}</v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols=5> PM: </v-col>
              <v-col cols=7 class="name notranslate">{{executives}}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div class="card-name text-center row ma-0" id="card-name" @mouseup="checkStatus()">
      <BaseInput
        :mode="rename"
        v-model="itemName"
        :hide-details="true"
        :clickOutsideCallback="updateName"
        @keydown.enter="updateName"
        @inputActive="inputStatus"
        autofocus
      >
        <div class="title text-capitalize notranslate project-title" id="project-title"
        v-if="cardType !== 'recentProjects'"> {{projectName()}} </div>
        <div v-else class="project-title notranslate" > {{projectName()}} </div>
      </BaseInput>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import vClickOutside from 'v-click-outside';
import { mapActions } from 'vuex';
import { lookup } from 'mime-types';
import { getOptimizedS3ImageURL } from '@/helpers/';
import Info from '@/components/common/svg/Info';
import BaseInput from './BaseInput';

export default {
  /* eslint-disable global-require */
  name: 'BaseCard',
  components: {
    BaseInput,
    Info,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    cardType: {
      type: String,
      default: 'project',
    },
    item: {
      type: Object,
      required: true,
    },
    hasInfo: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    onArchive: {
      type: Function,
      default: () => {},
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canAssign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeMenu: false,
      showInfo: false,
      rename: 'normal',
      itemName: '',
      clickedInBox: false,
      inputActive: false,
    };
  },
  computed: {
    canShowMenu() {
      if (this.cardType === 'project') {
        return true;
      }
      return this.canEdit;
    },
    validImage() {
      return this.itemImage && this.isFileImage(lookup(this.itemImage));
    },
    cardImage() {
      return this.validImage ? getOptimizedS3ImageURL(
        this.itemImage,
        { width: 500, height: 500 },
      ) : this.defaultImage;
    },
    defaultImage() {
      return require('@/assets/svg/theme/project-banner-default.svg');
    },
    cardImage2() {
      return this.itemImage || require('@/assets/svg/theme/project-banner-default.svg');
    },
    itemImage() {
      if (this.cardType === 'contentKit') {
        return this.item.bannerImage
          || _.get(this.item.contentKitFiles, '[0].smallImage')
          || _.get(this.item.contentKitFiles, '[0].poster')
          || _.get(this.item.contentKitFiles, '[0].file');
      }
      if (this.cardType === 'project') {
        return _.get(this.item, 'bannerImage') || _.get(this.item, 'business.bannerImage');
      }
      return this.item.bannerImage;
    },
    clientName() {
      return _.get(this.item, 'business.name', '');
      // return `${client.firstName} ${client.lastName}`;
    },
    projectBlend() {
      return _.get(this.item, 'business.subscriptionBlends[0].name', '');
    },
    producers() {
      const producerNames = _.map(this.item.producers, (object) => `${_.get(object, 'firstName')} ${_.get(object, 'lastName')}`);
      return producerNames.join(', ');
    },
    executives() {
      const executiveNames = _.map(this.item.executives, (object) => `${_.get(object, 'firstName')} ${_.get(object, 'lastName')}`);
      return executiveNames.join(', ');
    },
  },
  mounted() {
    this.itemName = this.item.name;
  },
  methods: {
    ...mapActions('contentKit', ['updateContentKitStatus']),
    ...mapActions('project', ['updateProject', 'assignProject']),
    projectName() {
      const displayName = this.item.name;
      return displayName;
    },
    isFileImage(format) {
      return format && ['jpg', 'png', 'jpeg', 'gif', 'svg'].includes(format.split('/')[1]);
    },
    checkStatus() {
      this.clickedInBox = true;
    },
    inputStatus(status) {
      this.inputActive = status;
    },

    updateName(event) {
      if (this.inputActive && this.clickedInBox && event.code !== 'Enter') {
        return;
      }
      if (this.nameChanged()) {
        this.$emit('update', { id: this.item.id, name: this.itemName });
      }
      this.$emit('renaming', false);
      this.rename = 'normal';
    },
    toggleMenu() {
      this.activeMenu = !this.activeMenu;
    },
    onClickOutsideInfo() {
      this.showInfo = false;
    },
    assignToProject(item) {
      this.assignProject({
        id: item.id,
        status: item.status,
      });
    },
    projectUpdate(item, status) {
      this.updateProject({
        id: item.id,
        project: Object.assign(item, { status }),
        status: status === '2' ? '1' : '2',
      });
    },
    updateKitStatus(item, status) {
      this.updateContentKitStatus({
        id: item.id,
        status: status === '1' ? '0' : '1',
      });
    },
    renaming() {
      this.rename = 'edit';
      this.$emit('renaming', true);
    },
    nameChanged() {
      if (this.itemName.length) {
        return this.item.name !== this.itemName;
      }
      this.itemName = this.item.name;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .card {
    background-color: white;
    box-shadow: 0 2px 4px 0 $silver;
    .card-image {
      background: $neutral5;
      height: 140px;
    }
    .card-body{
      position: relative;
      .card-info {
        box-shadow: 0 2px 2px 0 $silver;
        font-family: $fontFamily1;
        max-height: 110%;
        min-width: 75%;
        overflow-y: auto;
        position: absolute;
        right: 5%;
        top: 20%;
        .v-card__title, .v-card__text {
          font-size: 12px !important;
        }
        .v-card__title {
          line-height: normal;
        }
        .col-5, .col-7 {
          padding: 0;
        }
        .col-7 {
          padding-left: 5px;
        }
        .name {
          font-weight: normal;
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      &:hover {
        cursor:pointer;
      }
      .menu {
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 7;
        .menu-btn {
          background: rgba(0, 0, 0, 0.2);
        }

      }
      img {
        height: 100%;
        width: 100%;
        object-fit:contain;
      }
      img.default-image {
        width: 64px !important;
        height: 64px !important;
      }
    }
    .card-name {
      font-size: 16px;
      font-weight: bold;
      justify-content: center;
      padding: 18px 8px;
      &:hover{
        cursor:pointer;
      }
      .title {
        cursor: pointer;
        font-family: $fontFamily1 !important;
        font-size: 16px !important;
        font-weight: bold;
        letter-spacing: -0.12px !important;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &:hover {
          color: $primary3;
        }
      }
    }
  }
  .v-list {
    border-radius: 0;
    padding: 0;
  }
  .v-menu__content {
    right: 5px !important;
    left: auto !important;
    top: 30px !important;
    border-radius: 0;
    margin-right: 15px;
  }
  .v-menu__content:hover {
    .card {
      opacity: 0.5;
    }
    .menu-icon {
      display: block;
    }
  }
  .v-list-item {
    min-height: auto;
    padding: 0;
    .v-btn {
      height: 30px;
      :hover::before {
        opacity: 0.9 !important;
      }
    }
    ::v-deep {
      .v-btn__content {
        font-size: 14px !important;
        font-family: $fontFamily1;
        justify-content: flex-start;
        width: auto;
        .archive-icon {
          padding-right: 5px;
        }
      }
      .v-btn {
        padding-right: 50px;
      }
    }
  }
  .v-card {
    border-radius: 0 !important;
  }
  .card-project{
      height: 94px !important;
      position: relative;
    }
    .project-title{
      word-break: break-all;
      font-size: 14px;
      font-weight: normal;
      font-family:$fontFamily1 !important;
      letter-spacing: -0.11px;
      display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow:hidden;
        cursor: pointer;
        &:hover {
          color: $secondary1;
        }
    }

  ::v-deep .v-input {
    height: auto;
    margin: 0 !important;
    padding: 0 !important;
    input {
      font-weight: 400;
      padding-bottom: 4px;
      padding-top: 0;
    }
  }
  .menu-icon {
    display: none;
  }
  .card-image {
    opacity: 0.7;
  }
  .card-image:not(.on-hover) {
    opacity: 1;
  }
</style>
