<!-- eslint-disable max-len -->
<template>
  <v-container class="pa-0 main-container h-100">
    <Loader v-if="!loaded" />
    <v-row v-else
      class="ma-auto"
      :style="{
        'padding':$vuetify.breakpoint.smAndDown
          || $vuetify.breakpoint.xsAndDown ? '0px' : '40px 0px 0px 40px'}">
      <v-col class="welcome-conatiner">
        <!-- <div class="pb-10"><PageTitle /></div> -->
        <div class="welcome-text font-h1 mb-4">{{ welcomeText }}</div>
        <div class="welcome-description">
          Ready, set, create! Your digital studio awaits.
        </div>
        <v-row class="ma-auto mt-10">
          <v-col
            cols="12" xs="12" sm="12" md="7" lg="8" xl="7" xxl="7" class="left-col">
            <div class="tasks" v-if="getAccessborder || taskState.showBrandQuiz">
              <p class="section-title font-h6 mb-0">QUICK START TASKS</p>
              <div class="description">
                <div v-if="taskState.showBrandQuiz">
                  <v-card-text
                    :style="{
                      'border-bottom': getAccessborder ?
                          '1px solid #c5c5c5' : ''}"
                    class="d-flex flex-column client-success-board pl-0 pr-0 pt-4 pb-4">
                    <div
                      class="d-flex justify-space-between"
                      :class="$vuetify.breakpoint.smAndDown && 'flex-wrap'"
                    >
                      <div class="text-div">
                        <div class="text font-h4">
                          Complete Brand Profile
                        </div>
                        <div class="sub-text font-body">
                          Fill out your brand quick (5-10 Minutes). This is an important part of the
                          process to ensure creative and brand success with every asset.
                          <!-- This “quiz” gives our team more insight into who and what your brand is.
                          Plus it gives you a place to keep all your brand files for anyone on your
                          team to access! -->
                        </div>
                      </div>
                      <div class="task-btn">
                        <v-btn class="btn-purple" @click="openModal()">
                          Take Quiz
                        </v-btn>
                      </div>
                    </div>
                  </v-card-text>
                </div>
                <template v-if="taskState.pendingOrders">
                  <div v-for="order in pendingOrders" :key="order.id">
                    <v-card-text
                      :style="{
                      'border-bottom': taskState.showOrderAssets || taskState.findingProducer
                        || taskState.scheduleCVCall || taskState.goToLatestContentKit
                          || taskState.reUpCall ?
                          '1px solid #c5c5c5' : ''}"
                      class="d-flex flex-column client-success-board pl-0 pr-0 pt-4 pb-4">
                      <div
                        class="d-flex justify-space-between"
                        :class="$vuetify.breakpoint.smAndDown && 'flex-wrap'"
                      >
                        <div class="text-div">
                          <div class="text font-h4">Confirm Order</div>
                          <div class="sub-text font-body">
                            <span>
                              Please confirm the deliverables for your recent
                              <b>{{ `order #${order.id}` }}</b
                              >.</span
                            ><br />
                            As soon as you give it a go, we’ll get started right away.
                          </div>
                        </div>
                        <div class="task-btn">
                          <v-btn
                            class="btn-purple"
                            @click="
                              $router.push({
                                name: 'Order',
                                params: { orderId: order.id },
                                query: { orderId: order.id },
                              })
                            "
                          >
                            View Order
                          </v-btn>
                        </div>
                      </div>
                    </v-card-text>
                  </div>
                </template>
                <div v-if="taskState.showOrderAssets">
                  <v-card-text
                    :style="{
                      'border-bottom': taskState.findingProducer
                        || taskState.scheduleCVCall || taskState.goToLatestContentKit
                          || taskState.reUpCall ?
                          '1px solid #c5c5c5' : ''}"
                    class="d-flex flex-column client-success-board pl-0 pr-0 pt-4 pb-4">
                    <div
                      class="d-flex justify-space-between"
                      :class="$vuetify.breakpoint.smAndDown && 'flex-wrap'"
                    >
                      <div class="text-div">
                        <div class="text font-h4">Place your first order</div>
                        <div class="sub-text font-body">
                          Get Started with blended sense by placing your first
                          order. We'll match you with a local creative and
                          implement beautiful digital assets.
                        </div>
                      </div>
                      <div class="task-btn">
                        <v-btn
                          class="btn-purple"
                          @click="openCreateOrderModal"
                        >
                          Place Order
                        </v-btn>
                      </div>
                    </div>
                  </v-card-text>
                </div>
                <div v-if="taskState.findingProducer">
                  <v-card-text
                    :style="{
                      'border-bottom':
                        taskState.scheduleCVCall || taskState.goToLatestContentKit
                          || taskState.reUpCall ?
                          '1px solid #c5c5c5' : ''}"
                    class="d-flex flex-column client-success-board pl-0 pr-0 pt-4 pb-4">
                    <div
                      class="d-flex justify-space-between"
                      :class="$vuetify.breakpoint.smAndDown && 'flex-wrap'"
                    >
                      <div class="text-div">
                        <div class="text font-h4">
                          We're finding the best content producer for you
                        </div>
                        <div class="sub-text font-body">
                          Based on the answers you provided in your signup process and the
                          subscription plan you selected, we're finding the perfect content producer
                          for you.
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </div>
                <div v-if="taskState.scheduleCVCall">
                  <v-card-text
                    :style="{
                      'border-bottom': taskState.goToLatestContentKit
                          || taskState.reUpCall ?
                          '1px solid #c5c5c5' : ''}"
                    class="d-flex flex-column client-success-board pl-0 pr-0 pt-4 pb-4">
                    <div
                      class="d-flex justify-space-between"
                      :class="$vuetify.breakpoint.smAndDown && 'flex-wrap'"
                    >
                      <div class="text-div">
                        <div class="text font-h4">
                          Schedule your CV call
                        </div>
                        <div class="sub-text font-body">
                          Your Creative Vision aka “CV” call is a time to meet and create a content
                          vision with your dedicated Content Producer.
                        </div>
                      </div>
                      <div class="task-btn">
                        <v-btn class="btn-purple" @click="handleScheduleCall">
                          Schedule Call
                        </v-btn>
                      </div>
                    </div>
                  </v-card-text>
                </div>
                <div v-if="taskState.goToLatestContentKit">
                  <v-card-text
                    :style="{
                      'border-bottom': taskState.reUpCall ?
                          '1px solid #c5c5c5' : ''}"
                    class="d-flex flex-column client-success-board pl-0 pr-0 pt-4 pb-4">
                    <div
                      class="d-flex justify-space-between"
                      :class="$vuetify.breakpoint.smAndDown && 'flex-wrap'"
                    >
                      <div class="text-div">
                        <div class="text font-h4">Go to latest Content Kit</div>
                        <div class="sub-text font-body">
                          Check out what your Creative Team produced for your most recent Content
                          Kit.
                        </div>
                      </div>
                      <div class="task-btn">
                        <v-btn class="btn-purple" @click="onContentKitClick(latestContentKit.id)">
                          Go to Asset Library
                        </v-btn>
                      </div>
                    </div>
                  </v-card-text>
                </div>
                <div v-if="taskState.reUpCall">
                  <v-card-text class="d-flex flex-column client-success-board pl-0 pr-0 pt-4 pb-4">
                    <div
                      class="d-flex justify-space-between"
                      :class="$vuetify.breakpoint.smAndDown && 'flex-wrap'"
                    >
                      <div class="text-div">
                        <div class="text font-h4">Schedule Re Up Call</div>
                        <div class="sub-text font-body">
                          Schedule your next monthly Creative Vision ‘Re Up’ call with your Content
                          Producer.
                        </div>
                      </div>
                      <div class="task-btn">
                        <v-btn
                          class="btn-purple"
                          @click="handleScheduleCall({ contactType: 'Re-up call' })"
                        >
                          Schedule Call
                        </v-btn>
                      </div>
                    </div>
                  </v-card-text>
                </div>
                <!-- <div
                  v-if="
                    (client &&
                      projects > 0 &&
                      enableQuizCard &&
                      callScheduled &&
                      !reUpCall &&
                      !taskState.pendingOrders) ||
                      (!client && enableQuizCard && !latestContentKit && !taskState.pendingOrders)
                  "
                  class="d-flex flex-column justify-center align-center"
                >
                  <div style="width: 250px; height: 280px;">
                    <img src="@/assets/svg/noTask.svg" />
                  </div>
                  <div class="no-task">No new tasks... check back <br />later for an update!</div>
                </div> -->
              </div>
            </div>
            <div
              :style="{
                'margin-top': getAccessborder || taskState.showBrandQuiz ? '50px' : '0px'
              }"
              class="events-heading">
              <v-row class="ma-0 mb-3" style="border-bottom: 1px solid #dedede;">
                  <v-col class="events-title pa-0">
                  Upcoming Events
                  </v-col>
                  <!-- @click="purchaseCredits()" -->
                  <div
                  @click="$router.push('/sweeps');"
                  class="add-payment-btn d-flex justify-end pt-3">
                  View All Sweeps
                  </div>
              </v-row>
              <span v-if="upcomingsweeps.length !== 0" :key="upcomingsweeps.length">
                <div
                  v-for="item in upcomingsweeps" :key="item.id">
                  <UpcomingSweeps
                    :item="item"
                  />
                </div>
              </span>
              <v-row class="ma-0 no-history" v-else>
            <v-col class="d-flex flex-column justify-center align-center pt-12">
                <v-img
                  width="2rem"
                  src="@/assets/svg/no_active_orders.svg"
                  aspect-ratio="1"
                ></v-img>
                <div style="height: 100px;">No Upcoming Sweep</div>
              </v-col>
            </v-row>
            </div>
            <div
              class="events-heading mt-8">
              <v-row class="ma-0 mb-3" style="border-bottom: 1px solid #dedede;">
                  <v-col class="events-title pa-0">
                  Active Orders
                  </v-col>
                  <!-- @click="purchaseCredits()" -->
                  <div
                  @click="$router.push('/orders');"
                  class="add-payment-btn d-flex justify-end pt-3">
                  View All Orders
                  </div>
              </v-row>
              <div v-for="order in orders"
                :key="order">
              <ClientOrder
                v-if="order.status === 'ACTIVE'"
                :order="order"
                :key="order.key"
                @editOrder="() => openEditModel(order.id)"
                @showPreview="onShowPreview"
                />
              </div>
              <v-row class="ma-0 no-history" v-if="!orderActive">
            <v-col class="d-flex flex-column justify-center align-center pt-12">
                <v-img
                  width="2rem"
                  src="@/assets/svg/no_active_orders.svg"
                  aspect-ratio="1"
                ></v-img>
                <div style="height: 100px;">No Acive Orders</div>
              </v-col>
            </v-row>
              <!-- <v-row class="ma-0 no-history" v-else>
            <v-col class="d-flex flex-column justify-center align-center pt-12">
                <v-img
                  width="2rem"
                  src="@/assets/svg/no_active_orders.svg"
                  aspect-ratio="1"
                ></v-img>
                <div style="height: 100px;">No Upcoming Sweep</div>
              </v-col>
            </v-row> -->
            </div>
          </v-col>
          <v-col
            md="3"
            xs="12"
            sm="12"
            lg="4"
            xl="5"
            xxl="5"
            :class="$vuetify.breakpoint.lgAndUp ? 'justify-end' : 'justify-baseline'"
            class="right-col"
            :style="`padding-left: ${$vuetify.breakpoint.smAndDown ? '0px' : '55px'}`"
          >
            <div>
              <PlaceOrder>
              </PlaceOrder>
              <CreditBank
                :cardDetails="cardDetails"
                @reload-cards="reloadCardsList"
              ></CreditBank>
              <!-- <p class="section-title font-h3">
                {{ $vuetify.breakpoint.smAndDown ? 'Reminders' : 'Upcoming Events' }}
              </p>
              <div class="description">
                <div v-if="groupedEvents.length > 0" style="max-height:340px;overflow-y:auto;">
                  <div
                    class="event event-group"
                    v-for="eventGroup in groupedEvents"
                    :key="eventGroup.date"
                  >
                    <div class="date font-label">{{ eventGroup.displayTitle }}</div>
                    <div
                      class="d-flex align-start event-div"
                      :class="{ 'cursor-pointer': event.type === 'sweep' }"
                      @click="goToEvent(event.id, event.type)"
                      v-for="(event, j) in eventGroup.events"
                      :key="j"
                    >
                      <img :src="eventIcon(event.type)" class="event-icon" />
                      <div>
                        <div class="mr-3">
                          <div class="name font-h4">{{ event.name }}</div>
                          <div class="desc font-body">
                            {{ getDisplayTime(event.fullStartTime) }} -
                            {{ getDisplayTime(event.fullEndTime) }}
                          </div>
                          <div
                            class="event-btn"
                            v-if="event.type === 'call' && event.join_url !== null"
                          >
                            <v-btn @click="joinCall(event)">Join Google Meet Meeting</v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column justify-center align-center" v-else>
                  <div style="width: 48px; height: 61px; text-align: center;">
                    <img src="@/assets/svg/no-events.svg" />
                  </div>
                  <div class="no-task">
                    No reminders yet... check back <br />later for an update!
                  </div>
                </div>
              </div> -->
            </div>
          </v-col>
        </v-row>
        <!-- <v-row class="ma-auto" style="margin-top: 86px !important;">
          <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7" xxl="7" class="left-col">
            <div class="questions" :key="questions.length" >
              <p class="section-title font-h3">Frequently Asked Questions</p>
              <v-expansion-panels
                class="description que-description"
                v-for="(item, i) in questions"
                :key="i"
              >
                <v-expansion-panel class="que-div">
                  <v-expansion-panel-header class="text font-h4">
                    {{ item.question }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="font-body expansion-panel-sub-text">
                    {{ item.answer }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div
            v-if="$vuetify.breakpoint.mdAndUp"
              style="margin-top: 70px;"
              class="left-col"
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="7"
              xl="7"
              xxl="7"
            >
              <div class="resources">
                <p class="section-title font-h3">Resources</p>
                <div class="description">
                  <v-row
                    class="d-flex ma-auto"
                    :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-col
                      class="d-flex align-start resource"
                      :class="`resource${i + 1}`"
                      v-for="(resource, i) in resources.resource1"
                      :key="i"
                    >
                      <img
                        :src="resource.icon"
                        style="width:28px; height: 32px; margin-right: 18px;"
                      />
                      <div>
                        <div class="name font-h4">{{ resource.name }}</div>
                        <div class="desc font-body">{{ resource.description }}</div>
                        <a
                          :href="
                            resource.linkName === 'Contact Support'
                              ? `mailto:${resource.link}`
                              : resource.link
                          "
                          target="_blank"
                          class="linkName"
                          >{{ resource.linkName }}</a
                        >
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    class="d-flex ma-auto"
                    :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-col
                      class="d-flex align-start resource"
                      :class="`resource${i + 1}`"
                      v-for="(resource, i) in resources.resource2"
                      :key="i"
                    >
                      <img
                        :src="resource.icon"
                        style="width:28px; height: 32px; margin-right: 18px;"
                      />
                      <div>
                        <div class="name font-h4">{{ resource.name }}</div>
                        <div class="desc font-body">{{ resource.description }}</div>
                        <a :href="resource.link" target="_blank" class="linkName">{{
                          resource.linkName
                        }}</a>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            v-if="
              (producerCallInfo && producerCallInfo.email) ||
                getSortedCreativeTeam(creativesTeam()).length
            "
            cols="12"
            xs="12"
            sm="12"
            md="5"
            lg="5"
            xl="5"
            xxl="5"
            class="right-col"
            style="order: 1;"
            :style="`padding-left: ${$vuetify.breakpoint.smAndDown ? '0px' : '60px'}`"
          >
            <div class="creative-team">
              <div class="cpCard">
                <p class="section-title font-h3">Your Team</p>
                <div v-if="producerCallInfo && producerCallInfo.email">
                  <div class="sub-title font-label">{{ producerCallInfo.representive }}</div>
                  <div class="d-flex description">
                    <div class="producer-contact-img">
                      <AvatarRound
                        :size="72"
                        :avatar="producerCallInfo.avatar"
                        :name="memberInitials(producerCallInfo)"
                        @openPopup="showProducerPopup(producerCallInfo)"
                      />
                      <div style="position: relative;">
                        <img :src="getProfileIcon('Content Producer').icon" class="profile-icon" />
                      </div>
                    </div>
                    <div>
                      <div class="name font-h5">{{ producerCallInfo.displayName }}</div>
                      <div class="contact-details">
                        <div class="contact">
                          <a
                            class="text-wrap-all a-link"
                            :href="`mailto:${producerCallInfo.email}`"
                            >{{ producerCallInfo.email }}</a
                          >
                        </div>
                        <div class="contact">
                          <a
                            class="text-wrap a-link"
                            @click="handleScheduleCall({ scheduleCallSource: 'producer' })"
                          >
                            Schedule a meeting
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="creative-team-data mt-3"
                v-if="getSortedCreativeTeam(creativesTeam()).length"
              >
                <div class="sub-title mb-4 font-label">Creatives</div>
                <div class="d-flex flex-wrap creative-data" :key="creativesTeam().length">
                  <div
                    class="producer-contact-img2 producer-img"
                    v-for="(member, i) in getSortedCreativeTeam(creativesTeam())"
                    :key="i"
                  >
                    <v-tooltip left bottom :disabled="!$vuetify.breakpoint.mdAndUp">
                      <template v-slot:activator="{ on, attrs }" class="ma-0">
                        <div v-bind="attrs" v-on="on">
                          <AvatarRound
                            :size="72"
                            :avatar="getMemberProfile(member)"
                            :name="memberInitials(member)"
                            @openPopup="showPopup(i)"
                          />
                          <div style="position: relative;">
                            <img :src="getProfileIcon(member.skill).icon" class="profile-icon2" />
                          </div>
                        </div>
                      </template>
                      <span class="notranslate"> {{ memberName(member) }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.smAndDown">
          <v-col
              style="margin-top: 70px;"
              class="left-col"
              cols="12"
            >
              <div class="resources">
                <p class="section-title font-h3">Resources</p>
                <div class="description">
                  <v-row
                    class="d-flex ma-auto"
                    :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-col
                      class="d-flex align-start resource"
                      :class="`resource${i + 1}`"
                      v-for="(resource, i) in resources.resource1"
                      :key="i"
                    >
                      <img
                        :src="resource.icon"
                        style="width:28px; height: 32px; margin-right: 18px;"
                      />
                      <div>
                        <div class="name font-h4">{{ resource.name }}</div>
                        <div class="desc font-body">{{ resource.description }}</div>
                        <a
                          :href="
                            resource.linkName === 'Contact Support'
                              ? `mailto:${resource.link}`
                              : resource.link
                          "
                          target="_blank"
                          class="linkName"
                          >{{ resource.linkName }}</a
                        >
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    class="d-flex ma-auto"
                    :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-col
                      class="d-flex align-start resource"
                      :class="`resource${i + 1}`"
                      v-for="(resource, i) in resources.resource2"
                      :key="i"
                    >
                      <img
                        :src="resource.icon"
                        style="width:28px; height: 32px; margin-right: 18px;"
                      />
                      <div>
                        <div class="name font-h4">{{ resource.name }}</div>
                        <div class="desc font-body">{{ resource.description }}</div>
                        <a :href="resource.link" target="_blank" class="linkName">{{
                          resource.linkName
                        }}</a>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
        </v-row> -->
      </v-col>
    </v-row>
    <!-- <template v-if="showScheduleCallModal"> -->
    <v-dialog v-model="showScheduleCallModal" max-width="1280px" persistent>
      <v-card
        style="border-radius: unset !important;"
        class="d-flex flex-column pa-4"
        height="90vh"
        max-height="1080px"
      >
        <div class="d-flex mb-2">
          <v-card-title class="pt-2 pb-0 pl-2 pr-0 text-capitalize modal-header-title">
            SCHEDULE A CALL
          </v-card-title>
          <v-spacer></v-spacer>
          <div class="close-btn" @click="() => toggleScheduleCallModal(false)">
            <v-icon class="pr-1 cursor-pointer">
              mdi-close
            </v-icon>
          </div>
        </div>
        <div class="bs_calendly_wrapper d-flex flex-1" style="height:100%">
          <vue-calendly
            :key="calendlyKey"
            :url="producerCallInfo && appendInviteeParams(producerCallInfo.callLink)"
            ref="vueCalendly"
            style="flex:1;min-width:320px; height:100%;"
            data-processed="true"
            @load="calendlyLoading = false"
          >
          </vue-calendly>
          <div
            v-if="producerCallInfo && !producerCallInfo.isValidLink && !calendlyLoading"
            class="bs_calendly_fallback
              flex-1 d-flex align-center justify-center"
          >
            Calendly link is invalid. Please contact support.
          </div>
          <div
            class="bs_calendly_loader"
            v-if="producerCallInfo && producerCallInfo.isValidLink && calendlyLoading"
          >
            <Loader />
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- </template> -->
    <BrandModal
      :modalInitialStep="modalCurrentState"
      :isModal="showBrandQuizModal"
      @closeModal="closeModal"
      :brandProfileId="brandProfile.id"
      :canEdit="checkEditAccess"
    />
    <view-profile-modal
      :profilePic="profilePic(selectedMember, 'profilePic')"
      :imageBackground="checkProfile(selectedMember.profilePic)"
      :name="`${selectedMember.firstName || ''} ${selectedMember.lastName || ''}`"
      :skillset="selectedMember.skill"
      :viewProfileModal="showDetails"
      :selectedMember="selectedMember"
      @setViewProfileModal="showDetails = false"
      @modalOpen="modalOpen = true"
      parent="creativeTeam"
    />
    <!-- <div v-if="showOrderModal">
      <OrderForm
        :open="showOrderModal"
        @close="closeCreateOrderModal"
        :orderId="orderToEdit.id"
      />
    </div> -->
    <v-container fluid v-if="modalOpen" class="modal">
      <Modal
        :modal="modalOpen"
        persistent
        fullscreen
        content-class="preview-modal full-screen"
      >
        <v-card elevation="0">
          <v-card-text class="d-flex justify-end">
            <v-btn
              icon
              width="23"
              height="23"
              color="black"
              class="close-icon"
              @click="modalOpen = false"
            >
              <v-icon size="14">mdi-close</v-icon>
            </v-btn>
          </v-card-text>
          <v-card-text>
            <v-container fluid>
              <Profile :profileId="selectedMember.userId" />
            </v-container>
          </v-card-text>
        </v-card>
      </Modal>
    </v-container>
  </v-container>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import BrandModal from '@/components/profile/client/brandQuiz/Layout';
import { getOptimizedS3ImageURL } from '@/helpers/';
import questionsJson from '@/components/dashboard/ClientDashboardQue.json';
import ViewProfileModal from '@/components/common/ViewProfileModal';
import Profile from '@/components/profile/creative/ProfileView';
import Modal from '@/components/common/Modal';
import Loader from '@/components/common/Loader';
// import AvatarRound from '@/components/common/Avatar';
// import PageTitle from '@/components/common/PageTitle';
// import OrderForm from '@/components/orders/OrderForm';
import CreditBank from '@/components/credits/CreditBank';
import PlaceOrder from '@/components/orders/PlaceOrder';
import UpcomingSweeps from '@/components/sweeps/UpcomingSweeps';
import ClientOrder from '@/components/orders/ClientOrder';

export default {
  name: 'WelcomeDashboard',
  components: {
    BrandModal,
    ViewProfileModal,
    Profile,
    Modal,
    Loader,
    // AvatarRound,
    // PageTitle,
    // OrderForm,
    CreditBank,
    PlaceOrder,
    UpcomingSweeps,
    ClientOrder,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails', 'events']),
    ...mapGetters('business', ['business']),
    ...mapGetters('brandProfile', ['brandProfile']),
    ...mapGetters('profile', { profile: 'clientProfile' }),
    ...mapGetters('dashboard', ['project', 'recentContentKits']),
    ...mapGetters('orders', ['orders', 'pendingOrders']),
    getAccessborder() {
      if (this.taskState.goToLatestContentKit || this.taskState.scheduleCVCall
        || this.taskState.pendingOrders || this.taskState.showOrderAssets
          || this.taskState.findingProducer || this.taskState.reUpCall) {
        return true;
      }
      return false;
    },
    isAvatarExists() {
      return (member) => _.get(member.creatives || member, 'profilePic', '');
    },
    memberInitials() {
      return (member) => {
        const firstName = _.get(member.creatives || member, 'firstName', '').trim();
        const lastName = _.get(member.creatives || member, 'lastName', '').trim();
        let name = `${firstName} ${lastName}`;
        name = name.trim() || _.get(member, 'displayName', '').trim();
        if (name) {
          if (name.indexOf(' ') > 0) {
            const first = name.split(' ');
            name = first[0].charAt(0) + first[1].charAt(0);
          } else {
            name = name.charAt(0) + name.charAt(1);
          }
        }
        return name;
      };
    },
    taskState() {
      // const blends = _.get(this.project, 'business.subscriptionBlends', []);
      const hasContentProducers = Boolean(this.contentProducers.length);
      return {
        showOrderAssets: !this.orderActive,
        showBrandQuiz: !this.enableQuizCard,
        scheduleCVCall: this.client && !this.callScheduled && hasContentProducers,
        goToLatestContentKit: this.latestContentKit,
        reUpCall: this.client && this.latestContentKit,
        // eslint-disable-next-line max-len
        findingProducer: false,
        // this.client && !hasContentProducers && !this.callScheduled && blends.length > 0,
        pendingOrders:
          ['client', 'agency_owner', 'member', 'agency_member'].includes(this.role)
          && this.pendingOrders.length > 0,
      };
    },
    memberName() {
      return (member) => {
        const firstName = _.get(member, 'firstName', '') || _.get(member.creatives, 'firstName', '');
        const lastName = _.get(member, 'lastName', '') || _.get(member.creatives, 'lastName', '');
        return `${firstName} ${lastName}`;
      };
    },
    checkEditAccess() {
      return this.canEditBrandProfile;
    },
    creativeSkillsets() {
      return (member) => {
        const skillsets = _.get(member, 'creativeProfile.skillsets', []);
        return skillsets.map((x) => (x.name === 'Other' ? x.skillSetName : x.name)).join(', ');
      };
    },
    projectTeamDetails() {
      return (users, userRole) => _.map(users, (user) => ({
        profilePic: user.profilePic,
        smallImage: user.smallImage,
        thumbnail: user.thumbnail,
        firstName: user.firstName,
        lastName: user.lastName,
        skill: userRole === 'creative' ? this.creativeSkillsets(user) : userRole,
        number: user.number,
        email: user.email,
        bio: user.bio,
        userPronouns: user.userPronouns,
        userId: user.id,
        availability: user.availability,
        role: user.role.name,
        deletedAt: user.deletedAt,
      }));
    },
    producersTeam() {
      return this.projectTeamDetails(
        _.map(_.filter(this.projectTeam.producersTeam, ['accepted', '1']), 'user'),
        'Content Producer',
      );
    },
    executivesTeam() {
      return this.projectTeamDetails(
        _.map(_.filter(this.projectTeam.executiveTeam, ['accepted', '1']), 'user'),
        'Production Manager',
      );
    },
    creativeTeam() {
      return this.projectTeamDetails(
        _.map(_.filter(this.projectTeam.creativeTeam, ['accepted', '1']), 'user'),
        'creative',
      );
    },
    editorTeam() {
      return this.projectTeamDetails(
        _.map(_.filter(this.projectTeam.editorTeam, ['accepted', '1']), 'user'),
        'editor',
      );
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    client() {
      return ['client', 'agency_owner'].includes(this.role);
    },
    questions() {
      const {
        contentKitDelivered,
        postFirstSweep,
        postCvCall,
        cpMatched,
        beforeOrderAssets,
      } = questionsJson.questions;
      let questions;
      const blends = _.get(this.project, 'business.subscriptionBlends', []);
      const hasContentProducers = Boolean(this.contentProducers.length);
      if (this.latestContentKit) {
        questions = contentKitDelivered;
      } else if (this.firstSweep) {
        questions = postFirstSweep;
      } else if (this.projects > 0 && this.callScheduled) {
        questions = postCvCall;
      } else if (blends.length > 0 && hasContentProducers) {
        // blend is created after purchase. and has content producers.
        questions = cpMatched;
      } else {
        questions = beforeOrderAssets;
      }
      return questions;
    },
    resources() {
      const resource = {
        resource1: [
          {
            name: 'Technical Support',
            description: 'Have questions? Reach out to your producer directly.',
            // eslint-disable-next-line global-require
            icon: require('@/assets/svg/support.svg'),
            link: 'support@blendedsense.com',
            linkName: 'Contact Support',
          },
          {
            name: 'Videos & Tutorials',
            description: 'Check out our YouTube channel for tips and tricks.',
            // eslint-disable-next-line global-require
            icon: require('@/assets/svg/tutorial.svg'),
            link: 'http://youtube.com/channel/UCqrj6OYDNzdjTZ71XBrNh0Q/featured',
            linkName: 'Visit YouTube Channel',
          },
        ],
        resource2: [
          {
            name: 'Visit Resource Center',
            description: 'Check out our resources for more on all things creative.',
            // eslint-disable-next-line global-require
            icon: require('@/assets/svg/resourceCenter.svg'),
            link: 'https://www.blendedsense.com/blog',
            linkName: 'Visit Blog',
          },
          {
            name: 'Send Us Feedback',
            description: 'Let us know how we’re doing. Your feedback is important to us.',
            // eslint-disable-next-line global-require
            icon: require('@/assets/svg/feedback.svg'),
            link: 'https://www.blendedsense.com/contact',
            linkName: 'Send Feedback',
          },
        ],
      };
      return resource;
    },
    groupedEvents() {
      return this.formatEvents(this.events);
    },
    latestContentKit() {
      return _.get(this.recentContentKits, '[0]', false);
    },
    reUpCall() {
      return this.latestContentKit;
    },
    enableQuizCard() {
      // Short circuit the logic below brandProfile is completed unpopulated,
      // legacy code check is buggy when it's completely empty.
      if (!Object.keys(this.brandProfile).length) {
        return true;
      }

      const {
        brandColors,
        brandKits,
        brandKitLinks,
        purpose,
        website,
        facebook,
        instagram,
        linkedin,
        pinterest,
        youtube,
        brandMusic,
        brandStyle,
        brandFonts,
        brandKitInspirations,
      } = this.brandProfile;

      let hasBrandFonts = false
      if (brandFonts.primary && brandFonts.primary.name) {
        hasBrandFonts = true
      } else if (brandFonts.secondary && brandFonts.secondary.name) {
        hasBrandFonts = true
      }

      const brandMusicData = Object.keys(brandMusic).length > 0;
      const brandStyleData = Object.keys(brandStyle).length > 0;
      if (
        (website || facebook || instagram || linkedin || pinterest || youtube)
        && brandKits.length > 0
        && brandColors.length > 0
        && hasBrandFonts
        && brandStyleData
        && brandMusicData
        && purpose
        && (brandKitInspirations.length > 0 || brandKitLinks.length > 0)
      ) {
        return true;
      }
      return false;
    },
    projects() {
      return this.business.projects && _.get(this.business, 'projects').length;
    },
    contentProducers() {
      return (this.projectTeam.producersTeam || [])
        .map((item) => ({
          ...item.user,
        }))
        .map((user) => ({
          ...user,
          ...user.producerProfile,
        }));
    },
    userCompletedActions() {
      return (
        this.projects
        && (this.callScheduled || !this.contentProducers.length)
        && this.enableQuizCard
      );
    },
    producerCallInfo() {
      const info = {};
      const business = _.get(this.project, 'business', {});
      const pointOfContact = _.get(business, 'pointOfContact', null);
      const defaultContact = _.get(this.profile, 'defaultContact', null);

      const contactWithCalendly = [...this.contentProducers].find(({ calendly }) => calendly);
      if (contactWithCalendly) {
        info.userId = contactWithCalendly.id;
        info.displayName = `${contactWithCalendly.firstName} ${contactWithCalendly.lastName}`;
        info.email = contactWithCalendly.email;
        info.avatar = contactWithCalendly.profilePic;
        info.callLink = contactWithCalendly.calendly;
        info.representive = 'Content Producer';
      }

      // if there is no cp with calendly link, then use the first cp
      if (!info.callLink && this.contentProducers.length) {
        info.userId = this.contentProducers[0].id;
        info.displayName = `${this.contentProducers[0].firstName} ${
          this.contentProducers[0].lastName
        }`;
        info.email = this.contentProducers[0].email;
        info.avatar = this.contentProducers[0].profilePic;
        info.callLink = this.contentProducers[0].calendly;
        info.representive = 'Content Producer';
      }

      // if there is no cp's at all use default contact
      if (this.contentProducers.length === 0 && defaultContact) {
        info.userId = defaultContact.id;
        info.displayName = `${defaultContact.firstName} ${defaultContact.lastName}`;
        info.email = defaultContact.email;
        info.avatar = defaultContact.profilePic;
        info.callLink = defaultContact.calendly;
        info.representive = 'Production Manager';
      }

      // if there is a point of contact
      if (pointOfContact) {
        info.userId = pointOfContact.id;
        info.displayName = `${pointOfContact.firstName} ${pointOfContact.lastName}`;
        info.email = pointOfContact.email;
        info.avatar = pointOfContact.profilePic;
        info.callLink = pointOfContact.calendly;
        info.representive = pointOfContact.role.name === 'producer' ? 'Content Producer' : 'Production Manager';
      }

      if (info.callLink) {
        let link = info.callLink;
        if (link.indexOf('http') === -1) {
          link = `http://${link}`;
        }
        const url = new URL(link);
        info.isValidLink = url.host === 'calendly.com';
      }
      if (Object.keys(info).length === 0) {
        return null;
      }
      return info;
    },

    getClientUpdateWebFlowLink() {
      const businessType = this.business.businessType && this.business.businessType.name;

      if (businessType === 'E-Commerce') {
        return process.env.VUE_APP_WEB_FLOW_ECOMMERCE_LINK;
      }
      if (businessType === 'Professional Services') {
        return process.env.VUE_APP_WEB_FLOW_PROFESSIONAL_SERVICES_LINK;
      }
      if (businessType === 'Technology') {
        return process.env.VUE_APP_WEB_FLOW_TECHNOLOGY_LINK;
      }
      return null;
    },
    callScheduled() {
      return _.get(this.business, 'callScheduled', false);
    },
    welcomeText() {
      return `Welcome, ${this.name}`;
    },
    firstSweep() {
      return _.get(this.project, 'isfirstSweepCompleted');
    },
    orderActive() {
      const status = _.map(this.orders, 'status');
      if (status.find((data) => data === 'ACTIVE')) {
        return true;
      }
      return false;
    },
    // subText() {
    //   const checkFirstSweep = this.orderActive && this.enableQuizCard
    //     ? 'From your vision to the final cut, we’re with you.'
    //     : 'Ready, set, create! Your digital studio awaits.';
    //   const checkKitDelivered = this.latestContentKit
    //     ? 'The wait is over,
    //  go to your recently uploaded Content Kit to view and download your new assets!'
    //     : checkFirstSweep;
    //   return checkKitDelivered;
    // },
  },
  data() {
    return {
      // showOrderModal: false,
      orderToEdit: {},
      cardDetails: [],
      upcomingsweeps: [],
      canEditBrandProfile: false,
      calendlyKey: null,
      showDetails: false,
      modalOpen: false,
      selectedMember: {},
      modalCurrentState: { step: 1 },
      showBrandQuizModal: false,
      showScheduleCallModal: false,
      projectTeam: {},
      calendlyLoading: true,
      calendlyLoadTimer: null,
      loaded: false,
      scheduleCallSource: null,
      contactType: null,
      videoOptions: {
        autoplay: false,
        controls: true,
        aspectRatio: '16:9',
        sources: [
          {
            src: 'https://bldsns-prod.s3.amazonaws.com/brand_sources/Welcome+to+Blended+Sense.mp4',
            type: 'video/mp4',
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions('user', ['resendCreativeVideoEmail', 'updateScheduleACallStatus', 'userEvents', 'paymentMethods']),
    ...mapActions('profile', ['getProfile']),
    ...mapActions('business', ['getBusiness', 'getReasons', 'getCreditsHistory']),
    ...mapActions('project', ['getProjectTeam']),
    ...mapActions('orders', ['createOrder', 'getPendingOrders', 'getOrders']),
    ...mapActions('sweep', ['getBusinessSweeps']),
    ...mapActions('brandProfile', [
      'getBrandSources',
      'getBrandProfile',
      'checkBrandProfileEditAccess',
    ]),
    getOptimizedS3ImageURL,
    async reloadCardsList() {
      const result = await this.paymentMethods(this.userDetails.currentBusinessId);
      this.cardDetails = result.paymentMethods;
    },
    async openCreateOrderModal() {
      this.orderToEdit = await this.createOrder({ businessId: this.userDetails.currentBusinessId });
      // have to set the order id
      this.$router.push(`/order_create/${this.orderToEdit.businessId}/${this.orderToEdit.id}`);
      // this.showOrderModal = true;
    },
    // closeCreateOrderModal() {
    //   this.orderToEdit = {};
    //   this.showOrderModal = false;
    // },
    getMemberProfile(member) {
      const pic = _.get(member.creatives || member, 'profilePic', null);
      return pic;
    },
    getDisplayTime(time) {
      return moment(time).format('hh:mm a');
    },
    formatEvents(eventsToFormat) {
      const eventsToGroup = [...eventsToFormat].map((event) => {
        let inviteeName = event.type === 'call' ? _.get(event, 'details.invitee.firstName') : '';
        inviteeName = inviteeName.charAt(0).toUpperCase() + inviteeName.slice(1);
        return {
          ...event,
          name: event.type === 'sweep' ? event.name : `Call with ${inviteeName}`,
          fullStartTime:
            event.type === 'sweep'
              ? moment(`${event.date} ${event.startTime}`, 'YYYY-MM-DD HH:mm:ss')
              : moment(event.startTime),
          fullEndTime:
            event.type === 'sweep'
              ? moment(`${event.date} ${event.endTime}`, 'YYYY-MM-DD HH:mm:ss')
              : moment(event.endTime),
          sortDate:
            event.type === 'sweep'
              ? moment(`${event.date} ${event.startTime}`, 'YYYY-MM-DD HH:mm:ss')
              : moment(event.startTime),
        };
      });
      const groupByDate = _.groupBy(eventsToGroup, (event) => moment(event.sortDate).format('YYYY-MM-DD'));
      const sortedGroupedByDateObjects = Object.entries(groupByDate)
        .map(([date, events]) => ({
          date,
          displayTitle: moment(date).format('MMM DD'),
          events,
        }))
        .sort((a, b) => moment(a.date) - moment(b.date));

      // sort the items in a group

      const sortedData = sortedGroupedByDateObjects.map((group) => ({
        ...group,
        events: group.events.sort((a, b) => moment(a.sortDate) - moment(b.sortDate)),
      }));
      return sortedData;
    },
    joinCall(event) {
      const url = event.join_url.replace(/"/g, '');
      window.open(url, '_blank');
    },
    checkProfile(pic) {
      if (pic) {
        return false;
      }
      return true;
    },
    creativesTeam() {
      const latestSweepCreatives = _.get(this.project.latestSweep, 'sweepTeam', []);
      const creativeTeam = latestSweepCreatives.length > 0
        ? latestSweepCreatives : this.creativeTeam;
      const team = _.concat(this.executivesTeam, this.producersTeam, this.editorTeam, creativeTeam);
      const data = _.filter(team, (val) => {
        if (this.producerCallInfo && this.producerCallInfo.email) {
          return val.email !== this.producerCallInfo.email;
        }
        return val;
      });
      return data;
    },
    getSortedCreativeTeam(team) {
      const ordering = {
        'Production Manager': 1,
        'Content Producer': 2,
        Editor: 3,
      };
      const sortedTeam = team
        .map((user) => {
          const userWithOrder = { ...user };
          userWithOrder.order = ordering[user.role] || 100;
          return userWithOrder;
        })
        .sort((a, b) => a.order - b.order);
      return sortedTeam;
    },
    showProducerPopup(producer) {
      const { defaultContact } = this.profile;
      let producerObj = defaultContact;
      if (this.contentProducers.length > 0) {
        const producers = this.projectTeamDetails(
          _.map(_.filter(this.projectTeam.producersTeam, ['accepted', '1']), 'user'),
          'Content Producer',
        );
        const filteredProducer = _.filter(producers, (item) => item.email === producer.email);
        producerObj = _.get(filteredProducer, '[0]', {});
      }
      this.selectedMember = producerObj;
      this.showDetails = true;
    },
    showPopup(index) {
      const member = this.getSortedCreativeTeam(this.creativesTeam())[index];
      const team = _.concat(
        this.executivesTeam,
        this.producersTeam,
        this.editorTeam,
        this.creativeTeam,
      );
      const creativeDetails = _.filter(team, (item) => item.userId === member.userId);
      const creative = _.get(creativeDetails, '[0]', {});
      this.selectedMember = creative;
      this.showDetails = true;
    },
    profilePic(member, version) {
      const profilePic = _.get(member, version);
      if (profilePic) {
        return profilePic;
      }
      // eslint-disable-next-line global-require
      return require('@/assets/svg/users.svg');
    },
    getSkillIcon(skill) {
      if (skill === '1' || skill === '2') {
        return {
          classType: 'photographer',
          // eslint-disable-next-line global-require
          icon: require('@/assets/svg/camera-circle.svg'),
        };
      }
      if (skill === '3') {
        return {
          classType: 'talent',
          // eslint-disable-next-line global-require
          icon: require('@/assets/svg/profile-circle.svg'),
        };
      }
      if (skill === '4') {
        return {
          classType: 'producer',
          // eslint-disable-next-line global-require
          icon: require('@/assets/svg/video-circle.svg'),
        };
      }
      if (skill.indexOf('Talent') >= 0) {
        return {
          classType: 'talent',
          // eslint-disable-next-line global-require
          icon: require('@/assets/svg/profile-circle.svg'),
        };
      }
      return {
        classType: 'photographer',
        // eslint-disable-next-line global-require
        icon: require('@/assets/svg/camera-circle.svg'),
      };
    },
    getProfileIcon(role) {
      switch (role) {
        case 'Content Producer':
        case 'Production Manager':
          return {
            classType: 'producer',
            // eslint-disable-next-line global-require
            icon: require('@/assets/svg/video-circle.svg'),
          };
        case 'editor':
          return {
            classType: 'editor',
            // eslint-disable-next-line global-require
            icon: require('@/assets/svg/edit-circle.svg'),
          };
        default:
          return this.getSkillIcon(role);
      }
    },
    goToEvent(id, type) {
      if (type === 'sweep') {
        this.$router.push(`/sweep_details/${this.userDetails.currentBusinessId}/${id}`);
      }
    },
    eventIcon(type) {
      if (type === 'sweep') {
        // eslint-disable-next-line global-require
        return require('@/assets/svg/camera_event.svg');
      }
      // eslint-disable-next-line global-require
      return require('@/assets/svg/Phone.svg');
    },
    onContentKitClick() {
      const projectId = _.get(this.project, 'id');
      this.$router.push(
        `library/${projectId}/content_kits?businessId=${this.userDetails.currentBusinessId}`,
      );
    },
    async openModal() {
      this.showBrandQuizModal = true;
    },
    appendInviteeParams(link) {
      const { firstName, lastName, email } = this.profile || {};
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append('name', `${firstName} ${lastName}`);
      urlSearchParams.append('email', email);
      urlSearchParams.append('timestamp', Date.now());
      return `${link}?${urlSearchParams.toString()}`;
    },
    toggleScheduleCallModal(flag) {
      this.showScheduleCallModal = flag;
    },
    closeModal() {
      this.showBrandQuizModal = false;
    },
    appendBusinessParamsToWebFlowLink(link) {
      const { name: businessName } = this.business;
      const {
        firstName, lastName, email, number,
      } = this.profile || {};
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append('business_name', businessName);
      urlSearchParams.append('email', email);
      urlSearchParams.append('phone', number);
      urlSearchParams.append('first_name', firstName);
      urlSearchParams.append('last_name', lastName);
      urlSearchParams.append('customer_id', this.userDetails.id);
      urlSearchParams.append('redirect_url', encodeURIComponent(window.location.href));
      return `${link}?${urlSearchParams.toString()}`;
    },
    handleScheduleCall(options = {}) {
      if (options && options.scheduleCallSource) {
        this.scheduleCallSource = options.scheduleCallSource;
      } else {
        this.scheduleCallSource = null;
      }
      const { contactType = null } = options;
      this.contactType = contactType;
      if (this.producerCallInfo.callLink) {
        if (this.producerCallInfo.isValidLink) {
          // this.calendlyLoading = true;
          if (!this.calendlyLoadTimer) {
            this.calendlyLoadTimer = setTimeout(() => {
              this.calendlyLoading = false;
            }, 10000);
          }
        } else {
          this.calendlyLoading = false;
        }
        this.toggleScheduleCallModal(true);
        const vueCalendlyRef = this.$refs.vueCalendly;
        if (vueCalendlyRef) {
          this.calendlyLoading = true;
          const rootNode = vueCalendlyRef.$el;
          const iframe = rootNode.querySelector('iframe');
          if (iframe) {
            const url = this.producerCallInfo
            && this.appendInviteeParams(this.producerCallInfo.callLink);
            iframe.src = url;
            this.calendlyKey = url;
          }
        }
      } else {
        const element = document.createElement('a');
        element.setAttribute('target', '_blank');
        element.setAttribute('href', `mailto:${this.producerCallInfo.email}`);
        element.click();
      }
    },
    async handleCalendlyEvent(e) {
      const isCalendlyEvent = e.data.event && e.data.event.indexOf('calendly') === 0;
      if (isCalendlyEvent) {
        const { data } = e;
        if (data.event === 'calendly.event_type_viewed') {
          this.calendlyLoading = false;
        }
        if (data.event === 'calendly.event_scheduled') {
          const contactType = this.scheduleCallSource === 'producer' ? 'contact_producer' : 'creative_vision_call';
          const requestPayload = {
            eventURI: data.payload.event.uri.split('/').pop(),
            contactType,
            inviteeId: this.producerCallInfo.userId,
          };
          if (this.contactType !== null) {
            requestPayload.contactType = this.contactType;
          }
          await this.updateScheduleACallStatus(requestPayload);
          const businessId = this.userDetails.currentBusinessId;
          await this.getBusiness(businessId);
        }
      }
    },
    orderAssets() {
      // this.modalOpen = true;
      this.$router.push('/order_assets');
    },
  },
  async mounted() {
    await this.getProfile();
    if (this.userDetails.currentBusinessId) {
      this.reloadCardsList();
      const businessId = this.userDetails.currentBusinessId;
      await this.getBusiness(businessId);
      await this.getPendingOrders({ businessId });
      await this.getReasons();
      await this.getCreditsHistory({ businessId });
      this.$store.commit('orders/SET_ORDERS', []);
      await this.getOrders({ businessId });
      const results = await this.getBusinessSweeps({ businessId });
      this.upcomingsweeps = _.get(results.sweeps, 'upcoming', []);
      if (this.business && this.business.projects && this.business.projects.length) {
        this.projectTeam = await this.getProjectTeam(this.business.projects[0].id);
      }
      await this.userEvents(businessId);
      this.canEditBrandProfile = await this.checkBrandProfileEditAccess({
        brandProfileId: this.business.brandProfileId,
      });
      await this.getBrandSources({ businessId, reference: 'business' });
    }
    this.loaded = true;
    window.addEventListener('message', this.handleCalendlyEvent);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleCalendlyEvent);
    if (this.calendlyLoadTimer) {
      clearTimeout(this.calendlyLoadTimer);
    }
  },
};
</script>
<style lang="scss" scoped>
.close-btn {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-icon {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: #9d9d9d;
}
.bg-white {
  height: 100%;
  background-color: #fff;
}
// .creative-team{
//   margin-left:52rem;
//   margin-top:-51rem;
// }
// .creative-data{
//     min-width:400px;
//     max-height: 198px;
//     min-height: 100px;
// }
.welcome-conatiner {
  max-width: 1280px;
  margin: auto;
  ::v-deep .v-btn__content {
    font-family: $fontFamily1 !important;
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
  }
  .description .no-task {
    font-family: $fontFamily1;
    font-style: italic;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #a1a1a1;
    padding-top: 42px;
  }
  .left-col,
  .right-col {
    padding: 0px;
    .events-heading {
      .events-title {
        color: #929292;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .add-payment-btn {
        font-size: 14px !important;
        color: $primary1;
        cursor: pointer;
      }
      .no-history {
        text-align: center;
        font-style: italic;
        font-weight: 500;
        font-size: 1.2rem;
        color: $charcoalBlack;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .events {
      font-family: $fontFamily1;
      font-style: normal;
      font-weight: bold;
      .event {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
        .date {
          padding-bottom: 10px;
        }
        .cursor-pointer {
          cursor: pointer;
        }
        .event-div {
          width: fit-content;
          margin-bottom: 20px;
          color: $titleBlack;
          &:last-child {
            margin-bottom: 0px;
          }
          .event-icon {
            width: 25px;
            height: 25px;
            margin-right: 13px;
            margin-top: 3px;
          }
          .name {
            word-break: break-word;
          }
          .event-btn {
            .v-btn {
              margin-top: 0.5rem;
              width: fit-content;
              background: $neutral6;
              border-radius: 30px;
              border: 3px solid $secondary2;
              -webkit-box-shadow: none !important;
              box-shadow: none !important;
              ::v-deep .v-btn__content {
                font-weight: 500;
                font-size: 16px;
                line-height: 28px;
                color: $charcoalBlack;
              }
            }
          }
        }
      }
      .no-task {
        padding-top: 25px !important;
      }
    }
    .tasks {
      padding: 15px 30px 20px 20px;
      border: 1px solid $primary1;
      border-radius: 10px;
      background-color: $primaryBackground !important;
      .task-btn {
        .v-btn {
          width: 174px;
          height: 42px;
          border-radius: 30px;
          box-shadow: none !important;
          ::v-deep .v-btn__content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      ::v-deep .v-card {
        box-shadow: none !important;
        border-radius: unset !important;
        margin-bottom: 28px !important;
        .v-card__text {
          padding: 0px !important;
        }
      }
      ::v-deep .v-card:last-child {
        margin-bottom: 0px !important;
      }
    }
    .cpCard {
      .sub-title {
        margin-bottom: 18px;
      }
      .producer-contact-img {
        padding-right: 18px;
        .profile-icon {
          position: absolute;
          margin-top: -20px;
        }
      }
      .name {
        word-break: break-word;
      }
      .desc {
        font-style: italic;
        font-size: 16px;
        line-height: 19px;
        color: $secondary3;
        padding-top: 2px;
      }
      .contact-details {
        padding-top: 11px;
        .contact:nth-child(2) {
          padding-top: 6px;
        }
        a {
          border-bottom: 0px;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    .tasks,
    .events,
    .questions,
    .resources,
    .cpCard {
      .v-expansion-panel {
        background: none;
        border-bottom: 1px solid #d8d8d8;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-bottom: 15px;
        &::before {
          box-shadow: none !important;
        }
      }
      .v-expansion-panel-header {
        padding: 0px !important;
        min-height: auto;
      }
      ::v-deep .v-expansion-panel-content .v-expansion-panel-content__wrap {
        padding: 0 0 15px 0 !important;
        font-size: 16px !important;
        line-height: 20px !important;
      }
      .section-title {
        font-family: $fontFamily1;
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 8px;
        margin-bottom: 19px;
      }
      // .creative-team {
      //   .creative-team-data {
      //     .creative-data{
      //       .producer-img {
      //       margin-top: 18px;
      //       }
      //     }
      //   }
      // }
      .que-description {
        .que-div {
          // margin-bottom: 30px;
          .text {
            line-height: 30px;
            font-size: 20px !important;
            margin-bottom: 12px !important;
          }
        }
      }
      .text-div {
        margin-right: 30px;
      }
      .text {
        font-family: $fontFamily1;
        margin-bottom: 10px;
      }
      .resource:last-child {
        padding-left: 0px;
      }
      .resource {
        font-family: $fontFamily1;
        font-style: normal;
        font-weight: normal;
        color: $secondary3;
        .name {
          word-break: break-word;
        }
        .desc {
          padding-top: 4px;
          padding-bottom: 18px;
        }
        .linkName {
          padding-top: 18px;
          border-bottom: 2px solid $secondary2;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $secondary3;
        }
      }
    }
  }
}
.profile-icon2 {
  margin-top: -15px;
}
.producer-contact-img2 {
  padding: 0px 5px;
  margin-right: 0.8rem;
}
.client-heading {
  margin-top: 50px;
}
.creative-heading {
  margin-top: 125px;
}
.container {
  padding: 0 50px;
}
.welcome-text {
  text-transform: capitalize;
  max-width: 600px;
}
.welcome-description {
  max-width: 550px;
  font-family: $fontFamily1;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: $secondary3;
}
.empty-note {
  font-family: $fontFamily1;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: $charcoalBlack;
}
.theme-image {
  margin: auto;
  width: 100%;
}
.v-card__text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.description {
  .status-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .heading {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .description {
    .v-card__text {
      font-family: $fontFamily1;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0.18px;
      padding: 0.9375rem 2.1875rem;
      min-height: 200px;
      color: $neutral1;
    }
  }
  .client-text-next {
    font-family: $fontFamily1;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #949494;
    margin-bottom: 1rem;
  }
  .client-success-board {
    background-color: $primaryBackground;
  }
  .description {
    padding: 19px 24px;
    min-height: auto;
    .v-btn {
      margin-top: 1.25rem;
      font-weight: bold;
    }
  }
  .hover:hover {
    cursor: pointer;
  }
  // .v-card {
  //   margin-bottom: 1.25rem;
  // }
  .sub-text {
    margin-top: 5px;
  }
  .icon {
    margin-right: 10px;
    height: 2rem;
    width: 2rem;
    &.mdi-check {
      font-size: 0px !important;
      color: white;
      background-color: $primary2 !important;
      border-radius: 1rem;
      background-image: url('../../assets/svg/checkmark.png');
      background-position: center;
    }
    &.mdi-checkbox-blank-circle {
      color: white;
      border-radius: 1rem;
      border: solid 1px #a5affb;
      background-color: white;
    }
    &.disabled {
      border-color: #828282;
    }
  }
}
@media (min-width: 1264px) and (max-width: 1464px) {
  .welcome-conatiner {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
}
@media (min-width: 960px) and (max-width: 1263px) {
  .right-col {
    padding-left: 20px !important;
  }
  // .creative-team{
  //   margin-left:43rem;
  //   margin-top:-54.5rem;
  // }
}
@media (min-width: 960px) {
  .welcome-conatiner {
    padding-left: 40px;
    padding-right: 20px;
  }
  .resources {
    .row {
      margin-bottom: 45px !important;
    }
    .resource1 {
      padding: 0px;
      padding-right: 33px !important;
    }
    .resource2 {
      padding: 0px;
      padding-left: 33px !important;
    }
  }
}
@media (max-width: 959px) {
  .welcome-conatiner {
    padding: 26px;
  }
  ::v-deep .v-expansion-panel-header.text {
    line-height: 26px !important;
  }
  .right-col {
    margin-top: 81px;
  }
  .resources {
    .row {
      margin-bottom: 0px;
    }
    .resource {
      margin-bottom: 54px;
    }
    .resource1,
    .resource2 {
      padding: 0px !important;
    }
  }
  .tasks .task-btn .v-btn {
    margin-top: 19px;
  }
  // .creative-team{
  //   margin-left:4rem;
  //   margin-top:0rem;
  // }
}
@media (max-width: 600px) {
  .container {
    padding: 0 1.875rem;
  }
  .welcome-description {
    color: $neutral1 !important;
    font-size: 1.125rem;
    letter-spacing: -0.48px;
  }
  // .description {
  //   .text {
  //     font-size: 14px;
  //   }
  // }
}
.bs_calendly_wrapper {
  position: relative;
}
.bs_calendly_loader {
  width: 100%;
  height: 100%;
  position: absolute;
}
.bs_calendly_fallback {
  padding: 1rem 2rem;
  font-weight: 500;
  color: rgb(45, 44, 44);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.expansion-panel-sub-text {
  padding-right: 2rem;
}
</style>
